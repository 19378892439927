import React from 'react';
import Pages from "../../components/layout/Pages";
import OrderableTable from "../../components/layout/OrderableTable";
import {adminRoutes} from "../../utils/routes";
import {formats} from "../../components/layout/Table";
import {concepts} from "../../libs/lang";
import getFullName from "../../utils/getFullName";


const columns = [
    { id: 'id', accessor: 'id', header: 'ID' },
    { id: 'name', accessor: 'name', header: `${concepts.Subscription} name` },
    {
        id: 'academic_or_commercial', accessor: 'academic_or_commercial', header: 'Academic / Commercial',
        Cell: ({ row }) => {
            const subscription = row.original;
            return subscription?.academic_or_commercial || '-';
        },
    },
    {
        id: 'individual_or_team', accessor: 'user', header: `${concepts.User} / ${concepts.Group}`,
        Cell: ({ row }) => {
            const subscription = row.original;
            return subscription?.group
                ? `${concepts.Group}`
                : `${concepts.User}`;
        },
    },
    {
        id: 'subscriber', accessor: 'subscriber', header: 'Subscriber',
        Cell: ({ row }) => {
            const subscription = row.original;
            return subscription?.group
                ? `${subscription?.group.name}`
                : `${getFullName(subscription.user)}`;
        },
    },
    { id: 'status', accessor: 'stripe_status', header: 'Status' },
    { id: 'quantity', accessor: 'quantity', header: 'Quantity' },
    { id: 'created_at', accessor: 'created_at', header: 'Sign up at', Cell: formats.date },
]


export default function AdminSubscriptions() {
    return (
        <Pages.Centred padded>
            <h1>{concepts.Subscriptions}</h1>
            <OrderableTable
                orderables={{
                    'name': 'name',
                    'created_at': 'created_at',
                }}
                baseEndpoint={'/admin/subscriptions?_with[]=user&_with[]=group&_with[]=items&_orderBy=created_at'}
                memoisedColumns={columns}
                buildLink={(record) => adminRoutes.subscription(record.id)}
            />
        </Pages.Centred>
    );
}

