import React from 'react';
import useAuth from "../../components/hooks/useAuth";
import SignUp from "../SignUp";
import {Pages} from "../../components/layout";
import Header from "../../components/Header";
import Description from "../../components/Description";
import {Link, useSearchParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import ProfilePicture from "../../components/ProfilePicture";
import {routes} from "../../utils";
import {ArrowRight} from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import useTitle from "../../components/hooks/useTitle";


export default function ShareableLinkPage({
    header,
    tabTitle,
    description: DescriptionComponent,
    currentPageUrl,
    children,
    isLoading,
    error,
    shareRequests,
    group,
    arrow = true,
}) {
    useTitle(tabTitle);
    const { user } = useAuth();

    const [sp] = useSearchParams();
    const userFirstName =  sp?.get('userFirstName');

    const headerComponent = <Header component="h1" className="text-center mb-0 pb-0">{header}</Header>;
    if (isLoading) return <Pages.Centred width="xs" padded>{headerComponent}<Loading /></Pages.Centred>;
    if (error) return <Pages.Centred width="xs" padded>{headerComponent}<Alert variant="danger">{error}</Alert></Pages.Centred>;

    return user ? (
        <Pages.Centred width="xs">
            <span className="d-block mb-5">
                {headerComponent}
                {userFirstName && DescriptionComponent && (
                    <Description className="text-center mt-0">
                        <DescriptionComponent user={{ first_name: userFirstName }} />
                    </Description>
                )}
            </span>
            <Row className="align-items-center">
                <Col className={`text-center ${arrow ? '': 'border-1 border-end border-gray'}`}>
                    <ProfilePicture user={user} size={100} />
                    <h2>{user?.first_name} {user?.last_name}</h2>

                    <Description className="mb-5 text-sm">
                        Is this not you?
                        <br />
                        <Link to={routes.logoutWithRedirect(currentPageUrl)}>Log out</Link>
                        &nbsp;or log in via your account&nbsp;
                        <Link to={routes.loginAgain(currentPageUrl)}>here</Link>
                    </Description>
                </Col>
                {arrow && <Col xs={1}><ArrowRight size={40} /></Col>}

                <Col className="text-center px-4">
                    {children}
                </Col>
            </Row>
        </Pages.Centred>
    ) : <SignUp shareRequests={shareRequests} group={group} redirect={currentPageUrl} />;
}
