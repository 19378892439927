import React from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import Loading from "../Loading";
import useGet from "../hooks/useGet";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Alert from "react-bootstrap/Alert";
import {concepts} from "../../libs/lang";

const BillingDashboardButtonPropTypes = {
    user: PropTypes.shape({
        is_subscribed: PropTypes.bool,
    }),
    groupId: PropTypes.number,
}

const BillingDashboardButtonDefaultProps = {
    groupId: undefined,
    user: undefined,
}

export default function BillingDashboardButton({ user, groupId }) {
    let endpoint = undefined;
    if (user?.is_subscribed) endpoint = backendEndpoints.billingPortal
    if (groupId) endpoint = backendEndpoints.groupBillingPortal(groupId)
    const { data: url, isLoading, error } = useGet({ endpoint })


    return (
        <>
            {typeof url === 'string' && url.indexOf('https://billing.stripe.com') === 0 && (
                <a href={url} target="_blank">
                    <Button variant="outline-secondary mb-1">
                        Manage {concepts.Subscription} &rarr;
                    </Button>
                    <Button variant="outline-secondary">
                        Invoice history &rarr;
                    </Button>
                </a>
            )}
            {isLoading && <Loading />}
            {error && <Alert>{error}</Alert>}
        </>
    );
}

BillingDashboardButton.propTypes = BillingDashboardButtonPropTypes;
BillingDashboardButton.defaultProps = BillingDashboardButtonDefaultProps;
