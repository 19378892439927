import React, {useState, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Formik, Form as FormikForm, useFormikContext} from "formik";
import { post, get, put } from 'axios'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Loading from "../Loading";
import {beautifyErrorMessage} from "../../utils/error";
import usePreference from "../hooks/usePreference";
import useBackendFormContext, {BackendFormContextProvider} from "./BackendFormContext";
import StoreOnBlur from "./StoreOnBlur";


const FormPropTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    enableReinitialize: PropTypes.bool,
    showFooter: PropTypes.bool,
    targetMethod: PropTypes.oneOf(['POST', 'GET', 'PUT', 'DELETE']),
}

const FormDefaultProps = {
    enableReinitialize: false,
    onSuccess: undefined,
    onError: undefined,
    targetMethod: 'GET',
    showFooter: true,
}

export default function BackendForm({
    initialValues: propsInitialValues,
    children, targetEndpoint, targetMethod, onSuccess, enableReinitialize, footerContent,
    showFooter,
    onError,
    validationSchema,
    formId,
    rememberForm = undefined,
}) {
    const timeoutRef = useRef(null);
    const [error, setError] = useState('');
    const [feedback, setFeedback] = useState('');
    const preferenceObj = useMemo(() => ({
        key: `remember-${formId}`,
        // Only store form information for 6 hours = 0.25 days for security purposes.
        // Forms can contain sensitive information such as passwords and email
        // addresses.
        reminderDays: 0.25,
    }), [formId]);
    const { preferenceValue: storedInitialValues, setPreferenceValue: storeInitialValues } = usePreference(
        // Disabled storing and saving preference if rememberForm=false
        rememberForm ? preferenceObj : undefined
    );

    const initialValues = useMemo(() => {
        if (rememberForm && typeof storedInitialValues === 'object' && storedInitialValues) {
            return Object.keys(storedInitialValues).reduce((acc, key) => {
                // Only use the keys from the saved
                if (key in storedInitialValues && storedInitialValues[key]) {
                    acc[key] = storedInitialValues[key];
                }
                return acc;
            }, {...propsInitialValues});
        } else {
            return propsInitialValues;
        }
    }, [storedInitialValues, propsInitialValues, rememberForm]);

    const saveValuesToRemember = rememberForm ? storeInitialValues : undefined;

    return (
        <BackendFormContextProvider value={{ error, feedback, footerContent, saveValuesToRemember }}>
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    clearTimeout(timeoutRef)
                    setFeedback('')
                    setError('')

                    if (saveValuesToRemember) {
                        // Store the values in a persistent storage
                        saveValuesToRemember(values);
                    }

                    let method = get;
                    if (targetMethod === 'POST') {
                        method = post
                    } else if (targetMethod === 'PUT') {
                        method = put
                    }
                    method(targetEndpoint, { ...values, token_name: 'web-app' })
                        .then((response) => {
                            if (onSuccess) onSuccess(response.data)
                            const { message } = response.data;
                            if (message) setFeedback(message)

                            // Remove the feedback after some time.
                            timeoutRef.current = setTimeout(() => {
                                setFeedback('')
                            }, 3000)
                        })
                        .catch((e) => {
                            const data = e.response.data;
                            if (data) {
                                const { message, errors } = data;

                                setError(message)
                                for (const errorsKey in errors) {
                                    const value = errors[errorsKey]
                                    actions.setFieldError(
                                        errorsKey,
                                        Array.isArray(value) ? value?.join(' ') : value,
                                    )
                                }
                                if (onError) onError(e.response)
                            } else {
                                setError(beautifyErrorMessage(e))
                            }
                        })
                        .finally(() => {
                            actions.setSubmitting(false)
                        })
                }}
            >
                {({ handleSubmit }) => (
                    <FormikForm onSubmit={handleSubmit} action="post" id={formId}>
                        {children}
                        {showFooter && <BackendForm.Footer />}
                        <StoreOnBlur saveValuesToRemember={saveValuesToRemember} />
                    </FormikForm>
                )}
            </Formik>
        </BackendFormContextProvider>
    );
}
function BackendFormFooter({ showError = true, marginTop = true, buttonText = 'Submit', className = '', center = false }) {
    const { footerContent } = useBackendFormContext();
    const { isSubmitting } = useFormikContext();
    return (
        <>
            <BackendFormErrorFeedback showError={showError} />
            <div className={cx({
                'd-flex justify-content-end' : !center,
                'd-flex justify-content-center' : center,
                'mt-5' : marginTop,
            })}>
                {footerContent && (
                    <div className="me-2 flex-grow-1">{footerContent}</div>
                )}
                <Button
                    className={cx({
                        "ps-5": true,
                        "pe-5": !isSubmitting,
                        "pe-3": isSubmitting,
                        [className]: !!className,
                    })}
                    size="lg"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                >
                    {buttonText}
                    {isSubmitting && <Loading />}
                </Button>
            </div>
        </>
    )
}

/**
 * Show the submit error/feedback from the form.
 * With this component you can show it at multiple spots.
 */
function BackendFormErrorFeedback({ showError }) {
    const { error, feedback } = useBackendFormContext();

    return <>
        {showError && error ? <Alert variant="danger">{error}</Alert> : null}
        {feedback && <Alert variant="success">{feedback}</Alert>}
    </>;
}
BackendForm.Footer = BackendFormFooter;
BackendForm.Feedback = BackendFormErrorFeedback;
BackendForm.propTypes = FormPropTypes;
BackendForm.defaultProps = FormDefaultProps;
