import React from 'react';
import PropTypes from 'prop-types';
import BillingDashboardButton from "./BillingDashboardButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Description from "../Description";
import { TypeSpan, DataRow } from "../layout";
import moment from "moment";
import {standardDateFormat} from "../../utils/momentHelpers";
import InfoTooltip from "../layout/InfoTooltip";
import {concepts} from "../../libs/lang";
import useAuth from "../hooks/useAuth";
import isAdmin from "../../utils/isAdmin";
import CopyText from "../layout/CopyLink";
import {FormText} from "react-bootstrap";

const LABEL_SIZE = 4

const SubscriptionCardPropTypes = {
    subscription: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
            product: PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
            })
        })),
    }).isRequired,
    group: PropTypes.shape({}),
}
const SubscriptionCardDefaultProps = {
    group: null,
}

export default function SubscriptionCard({ subscription, group: propsGroup }) {
    const group = propsGroup || subscription?.group;
    const { user } = useAuth();

    const product = Array.isArray(subscription.items) && subscription.items.length && subscription.items[0].product;
    return (
        <div
            className="rounded px-3 py-3 justify-content-between bg-lightest mb-1"
        >
            <Row>
                <Col md={8}>
                    <TypeSpan>{concepts.Subscription}</TypeSpan>
                    <h6 className="gradient-color-secondary">{product?.name}</h6>
                    <Description>{product?.description}</Description>
                    {subscription.created_at && (
                        <DataRow label="Service effective date" labelSize={LABEL_SIZE}>
                            {moment(subscription.created_at).format(standardDateFormat)}
                        </DataRow>
                    )}
                    {subscription.trial_ends_at && (
                        <DataRow label="Trial ends at" labelSize={LABEL_SIZE}>
                            {moment(subscription.trial_ends_at).format(standardDateFormat)}
                        </DataRow>
                    )}
                    {subscription.ends_at ? (
                        <DataRow label="Status" labelSize={LABEL_SIZE}>
                            <span className="text-danger">
                                Will stop at {moment(subscription.ends_at).format(standardDateFormat)}
                            </span>
                        </DataRow>
                    ) : (
                        <DataRow label="Status" labelSize={LABEL_SIZE}>
                            Active
                        </DataRow>
                    )}
                    {subscription.group_id && (
                        <>
                            {typeof group.total_seats === 'number' ? (
                                <DataRow
                                    label={(
                                        <>
                                            Total seats
                                            <InfoTooltip>
                                                Each member can choose to arrange their subscription via this {concepts.Group}.
                                            </InfoTooltip>
                                        </>
                                    )}
                                    labelSize={LABEL_SIZE}
                                >
                                    {group.total_seats}
                                </DataRow>
                            ) : null}
                            <DataRow
                                label={(
                                    <>
                                        Seats used
                                        <InfoTooltip>
                                            This number is lower than the total number when a member has a subscription
                                            via another {concepts.Group} or as an individual.
                                        </InfoTooltip>
                                    </>
                                )}
                                labelSize={LABEL_SIZE}
                            >
                                {group.used_seats}
                            </DataRow>
                        </>
                    )}
                </Col>
                <Col className="text-right" md={4}>
                    <BillingDashboardButton groupId={subscription.group_id} user={subscription.user} />
                </Col>
            </Row>
            {isAdmin(user) && (
                <div className="mt-3">
                    <CopyText text={subscription.stripe_id} />
                    <FormText className="text-xs">Use this Stripe ID to manage the subscription in Stripe</FormText>
                </div>
            )}
        </div>
    );
}

SubscriptionCard.propTypes = SubscriptionCardPropTypes;
SubscriptionCard.defaultProps = SubscriptionCardDefaultProps;
