import React, {useEffect, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ArrowLeftCircleFill, ArrowRightCircleFill, CircleFill} from "react-bootstrap-icons";
import cx from "classnames";
import styles from "./ShowCase.module.scss";
import unlimitedInstallations from './../../assets/images/screenshots/showcase/5-showcase-unlimited-installations.png';
import {routes} from "../../utils";
import GalleryDots from "../../components/layout/GalleryDots";
import CircularTimer from "../../components/layout/CircularTimer";

const crisprVideoMov = 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/large-static-files/website/1-showcase-CRISPR-Cas-tools-480.cab1d37d44a16b460625.mov';
const inventoryPreviewMov = 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/large-static-files/website/inventory-with-preview-480.3627c81aeceba2526f1f.mov';
const groupsMov = 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/large-static-files/website/create-teams-and-work-together-480.644af6affe0d195b2770.mov';
const splitScreenMov = 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/large-static-files/website/splitscreen-480.52eeab4fa937ae9fe82e.mov';

const points = [
    { title: 'Inventory with preview', video: inventoryPreviewMov, duration: 12 },
    { title: 'CRISPR-Cas tools', video: crisprVideoMov, duration: 15 },
    { title: 'Split screen', video: splitScreenMov, duration: 15 },
    { title: 'Create teams & work together', video: groupsMov, duration: 19 },
    { title: 'Unlimited installations', image: unlimitedInstallations, duration: 5 },
]

export default function ShowCase() {
    const [activeIndex, setActiveIndex] = useState(0);
    const timeoutRef = useRef();
    const isActiveIndex = (idx) => activeIndex === idx;

    const getNextIndex = (idx) => idx < points.length - 1 ? idx + 1 : 0;
    const getPrevIndex = (idx) => idx > 0 ? idx - 1 : points.length - 1;

    const createTimeout = (newIndex) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(
            () => {
                setActiveIndex(newIndex);
                createTimeout(getNextIndex(newIndex));
            },
            Math.max(points[getPrevIndex(newIndex)].duration * 1000, 5000),
        );
    }

    useEffect(() => {
        createTimeout(1);

        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (
        <>
            <Container>
                <Row>
                    <Col sm={{ span: 5, order: 1 }} xs={{ span: 12,  order: 2 }}>
                        {points.map(({ title, description, duration }, idx) => {
                            const isActive = isActiveIndex(idx);
                            const arrowSize = 30;
                            return (
                                <div
                                    key={title}
                                    className={cx({
                                        'd-sm-flex gap-3 align-items-center cursor-pointer': true,
                                        'flex-row justify-content-between justify-content-sm-start': true,
                                        'py-2 pe-3 ps-4 my-2': true,
                                        [styles.BorderTransparant]: !isActive,
                                        [styles.MobileNavigation]: true,
                                        [styles.ActiveItem]: isActive,
                                        'd-none': !isActive,
                                        'd-flex': isActive,
                                    })}
                                    onMouseMove={!isActive ? () => {
                                        setActiveIndex(idx);
                                        clearTimeout(timeoutRef.current);
                                    } : undefined}
                                    onMouseLeave={() => {
                                        createTimeout(getNextIndex(idx));
                                    }}
                                >
                                    <CircleFill
                                        className={cx({"d-none": true, 'text-white': isActive, 'text-darkest': !isActive })}
                                    />
                                    <ArrowLeftCircleFill
                                        className="d-inline-block d-sm-none text-white"
                                        size={arrowSize}
                                        onClick={() => setActiveIndex(getPrevIndex(activeIndex))}
                                    />
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <div
                                            className="d-flex align-items-center justify-content-start gap-2 flex-grow-1">
                                            <span
                                                className="text-lg fw-bold mb-0 text-center text-sm-start">{title}</span>
                                            {isActive && <span><CircularTimer duration={duration}/></span>}
                                        </div>
                                        <span className={`text-xxl line-height-1-0 position-relative ${styles.Arrow}`}>
                                            {!isActive && <span className={styles.ShortenArrow}>&nbsp;</span>}
                                            &#10230;
                                        </span>
                                    </div>
                                    <ArrowRightCircleFill
                                        className="d-inline-block d-sm-none text-white"
                                        size={arrowSize}
                                        onClick={() => setActiveIndex(getNextIndex(activeIndex))}
                                    />
                                </div>
                            )
                        })}
                        <div className="d-block text-center d-sm-none">
                            <GalleryDots length={points.length} activeIndex={activeIndex} />
                        </div>
                        <div className="text-center text-sm-start">
                            <a className="btn btn-link btn-lg mt-4 ps-3 fw-bold" href={routes.photo51}>
                                Discover all features
                            </a>
                        </div>
                    </Col>
                    <Col sm={{ span: 7, order: 2 }} xs={{ span: 12,  order: 1 }} className={styles.PreviewContainer}>
                        {points[activeIndex].image ? (
                            <div
                                className={cx({
                                    [styles.PreviewImage]: true,
                                    'mt-3 mt-sm-0': true,
                                    'animated fadeIn': true,
                                })}
                                key={activeIndex}
                            >
                                <img src={points[activeIndex].image} alt={points[activeIndex].title} className="shadow rounded-2 bg-white" />
                            </div>
                        ) : (
                            <video
                                key={activeIndex}
                                className="w-100 rounded-2 shadow bg-white"
                                controls={false}
                                autoPlay
                                loop
                            >
                                <source
                                    key={activeIndex}
                                    src={points[activeIndex].video}
                                    type="video/mp4"
                                />
                            </video>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}
