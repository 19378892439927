import React from 'react';
import Select from "./Select";
import useGet from "../hooks/useGet";
import Alert from "react-bootstrap/Alert";

/**
 * Create a HTML <select> component based on options retrieved from the backend.
 */
export default function BackendSelect({ endpoint, ...props }) {
    const { data, isLoading, error } = useGet({ endpoint });

    return error ? <Alert variant="danger">{error}</Alert> : (
        <Select
            {...props}
            options={Array.isArray(data)
                ? data.map((record) => ({ value: record.id, label: record.name }))
                : []}
            placeholder={isLoading ? 'Loading...' : props.placeholder}
            disabled={isLoading || props.disabled}
        />
    );
}
