const Cookies = {
	get(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i <ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length,c.length);
			}
		}
		return null;
	},
	remove (name) {
		this.set(name, "", -5);
	}, /**
	 *
	 * @param cname string    Cookie name
	 * @param cvalue string    Cookie value
	 * @param exdays float    Expiration in days
	 */
	set(cname, cvalue, exdays, path = '/') {
		const d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		const expires = "expires=" + d.toUTCString();

		if (window.location.href.indexOf("localhost:3000") > -1 || window.location.href.indexOf("divvly.com") == -1) document.cookie = cname + "=" + cvalue + "; " + expires + ";path=" + path + ";"; else document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/;domain=.divvly.com";
	}
};

export const COOKIE_NAMES = {
	THEME: 'fl-theme'
}

export default Cookies;
