function addStripeToHead() {
    const script = document.createElement("script");

    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    document.body.appendChild(script);
}

const stripePriceTables = {
    GROUP_ANNUAL: 'stripe/annual-group-pricing-table/photo51',
    INDIVIDUAL: 'stripe/annual-individual-pricing-table/photo51',
    ACADEMIC: 'stripe/academic-pricing-table/photo51',
    CORPORATE: 'stripe/corporate-pricing-table/photo51',
};

// Should be compatible with the backend's ClientReferenceId class
function buildClientReferenceId(objectType, id) {
    switch (objectType) {
        case 'group': return `group_${id}`
        case 'user': return `user_${id}`
        default: return undefined;
    }
}

export {
    addStripeToHead,
    stripePriceTables,
    buildClientReferenceId,
}
