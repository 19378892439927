export const backendEndpoints = {
    inviteUserToGroup: (groupId) => `/groups/${groupId}/invite-user`,
    updateUser: (userId) => "/users/"+userId+'?_with[]=files&_with[]=custom_fields',
    updateGroup: (groupId) => "/groups/"+groupId,
    deleteGroup: (groupId) => "/groups/"+groupId,
    leaveGroup: (groupId) => "/groups/"+groupId+"/leave",
    getGroup: (groupId) => `/groups/${groupId}`,
    getGroupViaHash: (groupId, hash) => `/groups/${groupId}/hash/${hash}`,
    getShareRequestViaHash: (shareRequestIds, hash) => `/share-requests/${shareRequestIds}/hash/${hash}`,
    copyShareRequest: (shareRequestIds, hash) => `/share-requests/${shareRequestIds}/copy-to-recipient/${hash}`,
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    createGroup: '/groups',
    getGroups: '/groups',
    getAdminGroups: '/admin/groups?_with[]=users',
    getAdminUserStats: '/admin/users/stats',
    getAdminSubscriptions: '/admin/subscriptions',
    adminSubscription: (subscriptionId) => `/admin/subscriptions/${subscriptionId}`,
    addSubscriptionRule: `/admin/subscription-rules`,
    getSubscriptionRules: `/admin/subscription-rules?_with[]=institute`,
    deleteSubscriptionRule: (ruleId) =>  `/admin/subscription-rules/${ruleId}`,
    subscriptionRule: (ruleId) => `/admin/subscription-rules/${ruleId}`,
    login: '/create-token',
    signUp: '/register',
    billingPortal: '/billing-portal',
    switchSubscription: '/user/subscriptions/switch',
    getAdminInstitutes: '/admin/institutes',
    groupBillingPortal: (groupId) => `/groups/${groupId}/billing-portal`,
    createAnnualGroupSubscription: (groupId) => `/groups/${groupId}/create-fixed-quantity-subscription`,
    startCheckout: (priceId) => `/stripe/prices/${priceId}/checkout`,
    createJoinUrl: (groupId) => `/groups/${groupId}/join`,
    matchSubscriptionRule: '/subscription-rules/match-email',
}
