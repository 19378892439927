import React, {useEffect} from 'react';
import {useField} from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import {concepts} from "../../libs/lang";
import {Lock, Person} from "react-bootstrap-icons";
import useCheckExistingUser from "./useCheckExistingUser";
import useGet from "../../components/hooks/useGet";
import {backendEndpoints} from "../../libs/backendEndpoints";
import {validateEmail} from "../../utils/validations";
import avansLogo from "fearless-common/src/contributors/institutes-logos/avans.png";
import hogeschoolLeidenLogo from "fearless-common/src/contributors/institutes-logos/hs-leiden.png";


export default function SignUpFields() {
    return (
        <>
        </>
    );
}

const usernameFieldName = 'username';
const emailFieldName = 'email';

function Account({ showEmail }) {
    const [emailField] = useField(emailFieldName);
    const { alert } = useCheckExistingUser({ query: emailField.value, checkDuplicate: true });

    return (
        <>
            <Row>
                <Col>
                    <FloatingLabelField name="first_name" label="First name" required/>
                </Col>
                <Col>
                    <FloatingLabelField name="last_name" label="Last name" required/>
                </Col>
            </Row>
            {showEmail && <FloatingLabelField name={emailFieldName} label="E-mail" required />}
            {alert}
            <FloatingLabelField name="organisation" label="Organization" required/>
            <FloatingLabelField name="group_name" label={concepts.GroupName} />
            <FloatingLabelField name="position" label="Position"/>
        </>
    );
}
SignUpFields.Account = Account;

export const signUpInitialValues = {
    Account: {
        first_name: '',
        last_name: '',
        organisation: '',
        group_name: '',
        position: '',
    },
    UsernamePassword: {
        username: '',
        password: '',
        password_copy: '',
    },
}

const UserNamePassword = ({ checkDuplicate }) => {
    const [usernameField ] = useField(usernameFieldName);
    const { alert } = useCheckExistingUser({ query: usernameField.value, checkDuplicate });

    return (
        <>
            <FloatingLabelField
                name={usernameFieldName}
                label={concepts.Username}
                required
                icon={<Person />}
                autoFocus
            />
            {alert}
            <FloatingLabelField name="password" label="Password" type="password" icon={<Lock/>} required/>
            <FloatingLabelField name="password_copy" label="Confirm password" type="password" icon={<Lock/>} required />
        </>
    )
}

SignUpFields.UsernamePassword = UserNamePassword;

const customFieldsName = 'custom_fields';

function InstituteFields() {
    const [,, { setValue: setCustomFields }] = useField(customFieldsName);
    const [emailField] = useField(emailFieldName);
    const { data: rule } = useGet({
        endpoint: validateEmail(emailField.value) ? backendEndpoints.matchSubscriptionRule : '',
        params: { email: emailField.value },
        smoothRefresh: true,
        retry: 1,
    });

    const instituteNameToLogo = {
        'avans hogeschool': avansLogo,
        'avans': avansLogo,
        'hogeschool leiden': hogeschoolLeidenLogo,
        'hs leiden': hogeschoolLeidenLogo,
    };
    const lowercase = rule?.institute?.name?.toLowerCase();
    const logo = lowercase in instituteNameToLogo ? instituteNameToLogo[lowercase] : undefined;

    const signUpFields = rule?.signup_custom_fields;

    useEffect(() => {
        if (signUpFields) {
            setCustomFields((oldValue) => {
                // Only keep the field that are needed for this rule
                return Object.keys(signUpFields).reduce((acc, key) => {
                    acc[key] = oldValue[key] || '';
                    return acc
                }, {});
            });
        } else {
            setCustomFields({});
        }
    }, [signUpFields]);

    return signUpFields ? (
        <div className="mt-5">
            {logo && <img src={logo} height={50} className="mb-3" />}
            <p>
                <strong>Looks like you’re joining Fearless League through {rule?.institute?.name}.</strong>
                <br/>
                Please provide information below:
            </p>
            {Object.keys(signUpFields).map((key) => (
                <FloatingLabelField
                    key={key}
                    name={`custom_fields[${key}]`}
                    label={signUpFields[key]}
                    required
                />
            ))}
        </div>
    ) : null;
}

SignUpFields.InstituteFields = InstituteFields;
