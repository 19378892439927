import React from 'react';
import styles from './CircularTimer.module.scss';


export default function CircularTimer({ duration }) {
    return (
        <div className={styles.CircularTimer}>
            <div className={styles.outerShadow} />
            <div className={styles.innerShadow} />
            <div className={`${styles.hold} ${styles.left}`} >
                <div className={styles.fill} style={{ animationDuration: `${duration / 2}s` }} />
            </div>
            <div className={`${styles.hold} ${styles.right}`}>
                <div
                    className={styles.fill}
                    style={{ animationDelay: `${duration / 2}s`, animationDuration: `${duration / 2}s` }}
                />
            </div>
        </div>
    );
}
