import {useState} from "react";
import axios from "axios";


export default function usePost({ url }) {
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = (data) => {
        setError('');
        setSubmitting(true);
        return axios.post(url, data)
            .then((resp) => {
                return resp.data?.data || resp.status === 201;
            })
            .catch((e) => {
                setError(e.response?.data?.message || e.message);
                return false;
            })
            .finally(() => setSubmitting(false))
    }
    return {
        onSubmit,
        isSubmitting,
        error: error,
    }
}
