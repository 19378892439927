import React, {useEffect} from 'react';
import cx from 'classnames';
import PropTypes from "prop-types";
import styles from './Pages.module.scss'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";

function BreadcrumbTrail({ breadcrumbs }) {
    return (
        <Row>
            <Col>
                <Breadcrumb>
                    {breadcrumbs.map(({ href, label }) => (
                        <Breadcrumb.Item href={href} key={label || href}>
                            {label}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            </Col>
        </Row>
    )
}


function Page({ children, padded }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <div className={cx({
        [styles.Page]: true,
        'pt-5': padded
    })}>{children}</div>
}

function HeaderPage({ children, className, header, bgColorClassName }) {
    return (
        <Page padded={false}>
            <div
                className={cx({
                    [className]: !!className
                })}
            >
                <div className={`${bgColorClassName} py-5`}>{header}</div>
                {children}
            </div>
        </Page>
    );
}

function CentredPage({ children, padded, width, className, breadcrumbs }) {
    return (
        <Page padded={padded}>
            <div
                className={cx({
                    'container-sm': width === 'sm',
                    'container-xs': width === 'xs',
                    [className]: !!className
                })}
            >
                {breadcrumbs && <BreadcrumbTrail breadcrumbs={breadcrumbs} />}
                {children}
            </div>
        </Page>
    );
}

CentredPage.propTypes = {
    width: PropTypes.oneOf(['sm', 'xs']),
    className: PropTypes.string,
};
CentredPage.defaultProps = {
    width: 'sm',
    padded: true,
    className: '',
};

function SideBar({ children, sidebar, className, breadcrumbs, inverse, size }) {
    return (
        <Page padded>
            <Container
                className={cx({
                    [className]: !!className,
                })}
            >
                {breadcrumbs && <BreadcrumbTrail breadcrumbs={breadcrumbs} />}
                <Row
                    className={cx({
                        ['flex-row-reverse']: !!inverse,
                        [styles.Row]: true,
                    })}
                >
                    <Col md={size?.md || 4} sm={5} className={cx({
                    })}>
                        {sidebar}
                    </Col>
                    <Col>
                        {children}
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

const Pages = {
    Centred: CentredPage,
    Base: Page,
    SideBar: SideBar,
    HeaderPage: HeaderPage,
}

export default Pages
