import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {BackendForm, FloatingLabelField} from "./forms";

const PasswordConfirmationButtonPropTypes = {
    buttonProps: PropTypes.shape({
        variant: PropTypes.string,
    }),
    buttonText: PropTypes.node.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

const PasswordConfirmationButtonDefaultProps = {
    buttonProps: {}
}

function deleteCookie( name, path = '/', domain = window.location.hostname ) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
}

export default function PasswordConfirmationButton({ buttonProps, buttonText, onSuccess }) {
    const [show, setShow] = useState();

    const handleClose = () => setShow(false);

    useEffect(() => {
        // Need to be deleted, because otherwise Sanctum Guard will try to set the request's group
        // which will create the request group token using TransientToken, but we need it to use the
        // App\Model\PersonalAccessToken. See Laravel\Sanctum\Guard::__invoke method.
        deleteCookie('XSRF-TOKEN');
        deleteCookie('fearless_league_session');
    }, []);

    return (
        <>
            <Button {...buttonProps} onClick={() => setShow(true)}>
                {buttonText}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    Confirm password
                </Modal.Header>
                <Modal.Body>
                    <p>This action requires you to confirm your identity with your password:</p>
                    <BackendForm
                        initialValues={{
                            password: '',
                        }}
                        targetEndpoint="/group/confirm-password-custom"
                        targetMethod="POST"
                        onSuccess={(resp) => {
                            onSuccess(resp)
                            handleClose();
                        }}
                    >
                        <FloatingLabelField
                            name="password" type="password" label="Password"
                            autoFocus
                        />
                    </BackendForm>
                </Modal.Body>
            </Modal>
        </>
    );
}

PasswordConfirmationButton.propTypes = PasswordConfirmationButtonPropTypes;
PasswordConfirmationButton.defaultProps = PasswordConfirmationButtonDefaultProps;
