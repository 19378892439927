import React, { useState } from 'react';
import styles from "./ManualPricingTable.module.scss";
import UnitSpan from "./UnitSpan";
import AmountSpan from "./AmountSpan";
import { isCustom } from "./price";
import Spinner from "react-bootstrap/Spinner";
import useDownSell from "./useDownSell";

const ManualPlanDisplayPropTypes = {}

const ManualPlanDisplayDefaultProps = {}

export default function ManualPlanDisplay({ price: mainPrice, onSelect, isBestValue, filterScope }) {
    const [isSubmitting, setSubmitting] = useState(false);
    const { price, showingDownSell, switchComponent } = useDownSell(mainPrice);

    const name = price?.product?.name;
    const accountType = price?.product?.metadata?.accountType;
    const onClick = () => {
        setSubmitting(true);
        const promise = onSelect(showingDownSell);
        if (promise instanceof Promise) {
            promise.finally(() => {
                setSubmitting(false);
            })
        } else {
            setSubmitting(false);
        }
    };

    return (
        <div
            className={`${styles.PriceColumn} ${isBestValue ? styles.BestValue : ''} position-relative d-flex flex-column justify-content-stretch align-items-stretch mb-3 cursor-pointer`}
            onClick={onClick}
        >
            {isBestValue && <span className={styles.BestValueSpan}>Best value</span>}
            <span className={`${styles.ProductName} d-flex flex-1`}>
                {filterScope ? name.replace(/(Academic |Corporate )/, '') : name}
            </span>
            <div className={styles.PriceContainer}>
                <div className="d-flex align-items-center">
                    <AmountSpan price={price} showMonthly />
                    <UnitSpan price={price} showMonthly />
                </div>
                {switchComponent && <div className="text-left">{switchComponent}</div>}
                {accountType === 'group' && name.indexOf('Team') > -1 &&
                    <div className="gray-700 fw-bold text-sm text-left mt-1">Starts with 2 seats</div>}
            </div>
            <button
                className={`border-0 rounded-pill btn btn-primary ${styles.Button}`}
                disabled={isSubmitting}
                onClick={onClick}
            >
                <span>{isCustom(price) ? 'Contact us' : 'Select'}</span>
                {isSubmitting && <Spinner size="sm" animation="border" />}
            </button>
            <span className={`${styles.ProductDescription} d-flex flex-1`}>
                {price?.product?.description}
            </span>
        </div>
    );
}

ManualPlanDisplay.propTypes = ManualPlanDisplayPropTypes;
ManualPlanDisplay.defaultProps = ManualPlanDisplayDefaultProps;
