import React, {useEffect} from 'react';
import useAuth from "./hooks/useAuth";
import useGet from "./hooks/useGet";
import usePreference from "./hooks/usePreference";
import {concepts} from "../libs/lang";
import downloadLinks from "../libs/downloadLinks";
import {Apple, CheckSquareFill, Square, Windows} from "react-bootstrap-icons";
import {routes} from "../utils";
import Loading from "./Loading";
import trackEvent, {CATEGORIES, ACTIONS} from "./tracking/trackEvent";

const filled = <CheckSquareFill className="text-secondary me-2" size={25} />;
const unfilled = <Square className="text-secondary me-2" size={25} />;

const hideValue = 'hidden';

export default function GettingStarted() {
    const { user } = useAuth();
    const { data, isLoading: loadingTokens } = useGet({ endpoint: '/tokens' });
    const { data: files, isLoading: isLoadingFiles } = useGet({
        endpoint: '/files', params: { _limit: 1 }, headers: { 'X-Version': '2.0.95' },
    });
    const { preferenceValue, setPreferenceValue } = usePreference('getting-started-visibility');

    const hasGroups = user.groups?.length > 0;
    const isLoggedInOnDesktop = data?.length > 0;
    const hasFiles = files?.length > 0;

    const loadingAny = isLoadingFiles || loadingTokens;
    const showList = true // for now always show the list. We'll think of something better later. !hasGroups || !isLoggedInOnDesktop || !hasFiles;

    useEffect(() => {
        if (preferenceValue !== hideValue && !loadingAny && !showList) {
            // setPreferenceValue(hideValue);
        }
    }, [showList, loadingAny, setPreferenceValue, preferenceValue]);

    return showList && preferenceValue !== hideValue ? (
        <div className="bg-white d-inline-block py-2 px-3 mb-5 shadow rounded-3 position-relative" style={{ maxWidth: 370 }}>
            <h4 className="mt-2 mb-3">Getting started</h4>
            <ul className="list-unstyled mx-auto text-left">
                {[
                    { checked: isLoggedInOnDesktop, loading: loadingTokens, label: (
                            <>
                                Download and log into {concepts.Photo51}<br /> on your desktop
                                <br />
                                <a
                                    className="btn btn-sm btn-primary me-2 d-inline-block"
                                    href={downloadLinks.mac}
                                    onClick={() => trackEvent(CATEGORIES.FILE_DOWNLOAD, ACTIONS.DOWNLOAD_GETTING_STARTED, 'Mac')}
                                >
                                    <small style={{ fontSize: '10px' }} className="font-md d-block">Download</small>
                                    <span className="d-flex align-items-center gap-1">
                                        <Apple /> Mac
                                    </span>
                                </a>
                                <a
                                    className="btn btn-sm btn-primary d-inline-block"
                                    href={downloadLinks.win}
                                    onClick={() => trackEvent(CATEGORIES.FILE_DOWNLOAD, ACTIONS.DOWNLOAD_GETTING_STARTED, 'Windows')}
                                >
                                    <small style={{ fontSize: '10px' }} className="font-md d-block">Download</small>
                                    <span className="d-flex align-items-center gap-1">
                                        <Windows /> Windows
                                    </span>
                                </a>
                            </>
                        )},
                    { checked: hasFiles, loading: isLoadingFiles, label: <>Upload a file into {concepts.Photo51}</>},
                    { checked: hasGroups, label: <><a href={routes.groupsCreate}>Create</a> a {concepts.Group}</>},
                ].map(({ checked, label, loading }, idx) => (
                    <li className="d-flex gap-1 mb-3" key={idx}>
                            <span style={{ width: 30 }} className="text-left">
                                {loading ? <Loading size="sm" /> : <span>{checked ? filled : unfilled}</span>}
                            </span>
                        <span className={checked ? 'gray-500': ''}>{label}</span>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
}

