import React from 'react';
import {ArrowRight} from "react-bootstrap-icons";


export default function GetInTouch({ children }) {
    return (
        <a
            href="mailto:info@fearlessleague.com"
            className="btn btn-lg btn-primary d-inline-flex align-items-center px-4 py-2 shadow"
        >
            {children || (
                <>
                    <span>Get in touch&nbsp;</span>
                    <ArrowRight/>
                </>
            )}
        </a>
    );
}
