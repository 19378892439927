import React, {useEffect, useState} from 'react';
import { useTable } from "react-table";
import { useNavigate } from "react-router";
import styles from "./Table.module.scss";
import moment from "moment";
import {defaultDateFormat, defaultDateTimeFormat} from "../../utils/date";
import {CheckCircleFill} from "react-bootstrap-icons";

const colorClassName = ({ orderables, column, order }, ascDesc) => orderables[column.id] === order?.key && order?.direction === ascDesc ? 'text-primary-dark' : 'text-dark'

export const formats = {
    date: 'date',
    dateTime: 'dateTime',
    fullName: 'fullName',
    length: 'length',
    bool: 'bool',
}

function CellContent({ cell, row }) {
    const output = cell.render('Cell');
    if (output === formats.date) {
        const d = moment(cell.value);
        return cell.value && d.isValid() ? d.format(defaultDateFormat) : '-';
    }
    if (output === formats.dateTime) {
        const d = moment(cell.value);
        return cell.value && d.isValid() ? d.format(defaultDateTimeFormat) : '-';
    }
    if (output === formats.fullName) {
        return `${row.original.last_name}, ${row.original.first_name}`;
    }
    if (output === formats.bool) {
        if (cell.value === true) return <CheckCircleFill className="text-success" />;
        const d = moment(cell.value);
        if (cell.value && d.isValid()) return <CheckCircleFill className="text-success" />;
        return 'No';
    }
    if (output === formats.length) {
        return Array.isArray(cell.value) ? cell.value.length : '-';
    }

    return output;
}

const TablePropTypes = {}

const TableDefaultProps = {}

export default function Table({ memoisedData, memoisedColumns, buildLink, onChangeOrder, orderables }) {
    const navigate = useNavigate();
    const [order, setOrder] = useState(null);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns: memoisedColumns, data: memoisedData});

    useEffect(() => {
        setOrder(null);
    }, [memoisedColumns]);

    return (
        // apply the table props
        <div className={styles.TableContainer}>
            <table {...getTableProps()} className={`table table-striped ${styles.Table}`}>
                <thead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    <th {...column.getHeaderProps()}>
                                        {column.render('header')}
                                        {orderables && orderables[column.id] && (
                                            <button
                                                className="ms-2 cursor-pointer bg-transparent border-0 rounded-1 p-0"
                                                onClick={() => {
                                                    if (onChangeOrder && orderables && orderables[column.id]) {
                                                        const newValue = {
                                                            direction: order?.direction !== 'asc' ? 'asc': 'desc',
                                                            key: orderables[column.id],
                                                        };
                                                        setOrder(newValue);
                                                        onChangeOrder(newValue);
                                                    }
                                                }}
                                            >
                                                <span className={colorClassName({ orderables, column, order }, 'asc')}>
                                                    &#x25b4;
                                                </span>
                                                <span className={colorClassName({ orderables, column, order }, 'desc')}>
                                                    &#x25be;
                                                </span>
                                            </button>
                                        )}
                                    </th>
                                ))}
                        </tr>
                    ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                    rows.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            // Apply the row props
                            <tr
                                {...row.getRowProps()}
                                onClick={() => {
                                    if (buildLink && row.original) {
                                        const destination = buildLink(row.original)
                                        if (destination) navigate(destination)
                                    }
                                }}
                            >
                                {// Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props
                                        return (
                                            <td {...cell.getCellProps()}>
                                                <CellContent cell={cell} row={row} />
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = TablePropTypes;
Table.defaultProps = TableDefaultProps;

