import React from 'react';
import PropTypes from 'prop-types';
import styles from "./layout/Layout.module.scss";

const ProfilePicturePropTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
}

const ProfilePictureDefaultProps = {
    className: '',
    size: undefined,
}

export default function ProfilePicture({ user, size, className }) {
    const url = user?.profile_picture?.url;

    return url
        ? (
            <span
                className={[styles.UserImage, className].join(' ')}
                style={{ backgroundImage: `url(${url})`, paddingTop: size, width: size }}
            >
                &nbsp;
            </span>
        )
        : (
            <span
                className={[styles.UserImage, className].join(' ')}
                style={{ paddingTop: size, width: size }}
            >
                <span className={styles.Initials}>
                    {typeof user?.first_name === 'string' && user?.first_name ? user?.first_name[0] : user.email[0]}
                </span>
            </span>
        );
}

ProfilePicture.propTypes = ProfilePicturePropTypes;
ProfilePicture.defaultProps = ProfilePictureDefaultProps;
