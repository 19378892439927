import React, {useMemo, useState} from 'react';
import Loading from "../Loading";
import Alert from "react-bootstrap/Alert";
import Table from "./Table";
import useGet from "../hooks/useGet";
import {PaginatedContextProvider, usePaginatedContext} from "fearless-common";
import {Pagination} from "react-bootstrap";


const paginator =
    ({ page, paginationData, setPage }) => (
        <div className="d-flex flex-column align-items-center my-1">
            <div className="d-flex flex-row align-items-center justify-content-center justify-items-center">
                <Pagination size="sm" className="mb-0">
                    <Pagination.First disabled={page <= 0} onClick={() => setPage(0)} />
                    <Pagination.Prev disabled={page <= 0} onClick={() => setPage(page - 1)} />
                    {[...new Array(paginationData.number_of_pages || 0)].map((_, idx) => {
                        return (
                            <Pagination.Item key={idx} active={idx === page} onClick={() => setPage(idx)}>
                                {idx + 1}
                            </Pagination.Item>
                        );
                    })}
                    <Pagination.Next disabled={page >= paginationData.number_of_pages - 1} onClick={() => setPage(page + 1)} />
                </Pagination>
            </div>
            <small className="neutral-500">{`Total items: ${paginationData.total}`}</small>
        </div>
    )

function InnerOrderableTable({ baseEndpoint, orderables, pagination, searchQuery = '', ...props}) {
    const { applyToEndpoint, extractPaginationData, pageIndex } = usePaginatedContext();
    const [orderBy, setOrderBy] = useState();

    const fullEndpoint = useMemo(() => {
        const mockBase = 'http://mock.com';
        const url = new URL(mockBase+baseEndpoint);
        if (orderBy) {
            url.searchParams.set('_orderBy', orderBy.key);
            url.searchParams.set('_orderDirection', orderBy.direction);
        }
        if (searchQuery) {
            url.searchParams.set('query', searchQuery);
        }
        const modified = url.toString();
        return (pagination ? applyToEndpoint(modified): modified).replace(mockBase, '');
    }, [baseEndpoint, orderBy, pageIndex, pagination, searchQuery]);

    const { data: fullResp, isLoading, error } = useGet({
        endpoint: fullEndpoint,
        smoothRefresh: true,
        dataKey: '',
        onSuccess: extractPaginationData,
    });

    return (
        <>
            {isLoading && <Loading size="sm"/>}
            {Array.isArray(fullResp?.data) && (
                <Table
                    {...props}
                    memoisedData={fullResp.data}
                    orderables={orderables}
                    onChangeOrder={setOrderBy}
                />
            )}
            {error && <Alert variant="danger">{error}</Alert>}
        </>
    );
}

export default function OrderableTable(props) {
    return (
        <PaginatedContextProvider paginator={props.pagination ? paginator: () => null}>
            <InnerOrderableTable {...props} />
        </PaginatedContextProvider>
    )
}
