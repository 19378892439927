import React from 'react';


export default function LittleDetails({ children, className }) {
    return (
        <p className={`text-sm gray-600 ${className}`}>
            {children}
        </p>
    );
}
