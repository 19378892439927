import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Button, Tab, Tabs, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import {routes} from "../../utils";
import useAuth from "../../components/hooks/useAuth";
import { CheckLg, PencilFill, PlusLg } from "react-bootstrap-icons";
import styles from "./Groups.module.scss";
import GroupEdit from "./GroupEdit";
import GroupCreate from "./GroupCreate";
import {backendEndpoints} from "../../libs/backendEndpoints";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import BackendForm from "../../components/forms/BackendForm";

function GroupEditForm({ onSuccess, group }) {
    return (
        <BackendForm
            initialValues={group}
            targetEndpoint={backendEndpoints.updateGroup(group.id)}
            targetMethod="PUT"
            enableReinitialize
            showFooter={false}
            onSuccess={onSuccess}
        >
            <div className={`d-flex align-items-center ${styles.EditForm}`}>
                <FloatingLabelField name="name" label="Name" />
                <BackendForm.Footer
                    showError={false}
                    marginTop={false}
                    buttonText={<CheckLg />}
                    className={styles.SubmitButton}
                />
            </div>
        </BackendForm>
    );
}

function GroupEditName({ group, onSuccess }) {
    const [edit, setEdit] = useState(false);

    return edit
        ? (
            <span className={styles.FormContainer}>
                <GroupEditForm
                    onSuccess={() => {
                        onSuccess();
                        setEdit(false);
                    }}
                    group={group}
                />
                <Button
                    variant="outline-danger"
                    onClick={() => setEdit(false)}
                    className={styles.EditButton}
                >
                    cancel
                </Button>
            </span>
        )
        : (
            <span className={styles.NameContainer}>
                <span>{group.name}</span>
                <Button
                    variant="light"
                    onClick={() => setEdit(true)}
                    className={styles.EditButton}
                >
                    <PencilFill />
                </Button>
            </span>
        );
}

const fixedKeys = {
    loading: 'loading',
    create: 'create'
}

const composeGroupKey = (id) => `group-${id}`;
const decomposeGroupKey = (string) => string.replace('group-', '')

export default function Groups() {
    const { groupId } = useParams();
    const [key, setKey] = useState(fixedKeys.loading);
    const navigate = useNavigate();
    const { user } = useAuth()
    const params = {
        _with: ['users'],
    }
    const { data: backendData, isLoading, error, reload } = useGet({ endpoint: backendEndpoints.getGroups, params })

    useEffect(() => {
        if (!isLoading && key === fixedKeys.loading) {
            if (backendData?.length > 0) {
                setKey(composeGroupKey(backendData[0].id));
            } else {
                setKey(fixedKeys.create);
            }
        }
    }, [isLoading, backendData]);

    useEffect(() => {
        const newKey = composeGroupKey(groupId);
        if (groupId && newKey !== key) {
            if (Object.values(fixedKeys).indexOf(groupId) === -1) {
                setKey(newKey);
            } else {
                setKey(groupId);
            }
        }
    }, [groupId, key]);

    const data = useMemo(
        () => backendData?.map((group) => ({
            ...group,
            role: group.users?.find(u => u.id === user.id)?.pivot.role,
        })),
        [backendData]
    );

    return (
        <>
            {error && <Alert className="alert-danger">{error}</Alert>}
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => {
                    setKey(k)
                    navigate(routes.group(decomposeGroupKey(k)))
                }}
                className="mb-3"
            >
                {isLoading && (
                    <Tab eventKey={fixedKeys.loading} title="Loading...">
                        {isLoading && <Loading />}
                    </Tab>
                )}
                {Array.isArray(data) && data.map((group) => (
                    <Tab
                        eventKey={composeGroupKey(group.id)}
                        title={<GroupEditName group={group} onSuccess={() => reload()} />}
                        key={group.id}
                    >
                        {key === composeGroupKey(group.id) && (
                            <GroupEdit groupId={group.id} onSuccess={() => reload()} />
                        )}
                    </Tab>
                ))}
                <Tab
                    eventKey={fixedKeys.create}
                    title={
                        <span
                            data-testid="add-group-button"
                            className={key === fixedKeys.create ? '' : 'btn btn-primary btn-sm py-0'}
                        >
                            <PlusLg />
                            Add
                        </span>
                    }
                >
                    <Row className="mt-5">
                        <Col xs={{ offset: 2, span: 8 }} >
                            <GroupCreate
                                onSuccess={({ data }) => {
                                    reload();

                                    // Navigate to the newly created group.
                                    if (data.id) {
                                        navigate(routes.group(data.id))
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </>
    );
}
