
const userPrefix = '/user'

export const sectionIds = {
    personalInfo: 'personalInfo',
    subscriptions: 'subscriptions',
    teams: 'teams',
    security: 'security',
}

export const PLAN_TAB_KEYS = {
    CORPORATE: 'corporate',
    ACADEMIC: 'academic',
}

export const routes = {
    // START: before login routes
    login: '/login',
    openPhoto51: '/open-photo51',
    loginWithRedirect: (url) => `/login?redirect=${url}`,
    signUp: '/sign-up',
    logout: '/logout',
    loginAgain: (url) => `/logout?redirect=/login?redirect=${url}`,
    logoutWithRedirect: (url) => `/logout?redirect=${url}`,
    home: '/',
    photo51: '/photo51',
    download: '/download-photo51',
    plans: '/plans',
    plansAcademic: `/plans?tab=${PLAN_TAB_KEYS.ACADEMIC}`,
    plansCorporate: `/plans?tab=${PLAN_TAB_KEYS.CORPORATE}`,
    faq: '/faq',
    blog: '/blog',
    aboutUs: '/about-us',
    privacy: '/privacy',
    cookiePolicy: '/cookies',
    termsOfService: '/terms-of-service',
    landing: '/landing',
    billingPortal: '/billing-portal',
    features: '/features',
    resetPassword: '/reset-password',
    completeAccount: (id, hash) => `/complete-account/${id}/${hash}`,
    shareRequestAccessLink: (id, hash) => `/access-shared-requests/${id}/${hash}`,
    completeAccountSuccess: '/complete-account-success',
    signUpSuccess: '/sign-up-success',
    forgotPassword: '/forgot-password',
    emailVerified: '/email-verified',
    groupsJoinLink: (id, hash) => `/join/groups/${id}/${hash}`,
    // END: before login routes

    // START: logged in environment routes
    dashboard: userPrefix,
    personalInformation: userPrefix+'#'+sectionIds.personalInfo,
    groups: userPrefix+'/groups',
    groupsCreate: userPrefix+'/groups/create',
    group: (id) => `${userPrefix}/groups/${id}`,
    subscriptions: userPrefix+'#'+sectionIds.subscriptions,
    security: userPrefix+'#'+sectionIds.security,
    reagents: userPrefix+'/reagents',
    photo51Cloud: userPrefix+'/cloud-apps/photo51',
    accountEdit: userPrefix+'/account/edit',
    twoFactorAuthentication: userPrefix+'/enable-two-factor-authentication',
    twoFactorAuthenticationSuccess: userPrefix+'/two-factor-authentication-success',
    twoFactorChallenge: userPrefix+'/two-factor-challenge',
    groupsEdit: (id) => userPrefix+'/groups/edit/'+id,
    // END: logged in environment routes

    gmail: 'https://gmail.com',
    microsoftOutlook: 'https://outlook.office.com',
}

// all the admin web routes. Not the backend API routes.
export const newString = 'new';


export const adminRoutes = {
    users: '/admin/users',
    institutes: '/admin/institutes',
    institute: (id) => `/admin/institutes/${id}`,
    addInstitute: `/admin/institutes/${newString}`,
    user: (id) => `/admin/users/${id}`,
    groups: '/admin/groups',
    group: (id) => `/admin/groups/${id}`,
    subscriptions: '/admin/subscriptions',
    insights: '/admin/insights',
    subscription:  (id) => `/admin/subscription/${id}`,
    subscriptionRules: '/admin/subscription-rules',
    subscriptionRule: (id) => `/admin/subscription-rules/${id}`,
    addSubscriptionRule: `/admin/subscription-rules/${newString}`,
}

export const externalLinks = {
    privacyPolicy: 'https://app.termly.io/document/privacy-policy/0e531131-f5b9-4760-a418-3d19f8295ed8',
    cookiePolicy: 'https://app.termly.io/document/cookie-policy/cc521b9b-190b-41ca-b343-02b532ae306e',
    termsOfService: 'https://app.termly.io/document/terms-of-service/b20c1c44-2080-45c4-9339-24474c1f7b13',
    eula: 'https://app.termly.io/document/eula/ed99d7bc-7947-4a2e-8048-e13749f792f9',
    photo51desktop: 'photo51://'
}
