import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import axios from "axios";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Button from "react-bootstrap/Button";
import AmountSpan from "./AmountSpan";
import UnitSpan from "./UnitSpan";
import useDownSell from "./useDownSell";
import Loading from "../Loading";
import OptionButton from "./OptionButton";

const IndividualPriceModalPropTypes = {
    onCancel: PropTypes.func.isRequired,
    price: PropTypes.shape({}).isRequired,
    defaultShowDownSell: PropTypes.bool.isRequired,
};
const IndividualPriceModalDefaultProps = {};

export default function IndividualPriceModal({ onCancel, price: mainPrice, defaultShowDownSell }) {
    const { price, setShowDownSell, showingDownSell } = useDownSell(mainPrice);
    const [isCheckingOut, setCheckingOut] = useState(false);

    useEffect(() => {
        setShowDownSell(defaultShowDownSell);
    }, []);

    const checkout = () => {
        setCheckingOut(true);
        return axios.post(backendEndpoints.startCheckout(price.id))
            .then((resp) => {
                const url = resp?.data?.data?.checkout_url;
                if (url) {
                    window.location.href = url;
                }
            })
            .catch(e => alert(e.message))
            .finally(() => setCheckingOut(false));
    }

    return (
        <Modal show onHide={onCancel} size="md">
            <Modal.Header closeButton className="d-flex">
                <Modal.Title className="flex-grow-1 text-center">{price.product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h6>Select subscription cycle:</h6>
                <div className="d-flex gap-2">
                    <OptionButton selected={showingDownSell} onClick={() => setShowDownSell(true)}>
                        <span>&nbsp;</span>
                        <span>Monthly billing</span>
                        <div className="d-flex align-items-center">
                            <AmountSpan small price={mainPrice.downsell} />
                            <UnitSpan price={mainPrice.downsell} showPerUser={false} />
                        </div>
                        <span className="text-sm">&nbsp;</span>
                    </OptionButton>
                    <OptionButton selected={!showingDownSell} onClick={() => setShowDownSell(false)}>
                        <OptionButton.Discount mainPriceAmount={mainPrice.unit_amount} downSellAmount={mainPrice.downsell.unit_amount} currency={mainPrice.currency} />
                        <div>Annual billing</div>
                        <div className="d-flex align-items-center">
                            <AmountSpan small price={mainPrice} showMonthly />
                            <UnitSpan price={mainPrice} showPerUser={false} showMonthly />
                        </div>
                        <span className="text-sm">&nbsp;</span>
                    </OptionButton>
                </div>
                <div className="mt-4">
                    <p>VAT will be added based on billing address</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button onClick={checkout} className="d-inline-flex align-items-center" disabled={isCheckingOut}  size="lg">
                    Next: Enter billing address&nbsp;
                    {isCheckingOut && <Loading />}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

IndividualPriceModal.propTypes = IndividualPriceModalPropTypes;
IndividualPriceModal.defaultProps = IndividualPriceModalDefaultProps;
