import React from 'react';
import cx from 'classnames';
import styles from './IconContent.module.scss';

const IconContentPropTypes = {}

const IconContentDefaultProps = {}

export default function IconContent({ icon, children, inverseColors, noMargin }) {
    return (
        <div
            className={cx({
                "d-flex": true,
                "mb-5": !noMargin,
                "mb-2": noMargin,
            })}
        >
            <div className="me-3">{icon}</div>
            <div
                className={cx({
                    'text-left': true,
                    [styles.InverseColors]: inverseColors
                })}
            >
                {children}
            </div>
        </div>
    );
}

IconContent.propTypes = IconContentPropTypes;
IconContent.defaultProps = IconContentDefaultProps;
