import React from 'react';
import styles from './TileMenu.module.scss';

const TileMenuPropTypes = {}

const TileMenuDefaultProps = {}

export default function TileMenu({ children }) {
    return (
        <div className={`${styles.TileMenu} d-inline-flex flex-md-row flex-column`}>
            {children}
        </div>
    );
}

function Item({ children, header, icon: Icon, to }) {
    return (
        <a href={to} className={`${styles.ItemContainer} align-items-center`}>
            <div className={`${styles.IconContainer} text-nowrap`}>
                <Icon />
            </div>
            <span className={styles.Header}>{header}</span>
            {children}
        </a>
    )
}

TileMenu.Item = Item

TileMenu.propTypes = TileMenuPropTypes;
TileMenu.defaultProps = TileMenuDefaultProps;
