import React, {useState} from 'react';
import moment from "moment/moment";
import {standardDateFormat} from "../../utils/momentHelpers";
import CopyText from "../layout/CopyLink";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {Link45deg} from "react-bootstrap-icons";
import Loading from "../Loading";
import usePost from "../hooks/usePost";
import {backendEndpoints} from "../../libs/backendEndpoints";

/**
 * Generate a group join link.
 */
export default function GenerateGroupJoinLink({ groupId }) {
    const [{ url: groupJoinUrl, expires_at: expiresAt }, setGroupJoinData] = useState({});
    const { onSubmit, isSubmitting, error: submitError } = usePost({
        url: backendEndpoints.createJoinUrl(groupId), returnResponseData: true
    });

    return (
        <>
            {groupJoinUrl ? (
                <>
                    <p>Below link is valid until {moment(expiresAt).format(standardDateFormat)}.</p>
                    <CopyText text={groupJoinUrl} />
                </>
            ) : (
                <>
                    {submitError && <Alert>{submitError}</Alert>}
                    <Button
                        size="sm"
                        disabled={isSubmitting}
                        onClick={() => {
                            onSubmit().then((joinData) => {
                                setGroupJoinData(joinData);
                            })
                        }}
                    >
                        <Link45deg />
                        &nbsp;Generate secure group link
                        {isSubmitting && <Loading size="sm" />}
                    </Button>
                </>
            )}
        </>
    );
}
