import React, {useMemo} from 'react';
import {concepts} from "../libs/lang";
import Description from "../components/Description";
import {FAQS, FAQ_CATEGORIES} from "../libs/faqs";
import {Accordion} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function FAQ() {
    const hashMapPerTag = useMemo(() => {
        return FAQS.reduce((acc, o) => {
            o.tags?.forEach((tagValue) => {
                if (!acc[tagValue]) {
                    acc[tagValue] = [];
                }
                acc[tagValue].push(o);
            })
            return acc;
        }, {});
    }, []);

    return (
        <Container>
            <Row className="mb-5">
                <Col>
                    <div className="bg-lightest p-5 rounded-bottom-right-lg">
                        <h1>{concepts.FAQ}</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    {Object.keys(FAQ_CATEGORIES).map((key) => {
                        const value = FAQ_CATEGORIES[key];
                        return (
                            <section key={value} className="mb-5">
                                <span id={key}></span>
                                <h3 className="pb-3">{value}</h3>
                                {hashMapPerTag[value]?.map(({ title, description, tags}) => (
                                    <Accordion key={title}>
                                        <Accordion.Header>{title}</Accordion.Header>
                                        <Accordion.Body className="pb-5">
                                            {description.split('\n').map((text) => <Description key={text}>{text}</Description>)}
                                        </Accordion.Body>
                                    </Accordion>
                                ))}
                            </section>
                        )
                    })}
                </Col>
            </Row>
        </Container>
    );
}
