import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useTitle from "../components/hooks/useTitle";
import {routes} from "../utils";
import styles from './Home.module.scss';
import {concepts as concept, concepts} from "../libs/lang";
import adventurersBg from "../assets/images/backgrounds/adventureres.jpg";
import photo51logo from "../assets/images/logos/photo51-red-desktop-icon.svg";
import installationIcon from "../assets/images/icons/gray-icons/Icon-Install.svg";
import collaborateIcon from "../assets/images/icons/gray-icons/Icon-Collaborate.svg";
import importIcon from "../assets/images/icons/gray-icons/Icon-Import.svg";
import createUnlimitedTeamIcon from "../assets/images/icons/gray-icons/Icon-Create unlimited Teams.svg";
import crisprToolIcon from "../assets/images/icons/gray-icons/Icon-CRISPR-Cas gene editing tools.svg";
import plasmidViewIcon from "../assets/images/icons/gray-icons/Icon-Plasmid inventory and preview.svg";
import desktopCloudIcon from "../assets/images/icons/gray-icons/Icon-Desktop and cloud.svg";
import Container from "react-bootstrap/Container";
import ShowCase from "./partials/ShowCase";
import DesktopCloudBanner from "./partials/DesktopCloudBanner";
import useAuth from "../components/hooks/useAuth";
import useMetaDescription from "../components/hooks/useMetaDescription";



const containerWithIcons = (
    <Container fluid className="text-xl text-darkest text-center mt-5 pt-4">
        <Row className="align-items-center">
            <Col className={styles.IconBlock}>
                <img alt="Install icon" src={installationIcon} className={styles.BrownIcon} />
                <strong className="d-block">Install</strong>
            </Col>
            <Col className={styles.IconBlock}>
                <img alt="Import icon" src={importIcon} className={styles.BrownIcon} />
                <strong className="d-block">Import</strong>
            </Col>
            <Col className={styles.IconBlock}>
                <img alt="Share icon" src={collaborateIcon} className={styles.BrownIcon} />
                <strong className="d-block">Collaborate</strong>
            </Col>
        </Row>
    </Container>
);

export default function Home() {
    useTitle(`Fearless League - ${concepts.Slogan}`);
    useMetaDescription(
        `Revolutionize your research workflow with ${concept.Photo51}, the intuitive molecular biology software. Work anywhere with secure cloud access, and collaborate seamlessly in real-time. Find what you need fast with our intuitive inventory and design precise CRISPR-Cas edits. Try ${concepts.Photo51} for free today!`
    )
    return (
        <>
            <Container>
                <Row>
                    <Col
                        style={{
                            backgroundImage: `url(${adventurersBg})`,
                            backgroundSize: 'cover',
                            backgroundPositionY: 'center center',
                            minHeight: '500px',
                        }}
                        className="d-inline-flex align-items-start justify-content-center flex-column px-5 line-height-2"
                    >
                        <h1 className={`${styles.FoldText} text-white mb-5`}>
                            Life Sciences<br/>
                            Software
                        </h1>

                        <a href={routes.photo51} className="btn btn-primary btn-xl shadow-sm">{`Discover ${concepts.Photo51}`}</a>
                    </Col>
                </Row>
            </Container>
            <Container className={`bg-lighter p-5 ${styles.RoundedBottom}`}>
                <Row className="text-center">
                    <Col className="mb-5 mt-3">
                        <h2 className="mb-4">Collaborative Tools For Bold Biologists</h2>
                        <p className={styles.MaxWidthParagraph}>
                            At Fearless League, we share your passion for scientific exploration.
                            We believe the journey of discovery thrives on&nbsp;
                            <b>collaboration</b>
                            &nbsp;and&nbsp;
                            <b>continuous improvement</b>
                            .
                            <br/>
                            This is why we offer:
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src={createUnlimitedTeamIcon} height={80} className="mb-3" />
                        <h5>Powerful collaboration tools</h5>

                        <p>
                            Streamline teamwork and communication with intuitive features
                            designed to keep your research on track.
                        </p>
                    </Col>
                    <Col>
                        <img src={plasmidViewIcon} height={80} className="mb-3" />
                        <h5>Innovative solutions</h5>
                        <p>
                            Stay ahead of the curve with cutting-edge software that
                            empowers you to tackle complex scientific challenges.
                        </p>
                    </Col>
                    <Col>
                        <img src={crisprToolIcon} height={80} className="mb-3" />
                        <h5>Expert resources</h5>
                        <p>
                            Access a wealth of valuable resources to support your research journey
                            and help you achieve groundbreaking results.
                        </p>
                    </Col>
                    <Col>
                        <img src={desktopCloudIcon} height={80} className="mb-3" />
                        <h5>Unwavering support</h5>
                        <p>
                            We&apos;re here to partner with you, every step of the way. We believe
                            in open communication and value your feedback to continuously improve
                            our software.
                        </p>
                    </Col>
                </Row>
            </Container>
            <section className="text-center py-5 my-5">
                <a href={routes.photo51} className="text-decoration-none">
                    <img
                        src={photo51logo}
                        className={`${styles.Photo51Logo} mb-3`}
                        alt="Photo51 Logo"
                    />
                </a>
                <h2 className="mb-0">{concepts.Photo51}</h2>
                <div className="fw-bold text-xxl mb-4">Molecular biology software</div>
                <a href="#showcase-section" className="btn btn-primary btn-xl shadow">
                    {concepts.Photo51} showcase
                </a>
            </section>

            <section className="mb-5">
                <Container>
                    <Row>
                        <Col sm={5}>
                            <div
                                className={`${styles.EmpowerSection} py-5 px-2 px-sm-5 bg-dark-transparent bg-dark-transparent-span-before text-white mt-4`}
                            >
                                <h2>Empower your team in<br/>less than 3 minutes!</h2>
                                <a className="btn btn-primary btn-lg text-lg mt-3" href={routes.photo51}>
                                    Learn more
                                </a>
                            </div>
                        </Col>
                        <Col sm={5}>{containerWithIcons}</Col>
                    </Row>
                </Container>
            </section>
            <section className="py-5" id="showcase-section">
                <ShowCase />
            </section>
            <section className="mt-5">
                <DesktopCloudBanner/>
            </section>
        </>
    );
}

