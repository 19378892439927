import React from 'react';
import styles from "./ManualPricingTable.module.scss";
import { isCustom } from './price';

export function getCurrencySymbol(currency) {
    return (
        <>
            {currency === 'eur' && <span>&euro;&nbsp;</span>}
            {currency === 'usd' && <span>&dollar;&nbsp;</span>}
        </>
    );
}

const AmountSpanPropTypes = {}

const AmountSpanDefaultProps = {}

export default function AmountSpan({ price: selectedPrice, showMonthly, decimals = 2, small }) {
    if (isCustom(selectedPrice)) {
        return <b>Custom solutions</b>
    }
    const amount = selectedPrice.unit_amount / 100 / (showMonthly && selectedPrice.recurring?.interval === 'year' ? 12 : 1);
    return amount === 0 ? <span className={`${styles.Price}`}>Free</span>
        : (
        <span className={`${styles.Price} ${small ? styles.Small : ''}`}>
            {getCurrencySymbol(selectedPrice.currency)}
            {amount.toFixed(decimals)}
        </span>
    );
}

AmountSpan.propTypes = AmountSpanPropTypes;
AmountSpan.defaultProps = AmountSpanDefaultProps;
