import {createContext, useContext} from "react";

const BackendFormContext = createContext({
    footerContent: null,
    error: undefined,
    feedback: undefined,
});

export default function useBackendFormContext() {
    return useContext(BackendFormContext);
}

export function BackendFormContextProvider({ children, value }) {
    return <BackendFormContext.Provider value={value}>{children}</BackendFormContext.Provider>
}
