import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import BackendForm from "../components/forms/BackendForm";
import Pages from "../components/layout/Pages";
import FloatingLabelField from "../components/forms/FloatingLabelField";
import {routes} from "../utils";
import useAuth from "../components/hooks/useAuth";
import {useNavigate} from "react-router";
import {At, Lock} from "react-bootstrap-icons";
import {backendEndpoints} from "../libs/backendEndpoints";
import useTitle from "../components/hooks/useTitle";

const initialObject = {
    email_or_username: '',
    password: '',
}

export default function Login() {
    useTitle('Log in')
    const {saveToken, user, isLoading} = useAuth()
    const navigate = useNavigate()
    const [sp] = useSearchParams();

    useEffect(() => {
        if (!isLoading && user) navigate(routes.dashboard)
    }, [user, isLoading])

    return (
        <Pages.Centred width="xs">
            <BackendForm
                initialValues={initialObject}
                targetEndpoint={backendEndpoints.login}
                targetMethod="POST"
                onSuccess={({data}) => {
                    saveToken(data.token);
                    // Hard refresh, such that the login cookie read into the react state;
                    if (data.two_factor) {
                        window.location.href = routes.twoFactorChallenge;
                    } else {
                        window.location.href = sp?.get('redirect') || routes.landing;
                    }
                }}
                footerContent={(
                    <div className="py-2 d-inline-flex gap-3">
                        <a href={routes.forgotPassword} className="btn-link fw-bold">Forgotten password?</a>
                    </div>
                )}
            >
                <h1 className="text-center mb-5">Log in</h1>
                <FloatingLabelField
                    name="email_or_username"
                    label="E-mail or username"
                    icon={<At/>}
                    disappearingLabel
                />
                <FloatingLabelField
                    type="password"
                    name="password"
                    label="Password"
                    icon={<Lock/>}
                    disappearingLabel
                />
            </BackendForm>
            <div className="bg-dark-transparent py-5 px-5 text-center mt-5 rounded-bottom-lg">
                <strong className="text-lg d-block mb-3 text-white">Don't have an account yet?</strong>
                <a className="btn btn-primary btn-xl" href={routes.signUp}>Create an account</a>
            </div>
        </Pages.Centred>
    );
}
