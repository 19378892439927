import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ListedInformation({ title, children }) {
    return (
        <Container>
            <Row>
                <Col sm={3}>
                    <h2>{title}</h2>
                </Col>
                <Col sm={9}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

ListedInformation.Item = ({ title = '', description = '', children, showLine = true }) => (
    <>
        <Row>
            <Col className="py-3">
                {title && <h4 className="mb-3">{title}</h4>}
                {typeof description === 'string' && <p className="mb-0">{description}</p>}
                {typeof description === 'object' && description}
            </Col>
            <Col md={4}>
                <p>{children}</p>
            </Col>
        </Row>
        {showLine && <hr />}
    </>
)
