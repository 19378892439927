import React from 'react';
import {useField} from "formik";
import FloatingLabelField from "./FloatingLabelField";
import {DataRow} from "../layout";

export const customFieldsKeys = {
    studentNumber: 'student_number'
};

const userCustomFieldsLabelMapping = {
    [customFieldsKeys.studentNumber]: 'Student number'
}

export function getCustomFieldLabel(key) {
    return key in userCustomFieldsLabelMapping ? userCustomFieldsLabelMapping[key] : key;
}

/**
 * Show a list of input elements for the user custom fields.
 */
export default function UserCustomFields() {
    const [{ value: customFields }] = useField('custom_fields');

    return (
        <>
            {Array.isArray(customFields) && customFields.map(({ name }, idx) => (
                <FloatingLabelField
                    key={name}
                    name={`custom_fields[${idx}][value]`}
                    label={getCustomFieldLabel(name)}
                />
            ))}
        </>
    );
}

export function ReadOnlyCustomFields({ customFields }) {
    return (
        <>
            {customFields.map(({ name, value }) => (
                <DataRow
                    key={name}
                    label={name in userCustomFieldsLabelMapping
                        ? userCustomFieldsLabelMapping[name] : name}
                >
                    {value}
                </DataRow>
            ))}
        </>
    );
}
