import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Field} from "formik";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import cx from "classnames";

const CheckboxesPropTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    })),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}

const CheckboxesDefaultProps = {
    onChange: undefined,
}

export default function Checkboxes({ options, name, label, onChange }) {
    return (
        <>
            <div>
                <FormCheckLabel>{label}</FormCheckLabel>
            </div>
            {options.map((o) => {
                const inputId = `checkbox-${name}-${o.value}`;
                return (
                    <div key={o.value}>
                        <Field
                            type="radio"
                            name={name}
                            onChange={onChange}
                            value={o.value}
                            id={inputId}
                            disabled={o.disabled}
                        />
                        <label htmlFor={inputId} className={cx({ 'disabled': o.disabled })}>{o.label}</label>
                    </div>
                )
            })}

            <ErrorMessage name={name} className="text-danger" component="span" />
        </>
    );
}

Checkboxes.propTypes = CheckboxesPropTypes;
Checkboxes.defaultProps = CheckboxesDefaultProps;
