import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {STRIPE_PUBLISHABLE_KEY} from "../libs/keys";
import {addStripeToHead} from "../utils/stripe";
import ManualPricingTable from "./billing/ManualPricingTable";

const StripePricingTablePropTypes = {
    monthlyTableId: PropTypes.string,
    annualTableId: PropTypes.string,
    redirectTo: PropTypes.string,
    customerEmail: PropTypes.string,
    clientReferenceId: PropTypes.string,
}

const StripePricingTableDefaultProps = {
    redirectTo: '',
    annualTableId: '',
    monthlyTableId: '',
    customerEmail: '',
    clientReferenceId: undefined,
}

let hasAlreadyAddedStripeHeader = false;

export default function StripePricingTable({ tableId, customerEmail, redirectTo, clientReferenceId, groupId, prePrices, postPrices, filterScope, center }) {
    const isStripe = tableId?.indexOf('prctbl_') === 0;

    useEffect(() => {
        if (isStripe && !hasAlreadyAddedStripeHeader) {
            addStripeToHead();
            hasAlreadyAddedStripeHeader = true;
        }
    }, [isStripe]);

    return (
        <div className="position-relative">
            {tableId && typeof tableId === 'string' && (
                isStripe ? (
                    <>
                        {redirectTo && (
                            <a href={redirectTo} className="position-absolute h-100 w-100">
                                &nbsp;
                            </a>
                        )}
                        <stripe-pricing-table
                            key={tableId}
                            pricing-table-id={tableId}
                            publishable-key={STRIPE_PUBLISHABLE_KEY}
                            customer-email={customerEmail}
                            client-reference-id={clientReferenceId}
                        >
                            {tableId}
                        </stripe-pricing-table>
                    </>
                ) : (
                    <ManualPricingTable
                        endpoint={tableId}
                        groupId={groupId}
                        prePrices={prePrices}
                        postPrices={postPrices}
                        filterScope={filterScope}
                        center={center}
                    />
                )
            )}
        </div>
    );
}

StripePricingTable.propTypes = StripePricingTablePropTypes;
StripePricingTable.defaultProps = StripePricingTableDefaultProps;

export const PRICING_TABLE_PRODUCT = {
    INSTITUTE: {
        product: {
            name: 'Institute',
            metadata: {
                accountType: 'group',
            },
            description: 'Contact us for custom, on-site solutions tailored to your institute\'s specific needs. Photo51 Institute plans offer scalability, advanced security features, and dedicated support to empower your large-scale research initiatives.\n',
        },
        unit_amount: 'custom',
    },
}
