import React from 'react';
import Button from "react-bootstrap/Button";
import copyToClipboard from "../../utils/copyToClipboard";
import {Clipboard} from "react-bootstrap-icons";
import Alert from "react-bootstrap/Alert";
import {useDisappearingFeedback} from "../hooks/useDisappearingFeedback";


export default function CopyText({ text, multiline }) {
    const { feedback, setFeedback } = useDisappearingFeedback();
    const { feedback: error, setFeedback: setError } = useDisappearingFeedback();

    return (
        <>
            <div className="d-flex gap-2 mb-1">
                <div className={`form-control border border-1 bg-lightest text-sm ${multiline ? 'overflow-scroll' : 'text-break text-truncate'} py-2`}>
                    {multiline
                        ? text.split('\n').map((t, idx) => <div key={`${idx}-${t}`}>{t}&nbsp;</div>)
                        : text}
                </div>
                <Button
                    variant="primary"
                    className="rounded-1"
                    onClick={() => {
                        copyToClipboard(text)
                            .then(() => {
                                setFeedback('Copied to clipboard!');
                            })
                            .catch(e => {
                                setError('Could not copy to clipboard: '+e.message)
                            })
                    }}
                >
                    <Clipboard />
                </Button>
            </div>
            {feedback && <Alert variant="success" className="text-sm py-2">{feedback}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
        </>
    );
}

