import React, {useState, useEffect, useMemo} from 'react';
import {Trash} from "react-bootstrap-icons";
import moment from "moment";
import axios from "axios";
import useGet from "../../components/hooks/useGet";
import {Alert, Button} from "react-bootstrap";
import Loading from "../../components/Loading";
import {Table} from "../../components/layout";
import {standardDateTimeFormat} from "../../utils/momentHelpers";
import Spinner from "react-bootstrap/Spinner";
import OsIcon from "../../components/OSIcon";

export default function Security() {
    const { data, isLoading, error, reload } = useGet({
        endpoint: '/tokens'
    });
    const [deleteError, setDeleteError] = useState('');
    const [isDeletingIds, setDeletingIds] = useState({});

    useEffect(() => {
        setDeleteError('');
    }, [isLoading]);

    const invalidateLoginToken = (id) => {
        setDeleteError('');
        if (isDeletingIds.hasOwnProperty(id) === false) {
            isDeletingIds[id] = true;
            setDeletingIds(isDeletingIds);
            axios.delete('/tokens/'+id)
                .then(() => reload())
                .catch(e => {
                    setDeleteError(e.message)
                })
                .finally(() => {
                    setDeletingIds((oldDeleting) => {
                        delete oldDeleting[id];
                        return oldDeleting;
                    });
                })
        }
    }

    const columns = useMemo(() => [
        {
            header: 'Device',
            accessor: 'name',
            Cell: ({ value }) => (
                <div className="d-flex align-items-center">
                    <OsIcon name={value} />
                    &nbsp;
                    <span>
                        <div className="text-truncate" style={{ maxWidth: '300px'}}>{value}</div>
                    </span>
                </div>
            ),
        }, {
            header: 'Last used',
            accessor: 'last_used_at',
            Cell: ({ value }) => moment(value).format(standardDateTimeFormat),
        }, {
            header: '',
            accessor: 'id',
            Cell: ({ value }) => (
                <div className="text-right">
                    {isDeletingIds[value]
                        ? <Spinner animation="grow" size="xs" />
                        :(
                        <Button size="sm" className="py-0 border-0" variant="outline-danger" onClick={() => invalidateLoginToken(value)}>
                            <Trash />
                        </Button>
                    )}
                </div>
            ),
        }
    ], [])
    return (
        <>
            {error && typeof error === 'string' && <Alert variant="danger">{error}</Alert>}
            {deleteError && typeof deleteError === 'string' && <Alert variant="danger">{deleteError}</Alert>}
            {isLoading && <Loading />}
            {Array.isArray(data) && (
                data.length === 0
                    ? <p>No logins</p>
                    : <Table memoisedColumns={columns} memoisedData={data} />
            )}
        </>
    );
}
