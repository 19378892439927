import React from 'react';
import _ from 'lodash';
import {Link, useParams} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import {useField} from "formik";
import useGet from "../../../components/hooks/useGet";
import {BackendForm, Select} from "../../../components/forms";
import {Pages} from "../../../components/layout";
import FloatingLabelField from "../../../components/forms/FloatingLabelField";
import {defaultDateTimeFormat} from "../../../utils/date";
import {adminRoutes, newString} from "../../../utils/routes";
import {concepts} from "../../../libs/lang";
import {backendEndpoints} from "../../../libs/backendEndpoints";
import SubscriptionRuleDeleteButton from "../../../components/subscriptions/SubscriptionRuleDeleteButton";
import BackendSelect from "../../../components/forms/BackendSelect";
import SubscriptionRuleCustomFieldsBuilder from "./SubscriptionRuleCustomFieldsBuilder";

const typeName = 'type';

function ValueField({ name }) {
    const [field] = useField(name);

    let label = 'Value';
    if (field.value === 'email_domain') {
        label = 'Full email domain (everything that comes after \'@\')';
    } else if (field.value === 'temporary_group_access') {
        label = 'Group ID';
    }

    return (
        <FloatingLabelField
            name="value"
            label={label}
            required
        />
    );
}

export default function AdminSubscriptionRule() {
    const { ruleId } = useParams();
    const isNew = ruleId === newString;
    const { data: rule} = useGet({
        endpoint: isNew ? '' : `/admin/subscription-rules/${ruleId}`
    });
    const { data: types} = useGet({
        endpoint: `/admin/subscription-rules/types`
    });

    return (
        <Pages.Centred>
            <Button href={adminRoutes.subscriptionRules}>Back to rules</Button>
            {isNew ? <h1>New Rule</h1> : <h1>Rule #{rule?.id || '...'}</h1>}
            <div className="d-flex gap-4 mb-3">
                <span>Created at: {moment(rule?.created_at).format(defaultDateTimeFormat)}</span>
            </div>
            {(isNew || rule) && types && (
                <>
                    <Row>
                        <Col sm={4}>
                            <BackendForm
                                initialValues={isNew ? {
                                    type: 'email_domain',
                                    maximum_applications: 300,
                                    signup_custom_fields: {},
                                } : _.omit(rule, ['users'])}
                                targetEndpoint={isNew ? backendEndpoints.addSubscriptionRule : backendEndpoints.subscriptionRule(ruleId)}
                                targetMethod={isNew ? 'POST' : "PUT"}
                                onSuccess={(resp) => {
                                    if (resp.data) {
                                        if (isNew) {
                                            window.location.href = resp.data?.id
                                                ? adminRoutes.subscriptionRule(resp.data.id)
                                                : adminRoutes.subscriptionRules;
                                        } else {
                                            // If successful, reload page to show the new list of users.
                                            window.location.reload();
                                        }
                                    }
                                }}
                            >
                                <FloatingLabelField name="title" label="Title"/>
                                <FloatingLabelField name="description" label="Description" type="textarea"/>
                                <BackendSelect
                                    endpoint={backendEndpoints.getAdminInstitutes}
                                    name="institute_id"
                                    label="Institute"
                                    placeholder="----"
                                />
                                <div className="mb-5"></div>
                                <Select
                                    label="Rule type"
                                    name={typeName}
                                    options={Object.keys(types || {}).map((k) => ({value: k, label: types[k]}))}
                                    required
                                />
                                <div className="mb-2"></div>
                                <ValueField name={typeName}/>
                                <FloatingLabelField
                                    name="max_applications"
                                    label="Maximum applications"
                                    type="number"
                                    required
                                />
                                <FloatingLabelField
                                    name="start_date"
                                    label="Start date"
                                    type="date"
                                />
                                <FloatingLabelField
                                    name="end_date"
                                    label="End date"
                                    type="date"
                                />
                                <h5 className="mt-5">Custom sign-up fields</h5>
                                <SubscriptionRuleCustomFieldsBuilder />
                            </BackendForm>
                        </Col>
                        <Col>
                            {rule && (
                                <>
                                    <h5>{rule?.users?.length} {concepts.Users.toLowerCase()}</h5>
                                    <p>{concepts.Users} accounts that fall under this rule:</p>
                                    <ul>
                                        {rule?.users?.map(({ email, id }) => (
                                            <li key={id}>
                                                {email} <Link to={adminRoutes.user(id)}>view</Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <hr />
                                    <h6>Delete {concepts.SubscriptionRule}</h6>
                                    <SubscriptionRuleDeleteButton rule={rule} />
                                </>
                            )}

                        </Col>
                    </Row>
                </>
            )}

        </Pages.Centred>
    );
}
