import React from 'react';
import axios from "axios";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Button from "react-bootstrap/Button";
import {useConfirm} from "../contexts/ConfirmationModalContext";
import {adminRoutes} from "../../utils/routes";

export default function SubscriptionRuleDeleteButton({ rule }) {
    const { open } = useConfirm();
    return (
        <Button
            variant="outline-danger"
            size="sm"
            onClick={() => {
                open(
                    `Are you sure you want to delete this?`,
                    () => axios.delete(backendEndpoints.deleteSubscriptionRule(rule.id))
                        .then(() => {
                            window.location.href = adminRoutes.subscriptionRules;
                        }),
                    true,
                )
            }}
        >
            Delete rule
        </Button>
    );
}
