import React from 'react';
import cloudIcon from './../../assets/images/icons/gray-icons/Icon-Cloud.svg';
import desktopIcon from './../../assets/images/icons/gray-icons/Icon-Desktop.svg';
import styles from './DesktopCloudBanner.module.scss';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {routes} from "../../utils";


export default function DesktopCloudBanner() {
    return (
        <Container className={`${styles.Rounded} py-5 position-relative bg-lighter`}>
            <Row className="align-items-center">
                <Col>
                    <div className="py-3 px-5">
                        <h2 className="line-height-1-5">Unlimited installations,<br/>desktop and cloud!</h2>
                        <a className="btn btn-primary btn-xl mt-3 shadow" href={routes.photo51}>
                            Try for free
                        </a>
                    </div>
                </Col>
                <Col>
                    <img src={desktopIcon} alt="desktop icon" className={styles.Icon} />
                    <img src={cloudIcon} alt="cloud icon" className={styles.Icon} />
                </Col>
            </Row>
        </Container>
    );
}
