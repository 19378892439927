import { useQuery } from 'react-query'
import axios, {AxiosError} from 'axios'
import {useEffect, useState} from "react";

/**
 *
 * @param endpoint
 * @param onSuccess
 * @param onError
 * @param params
 * @param dataKey In nearly all cases, the JSON response contains a data key which contains the data you need. However,
 *                it may be the case that you need another key.
 * @returns {{isLoading: boolean, reload: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>)) =>
 *     Promise<QueryObserverResult<TQueryFnData, unknown>>, data: TQueryFnData, error: (string|*)}}
 */
export default function useGet({
    endpoint, onSuccess, onError,
    headers = {},
    params = {},
    dataKey = 'data',
    cacheTime = 0,
    smoothRefresh = false,
    retry = 2,
}) {
    const [newData, setNewData] = useState();
    const successHandler = ({ data }) => dataKey && data.hasOwnProperty(dataKey) ? data[dataKey] : data;
    const { data, isLoading, error, refetch } = useQuery(
        // The query will automatically update when this key changes
        [endpoint, JSON.stringify(params)],
        async () => await axios.get(endpoint, { params, headers })
            .then(successHandler),
        {
            enabled: !!endpoint,
            retry,
            onSuccess: onSuccess ? (data) => onSuccess(successHandler({ data })) : undefined,
            onError,
            cacheTime
        }
    );

    useEffect(() => {
        if (smoothRefresh) {
            if (error) {
                setNewData(data);
            } else if (data) {
                setNewData(data);
            }
        }
    }, [data, error, smoothRefresh]);


    return {
        data: smoothRefresh ? newData : data,
        isLoading,
        error: error instanceof AxiosError && error.response.status === 403
            ? 'You do not have access.'
            : error?.response?.data?.message || error?.message,
        reload: refetch
    }
}
