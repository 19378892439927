import React from 'react';
import ShareableLinkPage from "./ShareableLinkPage";
import {useParams} from "react-router";
import useAuth from "../../components/hooks/useAuth";
import {backendEndpoints} from "../../libs/backendEndpoints";
import useGet from "../../components/hooks/useGet";
import {routes} from "../../utils";
import {Alert} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Loading from "../../components/Loading";
import {concepts} from "../../libs/lang";
import {Send} from "react-bootstrap-icons";
import {openPhoto51DesktopApplication, OpenPhoto51SearchFlags} from "../OpenPhoto51";
import usePost from "../../components/hooks/usePost";

const SHOW_N = 5;

const listItemClassName = "border-bottom border-1 border-gray px-3 py-1";

function ShareRequestListItem({ shareRequest }) {
    const {
        filename,
    } = shareRequest;
    return (
        <li className={listItemClassName}>
            <p className="mb-0 fw-bold">{filename}</p>
        </li>
    )
}


/**
 * User can receive a link from a user that wants to share photo51 data.
 * When clicking on that link, this component will show.
 */
export default function ShareRequestAccessLink() {
    const { user } = useAuth();
    const { shareRequestIds, hash } = useParams();

    const { isSubmitting, error: submitError, onSubmit } = usePost({
        url: backendEndpoints.copyShareRequest(shareRequestIds, hash),
    });

    const { data: shareRequests, isLoading, error } = useGet({
        endpoint: shareRequestIds ? backendEndpoints.getShareRequestViaHash(shareRequestIds, hash) : '',
        params: { _with: ['reagent'] }
    });

    const thisPageUrl = routes.shareRequestAccessLink(shareRequestIds, hash);
    const sender = shareRequests ? shareRequests[0].created_by?.first_name : '...';
    const showOpenPhoto51Button = typeof submitError === 'string' && submitError.indexOf('Please open Photo51') > -1;

    return (
        <ShareableLinkPage
            tabTitle={`Access ${shareRequests?.length || '...'} files from ${sender}`}
            header={isLoading ? 'Retrieving link\'s content...' : (shareRequests?.length > 1
                ? `${shareRequests?.length || '...'} files are waiting for you`
                : `${shareRequests?.length || '...'} file is waiting for you`
            )}
            currentPageUrl={thisPageUrl}
            isLoading={isLoading}
            error={error}
            shareRequests={shareRequests}
            arrow={false}
        >
            <p className="text-left">
                <span>{sender} has shared the following {shareRequests?.length > 1 ? 'items': 'item'} with you:</span>
            </p>
            <ul className="text-left list-unstyled rounded-3 border border-1 border-gray">
                {shareRequests?.slice(0, SHOW_N).map((sr) => (
                    <ShareRequestListItem key={sr.id} shareRequest={sr} />
                ))}
                {shareRequests?.length - SHOW_N > 0 && (
                    <li className={listItemClassName}>
                        <p className="mb-0">{`And ${shareRequests?.length - SHOW_N} more file`}</p>
                    </li>
                )}
            </ul>
            {showOpenPhoto51Button === false && (
                <Button
                    size="lg"
                    onClick={() => {
                        onSubmit({user: user.id}).then((success) => {
                            if (success) {
                                const url = new URL(window.location.origin + routes.openPhoto51);
                                url.searchParams.set(OpenPhoto51SearchFlags.SHARE_REQUEST_ACCESS_LINK, '1');
                                window.location.href = url.toString();
                            }
                        });
                    }}
                    disabled={isSubmitting}
                >
                    <Send/>
                    &nbsp;
                    Send to my {concepts.Photo51}
                    {isSubmitting && <Loading size="sm"/>}
                </Button>
            )}
            {submitError && (
                <>
                    <Alert variant="danger" className="mt-2">{submitError}</Alert>
                    {showOpenPhoto51Button && (
                        <Button
                            onClick={() => {
                                openPhoto51DesktopApplication()
                            }}
                        >
                            Open {concepts.Photo51}
                        </Button>
                    )}
                </>
            )}
        </ShareableLinkPage>
    );
}
