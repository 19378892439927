import React from 'react';
import {Pages} from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cx from "classnames";
import useTitle from "../components/hooks/useTitle";
import {concepts} from "../libs/lang";
import binoculers from "../assets/images/backgrounds/binoculers.jpg";
import {routes} from "../utils";
import useMetaDescription from "../components/hooks/useMetaDescription";


export default function AboutUs() {
    useTitle(`About our ${concepts.Group}`)
    useMetaDescription('Unleash your scientific potential with Fearless League. Collaborative tools and a supportive community fuel breakthroughs. Join the movement!');

    return (
        <Pages.Base>
            <Container
                className={cx({
                    'py-vh5 ps-5 t': true,
                })}
                style={{
                    background: `url(${binoculers}) no-repeat center center`
                }}
            >
                <h1 className="text-white my-5">
                    We are
                    <br/>
                    Fearless League
                </h1>
                <a href="#more" className="btn btn-primary btn-xl">Discover more</a>
            </Container>
            <section className="py-vh5" id="more">
                <Container>
                    <Row>
                        <Col sm={{span: 10, offset: 1}} className="text-center my-5">
                            <p className="fw-semibold text-xl font-header text-black">
                                Fearless League: Empowering Scientists Through Collaborative Discovery
                            </p>
                            <h2 className="my-4">
                                A passion for discovery and a relentless<br/>
                                pursuit to change the world for the better
                            </h2>
                            <p className="max-width-700 mx-auto">
                                At Fearless League, we share your passion for scientific discovery. We
                                believe that brilliant minds achieve greater things together. That's
                                why we've built a collaborative environment and developed cutting-edge
                                software like {concepts.Photo51}.
                            </p>
                            <p className="max-width-700 mx-auto">
                                This powerful tool is named after the iconic X-ray diffraction image by
                                Rosalind Franklin and Raymond Gosling. Their groundbreaking work, which
                                served as a major stepping stone in scientific discovery, inspires us to
                                empower researchers like you.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="mb-5">
                <Container className="py-vh5 rounded-lg bg-lightest px-5 text-center">
                    <h2 className="mb-4">Our mission</h2>
                    <p className="max-width-700 mx-auto">
                        At Fearless League, we are driven by a core mission: to create a thriving community
                        of fearless scientists who push boundaries and make a positive impact through
                        collaborative discovery.
                    </p>
                </Container>
            </section>
            <section>
                <Container className="py-vh5">
                    <Row>
                        <Col sm={4}>
                            <h2>
                                Unwavering focus on teamwork.
                            </h2>
                        </Col>
                        <Col>
                            <p>
                                We understand the power of collaboration. Every groundbreaking scientific
                                achievement is often the result of a dedicated team effort. Fearless
                                League fosters collective brilliance by providing:
                            </p>
                            <ul>
                                <li>
                                    <b>Collaborative features</b>
                                    &nbsp;within our software to streamline teamwork and
                                    information sharing.
                                </li>
                                <li>
                                    <b>A supportive community</b>
                                    &nbsp;of passionate researchers where
                                    you can connect, share ideas, and inspire each other.
                                </li>
                                <li>
                                    <b>Open communication</b>
                                    &nbsp;channels to ensure your valuable input shapes the future of our products.
                                </li>
                            </ul>
                            <p>
                                <b>
                                    By joining Fearless League, you're not just gaining access to powerful tools;
                                    you're joining a movement.
                                </b>
                                &nbsp;
                                A movement dedicated to scientific excellence through collaboration.
                            </p>
                            <p>
                                Ready to embark on a journey of scientific discovery with a supportive team?
                            </p>
                            <a href={routes.signUp} className="btn btn-primary btn-lg">Sign up for free today!</a>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Pages.Base>
    );
}
