import React, {useRef, useState} from 'react';
import Button from "react-bootstrap/Button";
import BackendForm from "../../components/forms/BackendForm";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import {concepts} from "../../libs/lang";
import {backendEndpoints} from "../../libs/backendEndpoints";
import CreateManyGroupsModals from "../../components/groups/CreateManyGroupsModals";

const GroupsPropTypes = {}
const GroupsDefaultProps = {}

export default function GroupCreate({ onSuccess }) {
    const [showManyModal, setShowManyModal] = useState(false);
    // Keep a ref for reinitializing the form
    const ref = useRef(1);

    return (
        <>
            <BackendForm
                initialValues={{
                    name: '',
                }}
                key={ref.current}
                targetEndpoint={backendEndpoints.createGroup}
                targetMethod="POST"
                onSuccess={(resp) => {
                    // Reinitialize form by increasing the ref counter.
                    ref.current += 1;
                    if (onSuccess) onSuccess(resp);
                }}
                showFooter={false}
            >
                <h5>Create one new {concepts.Group}</h5>
                <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1 me-3">
                        <FloatingLabelField name="name" label={`Your new ${concepts.Group} name`}/>
                    </div>
                    <BackendForm.Footer showError={false} marginTop={false} buttonText="Save"/>
                </div>
                <BackendForm.Feedback showError/>
            </BackendForm>
            <h5>Create many {concepts.Groups}</h5>
            <p>Create many {concepts.Groups.toLowerCase()} conveniently for your all student classes or research groups.</p>
            <Button onClick={() => setShowManyModal(true)}>
                Start process
            </Button>
            {showManyModal && <CreateManyGroupsModals onClose={() => setShowManyModal(false)}/>}
        </>
    );
}

GroupCreate.propTypes = GroupsPropTypes;
GroupCreate.defaultProps = GroupsDefaultProps;
