import React from 'react';
import Spinner from "react-bootstrap/Spinner";


export default function Loading({ size }) {
    return (
        <Spinner animation="grow" size={size} />
    );
}

