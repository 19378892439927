import React from 'react';
import {
    Lock,
    BoxArrowRight,
} from "react-bootstrap-icons";
import settingsIcon from "../../assets/images/icons/account-settings.svg";
import downloadIcon from "../../assets/images/icons/download-icons.svg";
import manageTeamsIcon from "../../assets/images/icons/manage-teams.svg";
import cloudIcon from "../../assets/images/icons/cloud-icon.svg";
import subscriptionIcon from "../../assets/images/icons/subscription-icon.svg";
import inventoryIcon from "../../assets/images/icons/gray-icons/Icon-Plasmid inventory and preview.svg";
import shareIcon from "../../assets/images/icons/gray-icons/Icon-Collaborate.svg";
import crisprIcon from "../../assets/images/icons/gray-icons/Icon-CRISPR-Cas gene editing tools.svg";
import cloudDeskIcon from "../../assets/images/icons/gray-icons/Icon-Desktop and cloud.svg";


const StandardIcons = {
    OffLineOnline: ({ width }) => <img alt="offline icon" src={cloudDeskIcon} className={width ? undefined : "w-100"} style={{ width }} />,
    Labels: ({ width }) => <img alt="folder icon" src={inventoryIcon} className={width ? undefined : "w-100"} style={{ width }} />,
    CrisprCas: ({ width }) => <img alt="crispr icon" src={crisprIcon} className={width ? undefined : "w-100"} style={{ width }} />,
    LibraryShare: ({ width }) => <img alt="share icon" src={shareIcon} className={width ? undefined : "w-100"} style={{ width }} />,
    Cloud: ({ width }) => <img alt="cloud icon" src={cloudIcon} className={width ? undefined : "w-100"} style={{ width }} />,
    Subscriptions: ({ width }) => <img alt="subscription icon" src={subscriptionIcon} className={width ? undefined : "w-100"} style={{ width }} />,
    Group: () => <img alt="group icon" src={manageTeamsIcon} className="w-100" />,
    Logout: BoxArrowRight,
    Security: Lock,
    Download: () => <img alt="download icon" src={downloadIcon} className="w-100" />,
    Account: () => <img alt="settings icon" src={settingsIcon} className="w-100" />,
}

export default StandardIcons

