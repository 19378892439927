export const groupRole = {
    MANAGER: {
        label: 'Administrator',
        value: 'MANAGER',
    },
    MEMBER: {
        label: 'Member',
        value: 'MEMBER',
    }
}
