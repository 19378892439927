import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import useGet from "./hooks/useGet";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Loading from "./Loading";
import {backendEndpoints} from "../libs/backendEndpoints";
import {concepts} from "../libs/lang";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

// Data is received from the backend and depending on its value, the text should be different.
function dataToText(data) {
    if (!data) {
        return {
            label: `Add to ${concepts.Group}`,
            variant: 'primary',
        }
    }
    if (Date.now() - new Date(data?.created_at).getTime() < 60 * 5 * 1000) {
        return {
            label: 'Invited!',
            variant: '',
            className: 'text-success',
        }
    }
    if (Date.now() - new Date(data?.created_at).getTime() < 60 * 60 * 1000) {
        return {
            label: 'Recently invited',
            variant: 'success',
        }
    }

    return {
        label: `Already in ${concepts.Group}`,
        variant: '',
    }
}

const InviteButtonPropTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    groupId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

const InviteButtonDefaultProps = {}

export default function InviteButton({ user, groupId, onSuccess, groupIsSubscribed }) {
    const { data, isLoading, reload } = useGet({ endpoint: `/groups/${groupId}/check-user`, params: { user_id: user.id }})
    const [isInviting, setInviting] = useState(false)
    const [error, setErrorState] = useState(false)
    const [show, setShow] = useState(false);
    const timeout = useRef();

    const openModalBox = () => setShow(true);
    const closeModalBox = () => setShow(false);

    const setError = (message) => {
        setErrorState(message);
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setErrorState('');
        }, 4000);
    };

    const inviteUser = () => {
        setErrorState('');
        setInviting(true)
        axios.post(backendEndpoints.inviteUserToGroup(groupId), { user: user.id })
            .then(() => {
                reload()
                closeModalBox();
                onSuccess()
            })
            .catch((e) => {
                setError(e.response?.data?.message);
            })
            .finally(() => setInviting(false))
    }

    const next = groupIsSubscribed ? openModalBox : inviteUser;

    return (
        <>
            <OverlayTrigger
                overlay={
                    <Tooltip style={{ background: 'transparent' }}>
                        <Alert variant="" className="mb-0">{error}</Alert>
                    </Tooltip>
                }
                placement="left"
                show={!show && error}
            >
                <Button
                    size="sm"
                    disabled={isLoading}
                    variant={dataToText(data).variant}
                    className={dataToText(data).className}
                    onClick={isInviting || data ? undefined : next}
                >
                    {!isLoading && !isInviting && dataToText(data).label}
                    {(isLoading || isInviting) && <Loading />}
                </Button>
            </OverlayTrigger>
            {show && user && (
                <Modal show={show} onHide={closeModalBox} backdrop={false}>
                    <Modal.Header closeButton>
                        <h3 className="text-center flex-grow-1 mb-0">Confirm seat</h3>
                    </Modal.Header>
                    <Modal.Body className="justify-content-center text-center py-5">
                        {user.is_subscribed
                            ? `${user.first_name} already has a subscription. We will give ${user.first_name} the option to choose how to arrange his/her subscription.`
                            : `${user.first_name} has no subscription and will take a seat on your subscription.`}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center flex-column">
                        {error && <Alert className="alert-danger">{error}</Alert>}
                        <Button
                            size="lg"
                            disabled={isLoading}
                            variant="primary"
                            className="mb-0"
                            onClick={isInviting ? undefined : inviteUser}
                        >
                            {user?.is_subscribed ? `Add with optional seat` : `Add with Seat`}
                            {(isLoading || isInviting) && <Loading />}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

InviteButton.propTypes = InviteButtonPropTypes;
InviteButton.defaultProps = InviteButtonDefaultProps;
