import {useEffect, useRef} from "react";
import {useFormikContext} from "formik";

/**
 * Component with the logic that takes cares of storing the current form values in
 * the persistent storage.
 */
export default function StoreOnBlur({ saveValuesToRemember }) {
    const { values } = useFormikContext();
    const timeoutRef = useRef(null);
    useEffect(() => {
        if (saveValuesToRemember && values) {
            timeoutRef.current = setTimeout(
                () => {
                    saveValuesToRemember(values);
                },
                // Wait a good while before saving to persistent storage to not
                // block the CPU with work, so the user has a smooth experience.
                4000,
            );
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [JSON.stringify(values)]);
    return null;
}
