import React from 'react';
import {Apple, Windows} from "react-bootstrap-icons";
import useTitle from "../components/hooks/useTitle";
import Fold from "../components/layout/Fold";
import downloadAppleWindows from './../assets/images/backgrounds/download-apple-windows.jpg';
import styles from './DownloadPhoto51.module.scss';
import {concepts} from "../libs/lang";
import {Alert} from "react-bootstrap";
import links from "../libs/downloadLinks";

const btnClasses = 'd-inline-flex align-items-center px-4 mb-1';

export default function DownloadPhoto51() {
    useTitle('Download Photo51')

    return (
        <>
            <Fold className="d-block d-sm-none">
                <Fold.Main>
                    <h1>Download {concepts.Photo51}</h1>
                    <Alert variant="warning" className="mx-4 mt-3">
                        {concepts.Photo51} is a desktop software. Please log into this website on a
                        desktop to download {concepts.Photo51}.
                    </Alert>
                </Fold.Main>
            </Fold>
            <Fold className="d-none d-sm-block">
                <Fold.Main backgroundImage={downloadAppleWindows} className={styles.Fold}>
                    &nbsp;
                </Fold.Main>
                <Fold.Extension>
                    <h1 className="text-center pt-4 mt-5 pb-3">Download {concepts.Photo51}</h1>
                    <div className="text-center d-flex justify-content-center pb-5">
                        <span className="me-5">
                            <a
                                className={`btn btn-outline-secondary ${btnClasses}`}
                                href={links.mac}
                            >
                                <span className="text-xl position-relative" style={{ top: '-3px'}}>
                                    <Apple className="me-2" />
                                </span>
                                <span>
                                    Download for <b>MacOS</b>
                                </span>
                            </a>
                        </span>
                        <span>
                            <a className={`btn btn-outline-secondary ${btnClasses}`} href={links.win}>
                                <span className="text-xl position-relative" style={{ top: '-3px'}}>
                                    <Windows className="me-2" />
                                </span>
                                <span>Download for <b>Windows</b></span>
                            </a>
                        </span>
                    </div>
                    <div className="py-5" />
                </Fold.Extension>
            </Fold>
        </>
    );
}
