import React from 'react';
import {useParams} from "react-router";
import useGet from "../../components/hooks/useGet";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import {routes} from "../../utils";
import useAuth from "../../components/hooks/useAuth";
import {ArrowRight} from "react-bootstrap-icons";
import {concepts} from "../../libs/lang";
import Button from "react-bootstrap/Button";
import ShareableLinkPage from "./ShareableLinkPage";
import usePost from "../../components/hooks/usePost";


/**
 * In the platform, users can share links which allow anyone to join a group using a secure URL.
 */
export default function JoinGroupLink() {
    const { user } = useAuth();
    const { groupId, hash } = useParams();

    const { isSubmitting: isJoining, error: joinError, onSubmit } = usePost({
        url: backendEndpoints.inviteUserToGroup(groupId),
    });

    const { data: group, isLoading, error } = useGet({
        endpoint: groupId ? backendEndpoints.getGroupViaHash(groupId, hash) : '',
        params: { _with: ['users'] },
    });

    const thisPageUrl = routes.groupsJoinLink(groupId, hash);
    const alreadyInGroup = group?.users?.find(u => u.id === user?.id);

    return (
        <ShareableLinkPage
            tabTitle={`Join group ${group?.name}`}
            header={`Join the ${concepts.Group.toLowerCase()}`}
            description={(descProps) => <span>You have been invited by <b>{descProps.user?.first_name}</b> to join.</span>}
            currentPageUrl={thisPageUrl}
            isLoading={isLoading}
            error={error}
        >
            <h2>{group?.name}</h2>
            {alreadyInGroup ? (
                <>
                    <Alert variant="info">You are already part of this group.</Alert>
                    <a href={routes.group(groupId)} className="btn btn-primary">Go to group <ArrowRight /></a>
                </>
            ) : (
                <Button
                    size="lg"
                    onClick={() => {
                        onSubmit({ user: user.id }).then(() => {
                            window.location.href = routes.group(groupId);
                        });
                    }}
                    disabled={isJoining}
                >
                    Click here to join
                    {isJoining && <Loading size="sm" />}
                </Button>
            )}
            {joinError && <Alert variant="danger" className="mt-2">{joinError}</Alert>}
        </ShareableLinkPage>
    );
}
