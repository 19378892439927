const IS_DEV = process.env.NODE_ENV === 'development'
const isInternal = () => window.location.hostname.indexOf('internal') > -1
const isDev = () => IS_DEV;
const isProd = () => window.location.hostname === "fearlessleague.com";

export {
    isInternal,
    isDev,
    IS_DEV,
    isProd,
}
