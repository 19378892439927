import React, {useEffect} from 'react';
import Alert from "react-bootstrap/Alert";
import Pages from "../components/layout/Pages";
import {concepts} from "../libs/lang";
import Header from "../components/Header";
import {routes} from "../utils";
import Description from "../components/Description";
import StandardIcons from "../components/layout/StandardIcons";
import {externalLinks} from "../utils/routes";
import {useSearchParams} from "react-router-dom";


// Open Photo51 on desktop
export function openPhoto51DesktopApplication() {
    window.location.href = externalLinks.photo51desktop;
}


export const OpenPhoto51SearchFlags = {
    SHARE_REQUEST_ACCESS_LINK: 'share-request-access-link'
}


export default function OpenPhoto51() {
    useEffect(() => {
        openPhoto51DesktopApplication();
    }, []);

    const [searchParams] = useSearchParams();

    return (
        <Pages.Centred width="xs" className="text-center">
            <Header component="h1" className="gradient-color mb-5">Opening {concepts.Photo51}</Header>
            {searchParams.has(OpenPhoto51SearchFlags.SHARE_REQUEST_ACCESS_LINK) && (
                <Alert variant="success">Your files are in your {concepts.Photo51} share requests inbox.</Alert>
            )}
            <Description>
                If the application does not open by itself, please use the button below.
            </Description>

            <a href={externalLinks.photo51desktop} className="btn btn-primary btn-lg">
                Open {concepts.Photo51} desktop
            </a>
            <hr className="my-5" />
            <Description>
                Or open {concepts.Photo51} in cloud.
            </Description>
            <a href={routes.photo51Cloud} className="btn bg-light">
                <StandardIcons.Cloud width={20} />
                Open {concepts.Photo51} cloud
            </a>
        </Pages.Centred>
    );
}
