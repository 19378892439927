import React from 'react';
import {useParams} from "react-router-dom";
import {Link45deg} from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Button from "react-bootstrap/Button";
import useGet from "../../components/hooks/useGet";
import {DataRow, Pages} from "../../components/layout";
import {defaultDateTimeFormat} from "../../utils/date";
import {adminRoutes} from "../../utils/routes";
import {concepts} from "../../libs/lang";
import {backendEndpoints} from "../../libs/backendEndpoints";
import SubscriptionCard from "../../components/subscriptions/SubscriptionCard";
import getFullName from "../../utils/getFullName";

/**
 * Page listing subscriptions in the admin environment.
 */
export default function AdminSubscription() {
    const { subscriptionId } = useParams();
    const { data: subscription} = useGet({
        endpoint: backendEndpoints.adminSubscription(subscriptionId)+'?_with[]=group&_with[]=user'
    });

    const size = 5;

    return (
        <Pages.Centred>
            <Button href={adminRoutes.subscriptions}>Back to {concepts.Subscriptions}</Button>
            <h1>{concepts.Subscription}: {subscription?.name}</h1>
            <div className="d-flex gap-4 mb-3">
                <span>Created: {moment(subscription?.created_at).format(defaultDateTimeFormat)}</span>
                <span>Last updated: {moment(subscription?.updated_at).format(defaultDateTimeFormat)}</span>
            </div>
            {subscription && (
                <>
                    <Row>
                        <Col sm={5}>
                            <h5>Information</h5>
                            <DataRow labelSize={size} label="Subscription name">{subscription.name}</DataRow>
                            <DataRow labelSize={size} label="Academic / Commercial">{subscription.academic_or_commercial}</DataRow>
                            <DataRow labelSize={size} label={`User / ${concepts.Group}`}>{subscription?.group ? `${concepts.Group}` : `${concepts.User}`}</DataRow>
                            <DataRow labelSize={size} label="User">
                                {subscription.user
                                    ? <a href={adminRoutes.user(subscription.user?.id)}>{getFullName(subscription.user)} <Link45deg/></a>
                                    : '-'}
                            </DataRow>
                            <DataRow labelSize={size} label={concepts.Group}>
                                {subscription.group?.name
                                    ? <a href={adminRoutes.group(subscription.group?.id)}>{subscription.group?.name}</a>
                                    : '-'}
                            </DataRow>
                        </Col>
                        <Col>
                            <SubscriptionCard subscription={subscription} />
                        </Col>
                    </Row>
                </>
            )}
        </Pages.Centred>
    );
}

