import React from 'react';
import PropTypes from 'prop-types';
import {routes} from "../utils";
import {CloudArrowDownFill} from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import useAuth from "./hooks/useAuth";
import {concepts} from "../libs/lang";
import trackEvent, {CATEGORIES} from "./tracking/trackEvent";

const TryForFreeButtonPropTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOf([Nav.Link, Button, 'a']),
    componentProps: PropTypes.shape({}),
}

const TryForFreeButtonDefaultProps = {
    className: '',
    componentProps: {},
    component: Button,
}

export default function TryForFreeButton({ className, component: Component, componentProps, actionTag, children }) {
    const { user } = useAuth()
    const url = user ? routes.download : routes.signUp;
    const label = user ? 'Download '+concepts.Photo51 : 'Try for free';

    return (
        <Component
            {...componentProps}
            className={className}
            to={url}
            href={url}
            onClick={() => {
                if (actionTag) trackEvent(CATEGORIES.SIGN_UP_CTA, actionTag, label);
            }}
        >
            {children || (
                <>
                    <CloudArrowDownFill size={30} />
                    &nbsp;&nbsp;{label}
                </>
            )}
        </Component>
    );
}

TryForFreeButton.propTypes = TryForFreeButtonPropTypes;
TryForFreeButton.defaultProps = TryForFreeButtonDefaultProps;
