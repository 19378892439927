import React from 'react';
import photo51logo from "../assets/images/logos/photo51-red-desktop-icon.svg";
import Header from "./Header";
import {concepts} from "../libs/lang";
import TryForFreeButton from "./TryForFreeButton";
import Button from "react-bootstrap/Button";
import cx from 'classnames';
import blueMountainRange from "../assets/images/blue-mountain-range.jpg";


export default function StartWithPhoto51({ actionTag, variant = 'dark' }) {
    return (
        <section
            className={cx({
                "position-relative": true,
                "linear-gradient-film": variant === 'dark',
            })}
        >
            <div style={{ zIndex: 2 }} className={cx({
                "text-center mt-5 position-relative": true,
                "py-vh15": variant === 'dark',
                "py-vh5": variant === 'light',
            })}>
                <img src={photo51logo} height={120} alt={`${concepts.Photo51} logo`} />
                <Header
                    component="h1"
                    className={cx({
                        "mb-5 mt-3": true,
                        "text-white": variant === 'dark',
                    })}
                >
                    Start with&nbsp;
                    <span className="fw-bold">{concepts.Photo51}</span>
                    &nbsp;today
                </Header>
                <TryForFreeButton
                    component={Button}
                    className={cx({
                        "btn-lg text-xl mb-5": true,
                        "shadow": variant === 'light'
                    })}
                    componentProps={{variant: 'primary', size: 'lg'}}
                    actionTag={actionTag}
                />
            </div>
            {variant === 'dark' && <div style={{
                backgroundImage: `url(${blueMountainRange})`,
                backgroundAttachment: 'fixed',
                backgroundPosition: "center center",
                opacity: 0.1,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: '0'
            }}>&nbsp;</div>}
        </section>
    );
}
