import React from 'react';
import axios from "axios";
import {backendEndpoints} from "../../libs/backendEndpoints";
import {routes} from "../../utils";
import Button from "react-bootstrap/Button";
import {useConfirm} from "../contexts/ConfirmationModalContext";

export default function GroupDeleteButton({ group }) {
    const { open } = useConfirm();
    return (
        <Button
            variant="outline-danger"
            size="sm"
            onClick={() => {
                open(
                    `Are you sure you want to delete ${group?.name}?`,
                    () => axios.delete(backendEndpoints.deleteGroup(group.id))
                        .then(() => {
                            window.location.href = routes.groups;
                        }),
                    true,
                )
            }}
        >
            Delete {group?.name}
        </Button>
    );
}
