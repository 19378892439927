import React from 'react';
import useGet from "../../components/hooks/useGet";
import {useParams} from "react-router-dom";
import {BackendForm} from "../../components/forms";
import {Pages} from "../../components/layout";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import {defaultDateTimeFormat} from "../../utils/date";
import { FormGroup, FormLabel } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {adminRoutes} from "../../utils/routes";
import SubscriptionCard from "../../components/subscriptions/SubscriptionCard";
import {concepts, explanations} from "../../libs/lang";
import Loading from "../../components/Loading";
import GroupDeleteButton from "../../components/groups/GroupDeleteButton";
import GroupUserManagement from "../../components/groups/GroupUserManagement";
import GenerateGroupJoinLink from "../../components/groups/GenerateGroupJoinLink";

const labelClassName = 'col-5'

function AdminGroup() {
    const { groupId } = useParams();
    const { data: group, isLoading, reload } = useGet({
        endpoint: `/admin/groups/${groupId}?_with[]=users`
    })

    return (
        <Pages.Centred>
            <Button href={adminRoutes.groups}>Back to {concepts.Groups}</Button>
            <h1>Group: {group?.name}</h1>
            <div className="d-flex gap-4 mb-3">
                <span>Signed up: {moment(group?.created_at).format(defaultDateTimeFormat)}</span>
                <span>Last updated: {moment(group?.updated_at).format(defaultDateTimeFormat)}</span>
            </div>
            {isLoading && <Loading />}
            {group && (
                <>
                    <Row>
                        <Col>
                            <BackendForm
                                initialValues={group}
                                targetEndpoint={`/admin/groups/${groupId}`}
                                targetMethod="PUT"
                                onSuccess={(resp) => {

                                }}
                            >
                                <h5>Information</h5>
                                <FloatingLabelField name="name" label="Name"/>
                            </BackendForm>

                            <h5 className="px-0">Technical details</h5>
                            <FormGroup className="d-flex">
                                <FormLabel className={labelClassName}>Is subscribed</FormLabel>
                                {group?.is_subscribed ? 'Yes' : 'No'}
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <FormLabel className={labelClassName}>Used subscription seats</FormLabel>
                                {group?.used_seats}
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <FormLabel className={labelClassName}>Total subscription seats</FormLabel>
                                {group?.total_seats || 0}
                            </FormGroup>

                            {group?.subscriptions?.map((subscription) => (
                                <SubscriptionCard subscription={subscription} group={group} />
                            ))}

                            {group && (
                                <GroupUserManagement
                                    group={group}
                                    reload={reload}
                                    onSuccess={() => {
                                        reload();
                                    }}
                                />
                            )}
                        </Col>
                        <Col sm={4}>
                            <h5>{concepts.CustomerService}</h5>
                            <h6>Group join link to quickly add many members</h6>
                            <small className="line-height-1-2">
                                All groups have a join link, which users can find on the group page. This link allows
                                anyone with the link to join the group.
                            </small>
                            <small className="line-height-1-2 d-block mt-3">
                                <GenerateGroupJoinLink groupId={groupId} />
                            </small>

                            <h6 className="mt-5">Delete/Archive Group</h6>
                            <small className="line-height-1-2">{explanations.deleteGroup}</small>
                            <br />
                            <GroupDeleteButton group={group} />
                        </Col>
                    </Row>
                </>
            )}

        </Pages.Centred>
    );
}

export default AdminGroup;
