import React, {useState} from 'react';
import {adminRoutes} from "../../utils/routes";
import Pages from "../../components/layout/Pages";
import {formats} from "../../components/layout/Table";
import {concepts} from "../../libs/lang";
import OrderableTable from "../../components/layout/OrderableTable";
import {backendEndpoints} from "../../libs/backendEndpoints";
import SearchInput from "../../components/SearchInput";

const columns = [
    { id: 'id', accessor: 'id', header: 'ID' },
    { id: 'name', accessor: 'name', header: 'Name' },
    { id: 'created_at', accessor: 'created_at', header: 'Created at', Cell: formats.date },
    { id: 'deleted_at', accessor: 'deleted_at', header: 'Deleted at', Cell: formats.date },
    { id: 'subscription', accessor: 'is_subscribed', header: 'Subscribed', Cell: formats.bool },
    { id: 'users', accessor: 'users', header: `# of ${concepts.GroupMembers}`, Cell: formats.length },
]

export default function AdminGroups() {
    const [query, setQuery] = useState('');

    return (
        <Pages.Centred padded>
            <div className="d-flex align-items-center">
                <h1 className="flex-grow-1 mb-0">{concepts.Groups}</h1>
                <div className="ms-3">
                    <SearchInput onChange={(e) => setQuery(e.target.value)}/>
                </div>
            </div>
            <OrderableTable
                orderables={{
                    'name': 'name',
                    'created_at': 'created_at',
                }}
                baseEndpoint={backendEndpoints.getAdminGroups}
                memoisedColumns={columns}
                buildLink={(record) => adminRoutes.group(record.id)}
                pagination
                searchQuery={query}
            />
        </Pages.Centred>
    );
}

