import React, {useEffect, useRef, useState} from 'react';

export default function SearchInput({ onChange }) {
    const inputElement = useRef(null);
    const [localQuery, setLocalQuery] = useState('');

    useEffect(() => {
        if (inputElement.current) {
            setTimeout(() => {
                inputElement.current.focus();

            }, 100);
        }
    }, []);

    return (
        <input
            autoFocus
            ref={inputElement}
            autoComplete={false}
            autoCorrect={false}
            className="form-control"
            placeholder="Search..."
            value={localQuery}
            onChange={(e) => {
                setLocalQuery(e.target.value);
                onChange(e);
            }}
        />
    );
}
