import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Field, useField} from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import cx from "classnames";

const SelectPropTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}

const SelectDefaultProps = {
    placeholder: '',
    onChange: undefined,
    label: undefined,
}

export default function Select({ options, name, label, placeholder, onChange, required, disabled }) {
    const [{ value },, { setValue }] = useField(name);
    const field = (
        <Field
            aria-label={label}
            as="select"
            name={name}
            className="form-control"
            onChange={(e) => {
                e.persist();
                if (onChange) {
                    onChange(e)
                }
                setValue(e.target.value);
            }}
            disabled={disabled}
            value={value}
        >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((o) => (
                <option value={o.value} key={o.value}>{o.label}</option>
            ))}
        </Field>
    );
    return (
        <>
            {label !== undefined ? (
                <FloatingLabel controlId={label} label={(
                    <div className={cx({"required-field": required})}>
                        {label}
                    </div>
                )}>
                    {field}
                </FloatingLabel>
            ): field}

            <ErrorMessage name={name} className="text-danger px-1" component="span" />
        </>
    );
}

Select.propTypes = SelectPropTypes;
Select.defaultProps = SelectDefaultProps;
