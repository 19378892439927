import React, { useId } from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {InfoCircle} from "react-bootstrap-icons";
import styles from './InfoTooltip.module.scss';

const InfoTooltipPropTypes = {}

const InfoTooltipDefaultProps = {}

export default function InfoTooltip({ children }) {
    const id = useId();
    const renderTooltip = (props) => <Tooltip id={id} {...props}>{children}</Tooltip>;

    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            defaultShow={false}
        >
            <InfoCircle className={styles.InfoTooltip} />
        </OverlayTrigger>
    );
}

InfoTooltip.propTypes = InfoTooltipPropTypes;
InfoTooltip.defaultProps = InfoTooltipDefaultProps;
