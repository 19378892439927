/**
 *
 * @param {Error} e
 */
export function beautifyErrorMessage(e) {
    if (e.message === 'Network error') {
        return 'Our servers could not be reached. Please check your internet connection.'
    }
    return e.message;
}
