import React, {useState} from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Loading from "../Loading";
import Alert from "react-bootstrap/Alert";


export default function InviteEmailButton({ reload, onSuccess, groupId, email }) {
    const [isInviting, setInviting] = useState(false)
    const [error, setError] = useState(false)

    const inviteNotExistingUser = () => {
        setInviting(true)
        axios.post(backendEndpoints.inviteUserToGroup(groupId), { user: email })
            .then(({ data }) => {
                reload()
                onSuccess(data?.message)
            })
            .catch((e) => setError(e.response?.data?.message))
            .finally(() => setInviting(false))
    }

    return (
        <>
            <Button
                disabled={isInviting}
                onClick={inviteNotExistingUser}
            >
                Invite
                {isInviting && <Loading />}
            </Button>
            {error && <Alert className="alert-danger">{error}</Alert>}
        </>
    );
}
