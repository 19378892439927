import {concepts} from "./lang";

export const FAQ_CATEGORIES = {
    FILE_IMPORT_AND_FILE_ORGANISATION: 'File import and organization',
    SHARING_FILES: 'Sharing files',
    SUBSCRIPTIONS: 'Subscriptions'
}

export const FAQS = [
    {
        title: 'Can I import my current files?',
        description: 'Yes, the Photo51 “Import” function (in the library menu) allows you to import files of all popular formats () and even entire folder-file structures. Simply drag and drop files, a folder, or your entire sequence inventory onto the library menu desktop or click the “import” button. You’ll have the option to preserve your current folder structure upon import. Files will be stored on “My files” by default, but you have the option to save them in other groups as well during import.',
        tags: [FAQ_CATEGORIES.FILE_IMPORT_AND_FILE_ORGANISATION]
    }, {
        title: 'What are labels and how do they work? ',
        description: 'A label is a tag and you can create as many labels as you wish. It works similar to how Gmail allows you to organize your email. You can also create a label hierarchy. \n'
            + 'The advantage of tags is that the same file can be filed under different categories, for example “CRISPR-Cas vectors” and “Prokaryotic expression vectors”. This way you don’t have to keep multiple copies of a file. You can assign one label to multiple files. You can assign multiple labels to one file.',
        tags: [FAQ_CATEGORIES.FILE_IMPORT_AND_FILE_ORGANISATION]
    }, {
        title: 'Deleting files vs removing labels',
        description: 'Do keep in mind that when you delete a file under one label, it will be deleted everywhere else as well. If you want to remove a label but keep the file, you can do so via the “assign” button in the Library menu.',
        tags: [FAQ_CATEGORIES.FILE_IMPORT_AND_FILE_ORGANISATION]
    }, {
        title: 'How do I organize my files in Photo51?',
        description: 'Photo51 uses labels so you don’t have to keep multiple copies of a file. You can assign one label to multiple files. You can assign multiple labels to one file. \n'
            + 'When you import an existing folder structure, and if you select to keep this architecture, your folders become labels. \n'
            + 'For example, if you have folder called “GFP vectors”, this will become the label “GFP vectors” and all files in that folder will be imported and assigned to this label.',
        tags: [FAQ_CATEGORIES.FILE_IMPORT_AND_FILE_ORGANISATION]
    }, {
        title: 'How can I share files?',
        description: 'You can share files from within the Photo51 application. \n' +
            'You can share files with Photo51 users by searching for their name. Users with the Free View-Only plan will be able to see your files.\n' +
            'You can share files with non-users by entering their email address. When you share a file with a non-user, that person can sign up for the Free View-Only plan to see your file.\n',
        tags: [FAQ_CATEGORIES.SHARING_FILES]
    }, {
        title: 'Can I share a file with someone who does not have a subscription? ',
        description: 'Yes, you can share a file by simply typing the recipient’s email address, that person can view your file after signing up.',
        tags: [FAQ_CATEGORIES.SHARING_FILES]
    }, {
        title: 'Can I share a file with someone who does not have a Photo51 account? ',
        description: 'Yes. Within Photo51, you have the option to enter someone’s email address. Photo51 has a Free View-Only plan. When you share a file, that person can view your file after signing up.',
        tags: [FAQ_CATEGORIES.SHARING_FILES]
    }, {
        title: 'What is the view-only Plan?',
        description: 'Photo51 has a free view-only plan. After your free trial ends, you can still view, add, share and export files. Other Photo51 functions require a subscription',
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `What is a ${concepts.Group}?`,
        description: `Creating a ${concepts.Group.toLowerCase()} gives you the flexibility to work together with, for example, your lab, a project ${concepts.Group.toLowerCase()}, a student assignment group. You can create as many ${concepts.Groups.toLowerCase()} as you wish and customize the organization of respective inventories. \n`
           +  `A ${concepts.Group} has access to that respective inventory and each member can share their own files with the ${concepts.Group.toLowerCase()}. Every member with a subscription has access to all features of Photo51. ${concepts.Group} members without a subscription will have the free view-only plan.`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `Can I create a ${concepts.Group} without a subscription?`,
        description: `Yes, however, only members in your ${concepts.Group} who have a paid subscription will have access to all Photo51 features. \n`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `How do I join a ${concepts.Group}?`,
        description: `You can join a ${concepts.Group} when you receive an invitation from a ${concepts.Group}. When a ${concepts.Group} invites you, you’ll occupy one of their seats. \n`
            + `If you’ve been invited to join more than one ${concepts.Group.toLowerCase()}, you can decide which ${concepts.Group}’s seat you’ll occupy. If you have questions about whether or not to occupy a ${concepts.Group} seat within their subscription, please contact that ${concepts.Group}’s administrator.`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `I have an Individual subscription and would like to join a ${concepts.Group}. What happens to my subscription?`,
        description: `When a ${concepts.Group} invites you to occupy one of their seats, you can decide whether or not to take a ${concepts.Group}’s seat or keep your own subscription.`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `I have a subscription with a ${concepts.Group} and was invited to join another ${concepts.Group.toLowerCase()}. What happens to my subscription?`,
        description: `If you’ve been invited to join more than one ${concepts.Group.toLowerCase()}, you can decide which ${concepts.Group}’s seat you’ll occupy. If you have questions about whether or not to occupy a ${concepts.Group} seat within their subscription, please contact that ${concepts.Group}’s administrator.`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `Can I belong to more than one ${concepts.Group}?`,
        description: `Yes, you can belong to as many ${concepts.Groups} as you like. You can create your own ${concepts.Groups} as well.\n`
            + `You can create a ${concepts.Group} without a subscription. Only members in your ${concepts.Group} who have an existing subscription will have access to all Photo51 features. Those without a subscription will have the Free View-Only version.`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: 'What does the trial period include?',
        description: 'When you enroll in the trial period, you’ll have an Individuals subscription at the respective Academic or Corporate level. You’ll have access to all Photo51 features for a limited time.',
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: 'What happens if my subscription or free trial period expires?',
        description: 'If your subscription or free trial period expires before you’ve had chance to renew or upgrade, your account will become read-only. You can still view, add, share and export your files.',
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: 'When can I cancel?',
        description: 'You can cancel your subscription anytime, and your full access will convert to view-only at the end of the billing cycle within which you cancel. Say, you’ve chosen a monthly billing plan and are billed on the 1st of every month. If you cancel on June 5th, you’ll have access to all features until 30 June. ',
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `How are ${concepts.Group} Subscriptions charged?`,
        description: `A ${concepts.Group} Subscription includes a minimum of 2 seats and billing is done annually. The ${concepts.Group.toLowerCase()} administrator invites members.` +
            + `When you’re invited to join a ${concepts.Group.toLowerCase()} that has a ${concepts.Group} Subscription, you’ll occupy one of that ${concepts.Group.toLowerCase()}’s seats.` +
            + `If you have an existing subscription (an Individuals Subscription or are part of another ${concepts.Group} Subscription), you can decide whether to keep your current subscription and payment location, or whether to switch your subscription to the ${concepts.Group.toLowerCase()} that just invited you.`
            + 'No matter which paid subscription you have, you’ll have access to all Photo51 features.'
            + `If you have questions about whether or not to occupy a ${concepts.Group} seat within their subscription, please contact that ${concepts.Group}’s administrator.`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }, {
        title: `What’s the difference between an Individual Subscription and a ${concepts.Group} Subscription?`,
        description: 'Anyone can select and pay for an Individual Subscription. \n'
            + `A ${concepts.Group} Subscription is paid for by a group and has a minimum of 2 seats. Invited users who occupy a seat do not pay for the subscription themselves. \n`
            + `Invited users who have an existing subscription (an Individuals Subscription or are part of another ${concepts.Group} Subscription), can decide whether to keep their current subscription and payment location, or whether to switch their subscription to the ${concepts.Group.toLowerCase()} that just invited them. \n`
            + `In My Subscription, you can see the ${concepts.Groups} that you’ve joined and select where your subscription will be billed from. \n`
            + `If you have questions about whether or not to occupy a ${concepts.Group} seat within their subscription, please contact that ${concepts.Group}’s administrator.\n`,
        tags: [FAQ_CATEGORIES.SUBSCRIPTIONS]
    }
];
