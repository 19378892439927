import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "../forms/Select";
import {groupRole as groupRoleEnum} from "../../libs/backendEnums";
import {concepts} from "../../libs/lang";
import UserCard from "../layout/UserCard";
import ProfilePicture from "../ProfilePicture";

const UserRowPropTypes = {}

const UserRowDefaultProps = {}

export default function UserRow({ item: user, name, onChange, groupId, groupRole, groupIsSubscribed }) {
    const fieldName = `${name}.pivot.role`;

    return (
        <Row>
            <Col style={{ flex: '0 0 55px' }}>
                <ProfilePicture user={user} size={55} className="me-0" />
            </Col>
            <Col>
                <UserCard user={user} />
                {groupIsSubscribed && groupRole === groupRoleEnum.MANAGER.value && (
                    user?.subscribed_via_group?.id == groupId
                        ? <b className="text-success">On {concepts.Group} {concepts.Subscription}</b>
                        : <b className="text-success">Not on this {concepts.Group}'s {concepts.Subscription}</b>
                )}
            </Col>
            <Col>
                <Select
                    name={fieldName}
                    label="Role"
                    onChange={onChange}
                    options={Object.values(groupRoleEnum)}
                />
            </Col>
        </Row>
    )
}

UserRow.propTypes = UserRowPropTypes;
UserRow.defaultProps = UserRowDefaultProps;
