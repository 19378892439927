import React, {useEffect} from 'react';
import useGet from "../../components/hooks/useGet";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Loading from "../../components/Loading";
import Description from "../../components/Description";
import {groupRole} from "../../libs/backendEnums";
import axios from "axios";
import {concepts, explanations} from "../../libs/lang";
import {backendEndpoints} from "../../libs/backendEndpoints";
import {addStripeToHead, buildClientReferenceId, stripePriceTables} from "../../utils/stripe";
import SubscriptionCard from "../../components/subscriptions/SubscriptionCard";
import Header from "../../components/Header";
import PropTypes from "prop-types";
import {DataRow} from "../../components/layout";
import StripePricingTable, {PRICING_TABLE_PRODUCT} from "../../components/StripePricingTable";
import {routes} from "../../utils";
import {useConfirm} from "../../components/contexts/ConfirmationModalContext";
import useAuth from "../../components/hooks/useAuth";
import GroupDeleteButton from "../../components/groups/GroupDeleteButton";
import GroupUserManagement from "../../components/groups/GroupUserManagement";


function GroupNonEdit({ group }) {
    const { open } = useConfirm();
    const { user } = useAuth();

    const leaveGroup = () => {
        return axios.delete(backendEndpoints.leaveGroup(group.id), { data: { user_id: user?.id }})
            .then(() => {
                window.location.href = routes.groups;
            });
    }

    return (
        <>
            <DataRow label="Name">{group.name}</DataRow>
            <DataRow label="Members">
                {group.users.length} members.<br />
                <ul className="list-unstyled text-left">
                    {group.users.map(u => (
                        <li key={u.id}>
                            {u.first_name} {u.last_name}
                            - {groupRole[u.pivot?.role || groupRole.MEMBER]?.label}
                        </li>
                    ))}
                </ul>
            </DataRow>
            <h5>Leave group</h5>
            <p>If you leave the group, you will lose access to its data and its subscription.</p>
            <Button
                onClick={() => {
                    open('Are you sure you want to leave this group?', leaveGroup, true);
                }}
                variant="outline-danger"
            >
                Leave group
            </Button>
        </>
    );
}

const GroupsPropTypes = {
    groupId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func,
}
const GroupsDefaultProps = {
    onSuccess: undefined,
}

export default function GroupEdit({ groupId, onSuccess: propOnSuccess }) {
    const { data: initGroup, isLoading, error, reload } = useGet({ endpoint: backendEndpoints.getGroup(groupId), params: {
        _with: ['users', 'subscriptions']
    } })

    useEffect(() => {
        if (initGroup) addStripeToHead()
    }, [initGroup]);

    if (typeof error === 'string') return <Alert>{error}</Alert>;

    if (isLoading && !initGroup) return <Loading />

    if (!initGroup?.role || initGroup?.role !== groupRole.MANAGER.value) {
        return <GroupNonEdit group={initGroup} />;
    }

    return (
        <>
            {(!initGroup || isLoading) && !error && <Loading />}
            {initGroup && !isLoading && (
                <GroupUserManagement
                    group={initGroup}
                    reload={reload}
                    onSuccess={() => {
                        reload();
                        if (propOnSuccess) propOnSuccess()
                    }}
                />
            )}
            <Header component="h4" className="mt-5">{concepts.Group} {concepts.Subscription}.</Header>
            <Description>
                A {concepts.Group} {concepts.Subscription} enables all members to use {concepts.Photo51} both online and offline.
            </Description>
            {initGroup && !initGroup.is_subscribed && (
                <StripePricingTable
                    tableId={stripePriceTables.GROUP_ANNUAL}
                    clientReferenceId={buildClientReferenceId('group', groupId)}
                    groupId={groupId}
                    postPrices={[PRICING_TABLE_PRODUCT.INSTITUTE]}
                />
            )}
            {Array.isArray(initGroup?.subscriptions) && initGroup?.subscriptions.map(sub => (
                <SubscriptionCard key={sub.id} subscription={sub} group={initGroup} />
            ))}

            {initGroup?.id && (
                <>
                    <h6 className="mt-5">Delete {concepts.Group}</h6>
                    <p>{explanations.deleteGroup}</p>
                    <GroupDeleteButton group={initGroup} />
                </>
            )}
        </>
    );
}

GroupEdit.propTypes = GroupsPropTypes;
GroupEdit.defaultProps = GroupsDefaultProps;
