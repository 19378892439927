import React from 'react';
import useTitle from "../../components/hooks/useTitle";
import {Pages} from "../../components/layout";
import Description from "../../components/Description";

export default function InvalidLink() {
    useTitle('Invalid link');

    return (
        <Pages.Centred padded width="xs">
            <h1 className="gradient-color">Invalid link</h1>
            <Description>

            </Description>
        </Pages.Centred>
    )
}
