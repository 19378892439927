import React from 'react';
import moment from "moment";
import { FormLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useGet from "../../components/hooks/useGet";
import {useParams} from "react-router-dom";
import {BackendForm} from "../../components/forms";
import {Pages} from "../../components/layout";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import {defaultDateTimeFormat} from "../../utils/date";
import {adminRoutes} from "../../utils/routes";
import SubscriptionCard from "../../components/subscriptions/SubscriptionCard";
import usePost from "../../components/hooks/usePost";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Alert from "react-bootstrap/Alert";
import Loading from "../../components/Loading";
import {concepts} from "../../libs/lang";
import UserCustomFields from "../../components/forms/UserCustomFields";

const labelClassName = ''

function AdminUser() {
    const { userId } = useParams();
    const { data: user, error, isLoading} = useGet({
        endpoint: `/admin/users/${userId}?_with[]=subscriptions&_with[]=custom_fields`
    })
    const { onSubmit, isSubmitting, error: resetError } = usePost({
        url: backendEndpoints.forgotPassword,
    })

    return (
        <Pages.Centred>
            <Button href={adminRoutes.users}>Back to users</Button>
            <h1>User: {user?.first_name} {user?.last_name}</h1>
            <div className="d-flex gap-4 mb-3">
                <span>Signed up: {moment(user?.created_at).format(defaultDateTimeFormat)}</span>
                <span>Last updated: {moment(user?.updated_at).format(defaultDateTimeFormat)}</span>
            </div>
            {error && <Alert>{error}</Alert>}
            {isLoading && <Loading />}
            {user && (
                <>
                    <Row>
                        <Col>
                            <BackendForm
                                initialValues={user}
                                targetEndpoint={`/admin/users/${userId}?_with[]=custom_fields`}
                                targetMethod="PUT"
                                onSuccess={(resp) => {

                                }}
                            >
                                <h5>Personal information</h5>
                                <FloatingLabelField name="first_name" label="First name"/>
                                <FloatingLabelField name="last_name" label="Last name"/>
                                <FloatingLabelField name="username" label="Username"/>
                                <FloatingLabelField
                                    name="email"
                                    label="Email"
                                    helperText={(
                                        <>
                                            Email verified
                                            at: {user.email_verified_at ? moment(user.email_verified_at).format(defaultDateTimeFormat) : 'Not yet verified'}
                                        </>
                                    )}
                                />
                                <FloatingLabelField
                                    name="trial_ends_at"
                                    label="Sign up trial period ends at"
                                    type="date"
                                />

                                <h5 className="mt-4">Custom fields</h5>
                                <UserCustomFields />
                            </BackendForm>
                        </Col>
                        <Col>
                            <h5>Technical account details</h5>

                            <table className="table table-sm">
                                <tbody>
                                <tr>
                                    <td width="col-9">
                                        <FormLabel className={labelClassName}>Enabled two-factor auth.</FormLabel>
                                    </td>
                                    <td>{user?.has_2fa_enabled ? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormLabel className={labelClassName}>Is subscribed</FormLabel>
                                    </td>
                                    <td>{user?.is_subscribed ? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormLabel className={`${labelClassName} ps-3`}>Via rule</FormLabel>
                                    </td>
                                    <td>{user?.subscribed_via_rule ? <a href={adminRoutes.subscriptionRule(user?.subscribed_via_rule)}>Yes</a> : 'No'}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormLabel className={`${labelClassName} ps-3`}>Individually</FormLabel>
                                    </td>
                                    <td>
                                        {user?.is_subscribed && !user?.subscribed_via_rule && !user?.subscribed_via_group
                                            ? 'Yes'
                                            : 'No'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormLabel className={`${labelClassName} ps-3`}>Via group</FormLabel>
                                    </td>
                                    <td>
                                        {user?.subscribed_via_group
                                            ?
                                            <a href={adminRoutes.group(user?.subscribed_via_group.id)}>{user?.subscribed_via_group.name}</a>
                                            : 'No'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormLabel className={labelClassName}>Is on trial</FormLabel>
                                    </td>
                                    <td>{user?.on_trial ? 'Yes' : 'No'}</td>
                                </tr>

                                <tr>
                                    <td>
                                        <FormLabel className={labelClassName}>Last version used</FormLabel>
                                    </td>
                                    <td>{user?.last_version_used || '-'}</td>
                                </tr>
                                </tbody>
                            </table>

                            <h5 className="mt-5">{concepts.Subscriptions}</h5>
                            {user.subscriptions?.length > 0 ? user.subscriptions?.map((subscription) => (
                                <SubscriptionCard key={subscription.id} subscription={{ ...subscription, user }} />
                            )) : (
                                <Alert>
                                    No individual {concepts.Subscriptions.toLowerCase()}
                                    {user?.subscribed_via_group && ', but is subscibed via group'}
                                </Alert>
                            )}
                        </Col>
                        <Col>
                            <h5>{concepts.CustomerService}</h5>

                            <h6>Reset password link</h6>
                            <p className="small line-height-1-2">
                                For security reasons, we cannot share the password link directly with
                                FL employees.
                            </p>
                            <Button
                                variant="outline-primary"
                                onClick={() => onSubmit({email: user.email })}
                                size="sm"
                                className="mb-2"
                                disabled={isSubmitting}
                            >
                                {isSubmitting && <Loading size="sm" />}
                                Send reset password link to {user.email}
                            </Button>
                            {resetError && <Alert>{resetError}</Alert>}
                        </Col>
                    </Row>
                </>
            )}

        </Pages.Centred>
    );
}

export default AdminUser;
