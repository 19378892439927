import React from 'react';

const TotalRowPropTypes = {}

const TotalRowDefaultProps = {}

export default function TotalRow({ label, children }) {
    return (
        <div className="d-flex flex-row align-items-center my-1 gap-3 py-1">
            <b>{label}</b>
            <div className="d-inline-flex align-items-center me-2">
                {children}
            </div>
        </div>
    );
}

TotalRow.propTypes = TotalRowPropTypes;
TotalRow.defaultProps = TotalRowDefaultProps;
