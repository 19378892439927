import React from 'react';
import {concepts} from "./lang";

export const listedFeatures = {
    'Molecular techniques': [
        'Gateway Cloning',
        'Gibson Assembly',
        'In-Fusion Cloning',
        'HiFi Assembly',
        'Restriction enzyme cloning',
        'TA and TOPO Cloning',
        'PCR primer design'
    ],
    'Restriction enzyme sets': [
        'Predefined commercially available enzyme sets ',
        'Create custom enzyme sets',
        'View detailed enzyme information',
        'Filter by number of cuts or enzyme type\n'
    ],
    'Advanced file management': [
        'Intuitive file inventory with sequence map preview',
        'Create and edit label structures',
        'Comprehensive file history and recovery of older versions',
        'Sharing of files or file collections with your team ',
        'Sharing files or file collections with external colleagues via Photo51 or email',
        '30-day recovery mode for deleted files',
        'Import files from commonly used formats dna, .gb, .gbk, .fasta, .fa, .genious and .sbol',
        'Export files'
    ],
    'Teams': [
        'Create unlimited teams/subgroups',
        'Share file collections within teams',
        'Comprehensive file history and recovery of older versions'
    ],
    'Features': [
        'Automatic annotation of common sequence features',
        'Manually Create, annotate and edit features',
        'Automatically detect open reading frames (ORFs)',
        'Amino acid translations of features and ORFs',
        'Automatic readingframe translation for gene fusions'
    ],
    'Alignment': [
        'Align DNA sequences with a reference sequence',
        'Verify cloning or mutagenesis',
        'Pairwise and multi sequence DNA and Protein alignment',
        'Choice of alignment algorithms ',
        'Contig Assembly',
        'Store sequence alignment and validation information with plasmid'
    ],
    'Visualizing': [
        'Multiple viewing modes, plasmid diagram, sequence view, list views',
        'Visual maps of cloning history',
        'Split screen mode ',
        'Edit sequences',
        'Color code sequences',
        'Circularize/ linearize sequences',
        'Comprehensive DNA and protein sequence search'
    ],
    'History Tracking': [
        'Visual maps of cloning history ',
        'Comprehensive file history and recovery of older versions',
        '30-day recovery mode for deleted files',
        '“Undo” and ‘Redo” functions'
    ],
    'General': [
        'Unlimited installations',
        'Cross platform compatibility - Windows, macOS and cloud',
        'Synchronization between desktop and cloud'
    ],
}

export const essentialFeatures = [
    { title: 'CRISPR-Cas tools', description: 'Design guide RNAs for your gene editing projects with preset commonly used systems. Apply base editor systems (CBE and ABE) and automatically visualize PAM sequence, nuclease cut sites and base editing windows.' },
    { title: `Create ${concepts.Groups.toLowerCase()} & work together`, description: <>Create <b className="text-dark">unlimited</b> teams or subgroups. Create joint sequence collections or work on cloning projects together.</> },
    { title: 'Multi-sequence alignments', description: 'Align to a reference sequence pairwise or multi-sequence using MAFFT, Muscle, and more.' },
    { title: 'Unlimited installations & Cloud', description: 'Like your research, your software shouldn’t have any boundaries. You can install on as many computers as you want and access your data anytime from the cloud.' },
    { title: 'Cloning', description: 'Golden gate, TA, Restriction, Gibson and more.' },
    { title: 'History & versioning', description: <>Visual maps of cloning history, file history recovery, 30-day recovery mode for deleted files and Undo/Redo functions.</> },
];
