import { useEffect, useState } from 'react';
import Cookies from "../../libs/cookies";

const getKey = (preference) => (typeof preference === 'string' ? preference : preference?.key);

/**
 * @param {string|undefined|{key: string, reminderDays: int}} propsPreference
 * @return {{preferenceValue: string, setPreferenceValue: Function }}
 */
export default function usePreference(propsPreference) {
  const [preference, setPreferenceState] = useState(propsPreference);
  const disabledPreference = typeof propsPreference === 'undefined' || !propsPreference;
  const hasReminder = typeof preference === 'object' && preference.reminderDays;

  const getValue = () => {
    const key = getKey(preference);
    // When the user should be reminded every X days, use cookies
    if (hasReminder) {
      // Encode objects in JSON
      const value = Cookies.get(key) || preference.defaultValue
      try {
        return JSON.parse(value);
      } catch (e) {
        return value
      }
    } else {
      // If the setting is fixed indeterminately, use local storage
      const value = localStorage.getItem(key);

      // If the value was not actively disabled, show if by the default
      if (value === null && typeof preference === 'object') {
        return preference.defaultValue;
      } else {
        return value;
      }
    }
  };

  const [preferenceValue, setValue] = useState(getValue());

  useEffect(() => {
    if (!disabledPreference) setPreferenceState(propsPreference);
  }, [propsPreference]);


  useEffect(() => {
    if (!disabledPreference) {
      setValue(getValue());
    }
  }, []);

  const setPreferenceValue = (value) => {
    if (!disabledPreference) {
      const key = getKey(preference);
      if (hasReminder) {
        // When the preference has should not last indeterminately, cookies are used
        if (!value) {
          Cookies.remove(key);
        } else {
          const encodedValue = typeof value === 'object' ? JSON.stringify(value) : value;
          Cookies.set(key, encodedValue, preference.reminderDays);
        }
      } else {
        // If the setting is fixed indeterminately, use local storage
        if (value === '' || value === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, value);
        }
      }
      setValue(value);
    }
  };

  return { setPreferenceValue, preferenceValue };
}
