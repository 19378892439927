import React, {useState} from 'react';
import {Form} from "react-bootstrap";

export default function useDownSell(mainPrice) {
    const [showDownSell, setShowDownSell] = useState(false);
    const hasDownSell = Boolean(mainPrice?.downsell);
    const price = hasDownSell && showDownSell ? mainPrice?.downsell : mainPrice;

    return {
        hasDownSell,
        price,
        setShowDownSell,
        showingDownSell: showDownSell,
        switchComponent: hasDownSell ? (
            <Form.Switch // prettier-ignore
                type="switch"
                id={`custom-switch-${mainPrice.id}`}
                className="text-sm mb-0 d-inline-block"
                label={showDownSell ? 'Billed monthly' : 'Billed annually'}
                checked={!showDownSell}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={(e) => {
                    setShowDownSell(!e.target.checked);
                }}
            />
        ) : null,
    };
}
