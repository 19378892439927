import React, {useMemo, useState} from 'react';
import {Modal} from "react-bootstrap";
import {concepts} from "../../libs/lang";
import Button from "react-bootstrap/Button";
import {backendEndpoints} from "../../libs/backendEndpoints";
import {post} from "axios";
import Alert from "react-bootstrap/Alert";
import Loading from "../Loading";
import CopyText from "../layout/CopyLink";
import {routes} from "../../utils";

const id = 'team-names-input';

const parseLinesAsTeamNames = (value) => {
    if (typeof value === 'string') {
        return value.split('\n').map((name) => name.trim()).filter((v) => v);
    }
    return []
}

function CreateManyGroupsModals({ onClose }) {
    const [groups, setGroups] = useState();
    const [value, setValue] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const teamNames = useMemo(() => parseLinesAsTeamNames(value), [value]);

    const teamsText = useMemo(() => {
        let text = `Hi everyone, \n\n I've created many ${concepts.Groups.toLowerCase()} for ${concepts.Photo51}. \nYou can join any team by using the team links below:\n\n`
        groups?.forEach(({ name, join_url }) => {
            text += `To join ${name}, please use:\n${join_url}\n\n`;
        });
        return text;
    }, [groups]);

    const createManyTeams = () => {
        setSubmitting(true)
        post(backendEndpoints.createGroup, { name: teamNames })
            .then((response) => {
                setGroups(response.data.data);
            })
            .catch((e) => {
                const data = e.response.data;
                const { message } = data;

                setError(message);
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    return (
        <Modal
            onHide={() => {
                // Make sure the list of groups is refreshed
                if (groups) window.location.href = routes.groups;
                onClose();
            }}
            show
        >
            <Modal.Header closeButton>
                <Modal.Title>Create many {concepts.Groups}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!groups && (
                    <>
                        <label htmlFor={id}>Enter a new team name on each line</label>
                        <textarea
                            className="form-control"
                            name="lnames"
                            id={id}
                            autoFocus
                            rows={10}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </>
                )}
                {Array.isArray(groups) && groups.length > 0 && (
                    <>
                        <h6>Users add themselves</h6>
                        <p>
                            To get this many team set up conveniently, please share the following message with everyone:
                            <CopyText text={teamsText} multiline />
                        </p>
                        <h6>Or add users manually</h6>
                        <p>You can add users manually on each group page.</p>
                        <Button href={routes.group(groups[0].id)}>Close &amp; go to groups</Button>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {error && <Alert>{error}</Alert>}
                {!groups && (
                    <Button
                        onClick={() => {
                            createManyTeams()
                        }}
                        disabled={teamNames.length === 0}
                    >
                        Create {teamNames.length || ''} {concepts.Groups}
                        {submitting && <Loading size="sm" />}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default CreateManyGroupsModals;
