import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import cx from 'classnames';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { routes } from "../../utils";
import styles from './Layout.module.scss'
import useAuth from "../hooks/useAuth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useLocation} from "react-router-dom";
import {concepts} from "../../libs/lang";
import TryForFreeButton from "../TryForFreeButton";
import StandardIcons from "./StandardIcons";
import ProfilePicture from "../ProfilePicture";
import logoSrc from './../../assets/images/fearless-league-logo-h100.png';
import {adminRoutes, externalLinks} from "../../utils/routes";
import trackEvent, {ACTIONS, CATEGORIES} from "../tracking/trackEvent";
import GetInTouch from "../GetInTouch";
import isAdmin from "../../utils/isAdmin";

const links = [
    { href: routes.photo51, label: concepts.Photo51 },
    { href: routes.plans, label: concepts.Plans },
    { href: routes.faq, label: concepts.FAQ }
]

const adminLinks = [
    { href: adminRoutes.insights, label: 'Insights' },
    { href: adminRoutes.institutes, label: 'Institutes' },
    { href: adminRoutes.users, label: concepts.Users },
    { href: adminRoutes.groups, label: concepts.Groups },
    { href: adminRoutes.subscriptions, label: concepts.Subscriptions },
    { href: adminRoutes.subscriptionRules, label: concepts.SubscriptionRule },
]
const productLinks = [
    { href: routes.photo51, label: concepts.Photo51 },
    { href: routes.plans, label: concepts.Plans },
];
const resourceLinks = [
    { href: routes.faq, label: concepts.FAQ }
];
const companyLinks = [
    { href: routes.aboutUs, label: 'About us' },
    { href: externalLinks.privacyPolicy, label: 'Privacy policy' },
    { href: externalLinks.cookiePolicy, label: 'Cookie Policy' },
    { href: externalLinks.termsOfService, label: 'Terms of Service' },
    { href: externalLinks.eula, label: 'EULA' },
]

const mobileLinkPadding = 'py-3 py-sm-auto';
const adminMobileLinkPadding = 'py-2 py-sm-auto mb-0';

/**
 * Render top menu navigation link
 */
const LinkRender = ({ href, label, admin }) => (
    <Nav.Link
        as="a"
        href={href}
        className={`${styles.MainNavLink} text-center ${admin ? adminMobileLinkPadding : mobileLinkPadding}`}
    >
        {label}
    </Nav.Link>
);

function threshold(location) {
    if ([routes.photo51].indexOf(location.pathname) > -1) return 700
    return 200
}

function FooterLinks({ links }) {
    return (
        <ul className="mb-5">
            {links.map(({ href, label }) => (
                <li key={href}>
                    <a
                        href={href} target={href.indexOf('https://') > -1 ? '_blank': '_self'}
                        onClick={() => trackEvent(CATEGORIES.CLICK, ACTIONS.FOOTER_LINK, label)}
                    >
                        {label}
                    </a>
                </li>
            ))}
        </ul>
    );
}

const navBarId = 'basic-navbar-nav';

export default function Layout({ children }) {
    const { user } = useAuth()
    const [expanded, setExpanded] = useState(false)
    const [userScrolledPastFold, setUserScrolledPastFold] = useState(false)
    const location = useLocation();

    useEffect(() => {
        const onScroll = e => {
            const newValue = e.target.documentElement.scrollTop > threshold(location);
            if (userScrolledPastFold != newValue) {
                setUserScrolledPastFold(newValue);
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [userScrolledPastFold, location])

    return (
        <>
            <Navbar
                expand="lg"
                className={cx({
                    [styles.Navbar]: true,
                    [styles.Expanded]: expanded,
                    [styles.Regular]: true,
                })}
                fixed="top"
                onToggle={setExpanded}
                expanded={expanded}
                collapseOnSelect
            >
                <Container>
                    <a href={user ? routes.landing : routes.home} className="text-center">
                        <img
                            src={logoSrc}
                            alt="Fearless League logo"
                            height={26}
                            className={`${styles.Logo} me-3 d-inline-block`}
                        />
                    </a>
                    <Navbar.Toggle
                        aria-controls={navBarId}
                        className={styles.NavbarTogglerIcon}
                    />
                    <Navbar.Collapse id={navBarId}>
                        <Nav className="me-auto">
                            {links.map((link) => <LinkRender key={link.href} {...link} />)}
                        </Nav>
                        <Nav className="align-items-center">
                            {!user ? (
                                <TryForFreeButton
                                    component="a"
                                    className="btn btn-lg btn-secondary ps-3"
                                    actionTag={ACTIONS.MAIN_NAVIGATION_TRY_FOR_FREE}
                                />
                            ) : (
                                <>
                                    {isAdmin(user) && (
                                        <NavDropdown
                                            title="Admin"
                                        >
                                            {adminLinks.map((link) => (
                                                <NavDropdown.Item as="a" key={link.href} href={link.href}>
                                                    {link.label}
                                                </NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    )}
                                    <Nav.Link as={Link} to={routes.download}>Download</Nav.Link>
                                    <NavDropdown
                                        title={(
                                            <>
                                                <div className="d-inline-flex align-items-center">
                                                    <ProfilePicture user={user} />
                                                    <span>
                                                        <div>{user.first_name || user.last_name || user.email}</div>
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                        className="LoginDropdown"
                                    >
                                        <NavDropdown.Item as="a" href={routes.groups}>
                                            {concepts.Groups}
                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />

                                        <NavDropdown.Item as="a" target="_blank" href={routes.photo51Cloud}>
                                            {concepts.Photo51} Cloud
                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />

                                        <NavDropdown.Item as="a" href={routes.download}>
                                            Download
                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />

                                        <NavDropdown.Item as="a" href={routes.personalInformation}>
                                            {concepts.Account}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as="a" href={routes.security}>
                                            {concepts.Security}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as="a" href={routes.subscriptions}>
                                            {concepts.MySubscription}
                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />

                                        <NavDropdown.Item as={Link} to={routes.logout} className="text-danger">
                                            <StandardIcons.Logout />
                                            &nbsp;
                                            Log out
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </>
                            )}
                            {!user && <Nav.Link as="a" href={routes.login} className={mobileLinkPadding}>Log in</Nav.Link>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                {expanded && (
                    <div className={`d-block d-sm-none animated fadeIn ${styles.Shade}`} onClick={() => setExpanded(false)}>
                        &nbsp;
                    </div>
                )}
            </Navbar>
            <main className={styles.Main}>
                {children}
            </main>
            <footer>
                <Container className={`py-5 ${styles.Footer} mt-5`}>
                    <Row className="mb-5">
                        <Col xs={{ order: 1, span: 6 }} md={{ order: 1, span: true }} className="line-height-2">
                            <strong>Fearless League</strong>
                            <p className="line-height-2 mb-5 mb-md-0">
                                BioPartner, Room 3036 <br />
                                Galileiweg 8 <br />
                                2333 BD&nbsp;Leiden <br />
                                The Netherlands
                            </p>
                        </Col>
                        <Col xs={{ order: 3, span: 6 }} md={{ order: 2, span: true }}>
                            <strong className="text-secondary mb-2">Product</strong>
                            <FooterLinks links={productLinks} />
                        </Col>
                        <Col xs={{ order: 4, span: 6 }} md={{ order: 3, span: true }}>
                            <strong className="text-secondary mb-2">Resources</strong>
                            <FooterLinks links={resourceLinks} />
                        </Col>
                        <Col xs={{ order: 5, span: 6 }} md={{ order: 4, span: true }}>
                            <strong className="text-secondary mb-2">{concepts.CompanyName}</strong>
                            <FooterLinks links={companyLinks} />
                        </Col>
                        <Col xs={{ order: 1, span: 6 }} md={{ order: 5, span: true }}>
                            <p>Are you interested in our solutions or want to set up a collaboration?</p>
                            <GetInTouch />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="gray-600 text-sm">
                            Copyright &copy; Fearless League {new Date().getFullYear()}.
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}
