import React, {useState} from 'react';
import {Alert, Modal} from "react-bootstrap";
import {useField} from "formik";
import {BackendForm, Select} from "../forms";
import {backendEndpoints} from "../../libs/backendEndpoints";
import * as yup from "yup";
import Description from "../Description";
import AmountSpan from "./AmountSpan";
import UnitSpan from "./UnitSpan";
import {DashLg, PlusLg} from "react-bootstrap-icons";
import useGet from "../hooks/useGet";
import Loading from "../Loading";
import TotalRow from "./TotalRow";
import {concepts} from "../../libs/lang";
import GroupCreate from "../../pages/partials/GroupCreate";
import OptionButton from "./OptionButton";

function Totals({ selectedPrice }) {
    const [error, setError] = useState('');
    const [{ value: qtyString },, { setValue }] = useField('quantity');

    const qty = parseInt(qtyString);
    const decrease = () => {
        if (qty <= 2) {
            setError('A team requires at least 2 seats')
        } else {
            if (error) setError('');
            setValue(Math.max(qty - 1, 2));
        }
    }
    const increase = () => {
        setValue(qty + 1);
        if (error) setError('');
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center gap-4 fw-bold text-xl py-2">
                <button type="button" className="border-0 rounded-circle bg-primary" onClick={decrease}>
                    <DashLg className="m-2" />
                </button>
                <div className="d-flex align-items-center gap-2">
                    <AmountSpan price={{ unit_amount: qty * 100 }} decimals={0} />
                    <b>seats</b>
                </div>
                <button type="button" className="border-0 rounded-circle bg-primary" onClick={increase}>
                    <PlusLg className="m-2" />
                </button>
            </div>
            {error && <span className="text-danger">{error}</span>}
            <OptionButton selected>
                <span>&nbsp;</span>
                <span>Annual billing</span>
                <div className="d-inline-flex align-items-center me-2 pb-2">
                    <AmountSpan small price={selectedPrice} showMonthly />
                    {selectedPrice.recurring && <UnitSpan price={selectedPrice} showMonthly showInterval />}
                </div>
                <span>&nbsp;</span>
            </OptionButton>
            {Number.isInteger(qty) && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                    <TotalRow label="Total">
                        <AmountSpan price={{ currency: selectedPrice.currency, unit_amount: qty * selectedPrice.unit_amount }} />
                        {selectedPrice.recurring && <UnitSpan price={selectedPrice} showPerUser={false} showInterval />}
                    </TotalRow>
                    <Description className="mt-3">VAT will be added based on billing address</Description>
                </div>
            )}
        </div>
    );
}

const GroupSelectorWrapper = ({ children, className }) => (
    <div className={`mb-4 border border-primary border-2 rounded-2 ${className}`}>{children}</div>
);

const SeatSelectorModalPropTypes = {}

const SeatSelectorModalDefaultProps = {}

export default function SeatSelectorModal({ onCancel, selectedPrice, initGroupId }) {
    const [groupId, setGroupId] = useState(initGroupId);
    const { data: groups, isLoading, error, reload } = useGet({
        endpoint: backendEndpoints.getGroups,
        onSuccess: (groupsSuccess) => {
            if (!groupId && Array.isArray(groupsSuccess) && groupsSuccess.length === 1) {
                setGroupId(`${groupsSuccess[0].id}`);
            }
        }
    })

    return (
        <Modal show onHide={onCancel} size="md">
            <Modal.Header closeButton>
                <Modal.Title className="text-center flex-grow-1">{selectedPrice.product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading />}
                {error && <Alert>{error}</Alert>}
                {Array.isArray(groups) && groups.length > 0 ? (
                    <BackendForm
                        targetEndpoint={backendEndpoints.createAnnualGroupSubscription(groupId)}
                        targetMethod="POST"
                        initialValues={{
                            quantity: 2,
                            price_id: selectedPrice.id,
                            group_id: initGroupId ? `${initGroupId}` : '',
                        }}
                        validationSchema={yup.object({
                            group_id: yup.string().required('Please select a group'),
                        })}
                        showFooter={false}
                        onSuccess={(resp) => {
                            if (resp?.data?.checkout_url) {
                                window.location.href = resp.data.checkout_url;
                            }
                        }}
                    >
                        <h6>Add seats to:</h6>
                        <GroupSelectorWrapper>
                            <Select
                                name="group_id"
                                onChange={(e) => setGroupId(e.target.value)}
                                options={groups.map((g) => ({ value: `${g.id}`, label: g.name, disabled: g.subscriptions?.length > 0 }))}
                                placeholder={`-- Select a ${concepts.Group} --`}
                                value={groupId}
                            />
                        </GroupSelectorWrapper>
                        <h6>Select number of seats:<br />(min. 2)</h6>
                        <Totals selectedPrice={selectedPrice} />

                        <BackendForm.Footer buttonText="Next: Enter billing address" center />
                    </BackendForm>
                ) : (
                    <GroupSelectorWrapper className="p-2">
                        <p>To have a {concepts.Group} {concepts.Subscription}, you must first create a group:</p>
                        <GroupCreate onSuccess={reload} />
                    </GroupSelectorWrapper>
                )}
            </Modal.Body>
        </Modal>
    );
}

SeatSelectorModal.propTypes = SeatSelectorModalPropTypes;
SeatSelectorModal.defaultProps = SeatSelectorModalDefaultProps;
