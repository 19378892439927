import React, {useState} from 'react';
import {useField} from "formik";
import {DashLg, PlusLg} from "react-bootstrap-icons";
import _ from 'lodash';
import {customFieldsKeys} from "../../../components/forms/UserCustomFields";
import Alert from "react-bootstrap/Alert";

const name = 'signup_custom_fields';
const customFieldValues = Object.values(customFieldsKeys);

/**
 * For a email domain based subscription rule, the system allows for adding
 * custom input fields to the sign-up page. This component is used in the
 * admin editing page, to customize those custom fields.
 */
export default function SubscriptionRuleCustomFieldsBuilder() {
    const [field, , fieldHelpers] = useField(name);
    const [selectValue, setSelectValue] = useState('');
    const [labelValue, setLabelValue] = useState('');
    const [error, setError] = useState('');

    const items = typeof field.value === 'object' && field.value ? field.value: {};
    const availableOptions = customFieldValues.filter(v => !items.hasOwnProperty(v));
    const hasNoOptionsLeft = availableOptions.length === 0;

    return (
        <table className="table table-borderless border border-gray">
            <thead>
            <tr>
                <th>Field</th>
                <th>Label</th>
                <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
                {Object.keys(items).map((selectValue) => {
                    return (
                        <tr key={selectValue}>
                            <td>{selectValue}</td>
                            <td>
                                <input
                                    className="form-control"
                                    value={items[selectValue]}
                                    onChange={(e) => {
                                        fieldHelpers.setValue({
                                            ...items,
                                            [selectValue]: e.target.value,
                                        })
                                    }}
                                />
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => {
                                        fieldHelpers.setValue(_.omit(items, selectValue))
                                    }}
                                >
                                    <DashLg />
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
            <tfoot className="bg-lighter">
            <tr>
                <th colSpan={3}>New</th>
            </tr>
            <tr>
                <td>
                    <select
                        className="form-control"
                        onChange={(e) => setSelectValue(e.target.value)}
                        value={selectValue}
                    >
                        {hasNoOptionsLeft
                            ? <option value="" disabled>-- No options left --</option>
                            : (
                                <>
                                    <option value="">-- select --</option>
                                    {availableOptions.map((value) => (
                                        <option
                                            value={value}
                                            key={value}
                                        >
                                            {value}
                                        </option>
                                    ))}
                                </>
                            )}
                    </select>
                </td>
                <td>
                    <input
                        className="form-control required-field"
                        value={labelValue}
                        onChange={(e) => setLabelValue(e.target.value)}
                        disabled={hasNoOptionsLeft}
                    />
                </td>
                <td>
                    <button
                        type="button"
                        className="btn"
                        disabled={hasNoOptionsLeft}
                        onClick={() => {
                            if (!selectValue || !labelValue) {
                                setError('Please fill in both fields.')
                            } else {
                                fieldHelpers.setValue({
                                    ...items,
                                    [selectValue]: labelValue,
                                });
                                // Clear inputs
                                setError('');
                                setSelectValue('');
                                setLabelValue('');
                            }
                        }}
                    >
                        <PlusLg />
                    </button>
                </td>
            </tr>
            {error && (
                <tr>
                    <td colSpan={3}>
                        <Alert variant="danger">{error}</Alert>
                    </td>
                </tr>
            )}
            </tfoot>
        </table>
    );
}
