import React from 'react';
import axios from "axios";
import {backendEndpoints} from "../../libs/backendEndpoints";
import Header from "../Header";
import {concepts} from "../../libs/lang";
import InviteUserModal from "./InviteUserModal";
import BackendForm from "../forms/BackendForm";
import {useFormikContext} from "formik";
import DynamicList from "../forms/DynamicList";
import UserRow from "./UserRow";
import {useConfirm} from "../contexts/ConfirmationModalContext";

const emptyUser = {
    email: '',
}

function DynamicListWrapper({ groupId, reloadGroup, groupRole, groupIsSubscribed }) {
    const { submitForm } = useFormikContext();
    const { openPromisify } = useConfirm();

    return (
        <DynamicList
            name="users"
            emptyEntry={emptyUser}
            Component={(props) => (
                <UserRow
                    {...props}
                    groupId={groupId}
                    groupRole={groupRole}
                    onChange={() => submitForm()}
                    groupIsSubscribed={groupIsSubscribed}
                />
            )}
            onDelete={(user) => {
                return openPromisify(
                    'Are you sure you want to remove '+(user.first_name || user.email)+' from the '+concepts.Group+'?',
                    () =>  axios.delete(`/groups/${groupId}/remove-user`, { data: { user_id: user.id } })
                        .then(() => reloadGroup()),
                    true,
                )
            }}
        />
    );
}

export default function GroupUserManagement({ group, reload, onSuccess }) {
    const groupId = group.id;

    return (
        <BackendForm
            initialValues={group}
            targetEndpoint={`${backendEndpoints.updateGroup(group.id)}?_with[]=users`}
            targetMethod="PUT"
            enableReinitialize
            showFooter={false}
            onSuccess={onSuccess}
        >
            <div className="d-flex align-items-start justify-content-between mt-5">
                <Header component="h4" className="mb-0">{concepts.GroupMembers}</Header>
                <div>
                    <InviteUserModal
                        groupId={groupId}
                        reloadGroup={reload}
                        groupIsSubscribed={group?.is_subscribed}
                    />
                </div>
            </div>
            <BackendForm.Feedback showError />
            <DynamicListWrapper
                groupId={groupId}
                groupRole={group?.role}
                reloadGroup={reload}
                groupIsSubscribed={group?.is_subscribed}
            />
        </BackendForm>
    );
}
