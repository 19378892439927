import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import useGet from "../hooks/useGet";
import {concepts} from "../../libs/lang";
import CopyText from "./CopyLink";

function UserLinkField({ userId }) {
    const { data } = useGet({ endpoint: `/users/${userId}/complete-account-link` })

    return <CopyText text={data?.url} />;
}

const UserCardPropTypes = {
    user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        group_name: PropTypes.string,
        organisation: PropTypes.string,
        position: PropTypes.string,
    }),
    children: PropTypes.node,
};

const UserCardDefaultProps = {};

export default function UserCard({ user, children }) {
    const [show, setShow] = useState(false);
    const isInvitedUserWithoutCompletedAccount = !user.first_name && !user.last_name;

    return (
        <div
            className="mb-0 py-2 text-start rounded-0 d-flex justify-content-between"
        >
            <div>
                <div className="fw-bold">
                    {isInvitedUserWithoutCompletedAccount ? (
                        <>
                            <div>{user.email}</div>
                            <Button variant="link" className="px-0 mb-0 py-0 text-sm" onClick={() => setShow(true)}>
                                Show invitation link
                            </Button>
                            {show && (
                                <Modal show onHide={() => setShow(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Invitation link</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Please share this link with your {concepts.Group.toLowerCase()} {concepts.GroupMember.toLowerCase()}.</p>
                                        <UserLinkField userId={user.id} />
                                    </Modal.Body>
                                </Modal>
                            )}
                        </>
                    ) : `${user.first_name} ${user.last_name}`}
                </div>
                <small>
                    {[[user.position, user.group_name].filter(s => s).join(', ')].map((str) => str ? <span key={str}>{str}</span> : null)}
                    {[user.organisation].map((str) => str ? <div key={str}>{str}</div> : null)}
                </small>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}

UserCard.propTypes = UserCardPropTypes;
UserCard.defaultProps = UserCardDefaultProps;
