import React from 'react';
import {Apple, CloudFill, Windows} from "react-bootstrap-icons";

export default function OsIcon({ name }) {
    return typeof name === 'string' ? (
        <>
            {name.indexOf('MacOS') === 0 && <Apple size={24} />}
            {name.indexOf('Windows') === 0 && <Windows size={24} />}
            {name.indexOf('web-app') === 0 && <CloudFill size={24} />}
        </>
    ) : null;
}
