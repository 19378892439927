import React from 'react';
import {Pages} from "../components/layout";
import Header from "../components/Header";
import {routes, sectionIds} from "../utils/routes";
import {concepts} from "../libs/lang";
import Groups from "./partials/Groups";
import Description from "../components/Description";
import {Link} from "react-router-dom";
import {Accordion} from "react-bootstrap";

export default function GroupDashboard() {
    return (
        <Pages.SideBar
            inverse
            size={{ md: 3 }}
            sidebar={(
                <div className="bg-lightest p-3">
                    <h5 className="mb-5">Frequently Asked Questions</h5>

                    <Accordion>
                        <Accordion.Item eventKey="what-are-groups">
                            <Accordion.Header>What are {concepts.Groups} for?</Accordion.Header>
                            <Accordion.Body>
                                <Description>
                                    {concepts.Groups} can be used to share sequences with other {concepts.Group}
                                    &nbsp;members. If a {concepts.Group} has a {concepts.Subscription}, it can provide
                                    access to Photo51 for all its members.
                                </Description>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="how-are-groups-charged">
                            <Accordion.Header>
                                How are {concepts.Group} {concepts.Subscriptions} charged?
                            </Accordion.Header>
                            <Accordion.Body>
                                <Description>
                                    With a {concepts.Subscription}, any team member can opt in on the subscription.
                                </Description>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="how-does-multiple-groups-work">
                            <Accordion.Header>
                                How does payments work if I have multiple {concepts.Groups}?
                            </Accordion.Header>
                            <Accordion.Body>
                                <Description>
                                    You only need to have one subscription. Either as an individual or via one of your {concepts.Groups}.
                                    In <Link to={routes.subscriptions}>{concepts.MySubscription}</Link>, you can select through which {concepts.Group} you would like to arrange
                                    your {concepts.Subscription}.
                                </Description>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            )}
        >
            <Header component="h2" id={sectionIds.teams}>
                <span className="me-5">{concepts.Groups} / Groups</span>
            </Header>
            <Groups />
        </Pages.SideBar>
    );
}
