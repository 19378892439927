import React, {useEffect, useState} from 'react';
import Tab from "react-bootstrap/Tab";
import {useSearchParams} from "react-router-dom";
import {concepts} from "../../libs/lang";
import Description from "../Description";
import StripePricingTable, { PRICING_TABLE_PRODUCT } from "../StripePricingTable";
import {stripePriceTables} from "../../utils/stripe";
import {routes} from "../../utils";
import Tabs from "react-bootstrap/Tabs";
import {PLAN_TAB_KEYS} from "../../utils/routes";
import trackEvent, {ACTIONS, CATEGORIES} from "../tracking/trackEvent";

const freeProduct = {
    product: {
        name: 'Basic',
        description: 'Enjoy all Photo51 features for free for 1 month. After your trial, you\'ll still be able to access and view your uploaded data. Upgrade to a paid plan to unlock full functionality.',
        metadata: {
            accountType: 'individual',
        },
    },
    unit_amount: 0
}

export default function SubscriptionTabs({ hideBasic, center = true }) {
    const [searchParams] = useSearchParams();
    const [key, setKey] = useState(PLAN_TAB_KEYS.ACADEMIC);

    useEffect(() => {
        const queryTabValue = searchParams.get('tab');
        if (Object.values(PLAN_TAB_KEYS).indexOf(queryTabValue) > -1) {
            setKey(queryTabValue);
        }
    }, []);

    const className = (k) => (
        k === key
            ? 'h4 text-black border-0 py-4 px-5 rounded-top-md bg-lightest'
            : 'h4 text-black border-0 py-3 px-5 rounded-top-md bg-lighter'
    );

    return (
        <Tabs
            id="plans-tabs"
            activeKey={key}
            onSelect={(k) => {
                setKey(k);
                trackEvent(CATEGORIES.TAB_VIEW, ACTIONS.VIEW_PLAN_ON_PUBLIC_PLANS, k);
            }}
            className={`mb-3 justify-content-center align-items-end`}
        >
            <Tab
                eventKey={PLAN_TAB_KEYS.ACADEMIC}
                tabClassName={className(PLAN_TAB_KEYS.ACADEMIC)}
                title={concepts.Academic}
            >
                <StripePricingTable
                    tableId={stripePriceTables.ACADEMIC}
                    redirectTo={routes.signUp}
                    prePrices={hideBasic ? [] : [freeProduct]}
                    postPrices={[PRICING_TABLE_PRODUCT.INSTITUTE]}
                    center={center}
                    filterScope
                />
            </Tab>
            <Tab
                eventKey={PLAN_TAB_KEYS.CORPORATE}
                tabClassName={className(PLAN_TAB_KEYS.CORPORATE)}
                title={concepts.Corporate}
            >
                <StripePricingTable
                    tableId={stripePriceTables.CORPORATE}
                    redirectTo={routes.signUp}
                    prePrices={hideBasic ? [] : [freeProduct]}
                    postPrices={[PRICING_TABLE_PRODUCT.INSTITUTE]}
                    center={center}
                    filterScope
                />
            </Tab>
        </Tabs>
    );
}
