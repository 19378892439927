import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useField } from "formik";
import Button from "react-bootstrap/Button";
import {Plus, Trash3} from "react-bootstrap-icons";
import Alert from "react-bootstrap/Alert";
import cx from "classnames";

function DeleteButton({ onDelete, onSuccess }) {
    const [isDeleting, setDeleting] = useState(false);
    const [error, setError] = useState('');

    return (
        <>
            <Button
                variant="outline-danger"
                onClick={() => {
                    setError('')
                    if (!isDeleting) {
                        if (typeof onDelete === 'function') {
                            setDeleting(true)
                            onDelete()
                                .then(() => {
                                    onSuccess()
                                })
                                .catch((e) => {
                                    setError(e.response?.data?.message)
                                })
                                .finally(() => setDeleting(false))
                        } else {
                            onSuccess()
                        }
                    }
                }}
            >
                <Trash3 />
            </Button>
            {error && <Alert className="alert-danger">{error}</Alert>}
        </>
    )
}
DeleteButton.propTypes = {
    onDelete: PropTypes.func,
}

const DynamicListPropTypes = {
    emptyEntry: PropTypes.oneOfType([PropTypes.shape]),
    name: PropTypes.string.isRequired,
}

const DynamicListDefaultProps = {
    addButton: false,
    emptyEntry: {},
}

export default function DynamicList({ name, emptyEntry, Component, addButton, onDelete }) {
    const [{ value }] = useField(name)

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => {
                return (
                    <div>
                        {value && value.length > 0 && (
                            value.map((item, index) => (
                                <div
                                    key={index}
                                    className={cx({
                                        "d-sm-flex flex-row px-3 py-2 align-items-center mb-1": true,
                                        "bg-lighter": true,
                                        "rounded-bottom": index === value.length - 1,
                                        "rounded-top": index === 0,
                                    })}
                                >
                                    <div className="flex-sm-fill me-4">
                                        <Component item={item} name={`${name}.${index}`} />
                                    </div>
                                    <div>
                                        {value.length >= 2 && (
                                            <DeleteButton
                                                onSuccess={() => {
                                                    arrayHelpers.remove(index)
                                                }}
                                                onDelete={() => onDelete(item)}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                        {addButton && (
                            <Button onClick={() => arrayHelpers.push(emptyEntry)}>
                                <Plus />
                            </Button>
                        )}
                    </div>
                )
            }}
        />
    );
}

DynamicList.propTypes = DynamicListPropTypes;
DynamicList.defaultProps = DynamicListDefaultProps;
