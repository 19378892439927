import React from 'react';
import {adminRoutes} from "../../../utils/routes";
import Pages from "../../../components/layout/Pages";
import {formats} from "../../../components/layout/Table";
import OrderableTable from "../../../components/layout/OrderableTable";
import {concepts, explanations} from "../../../libs/lang";
import Button from "react-bootstrap/Button";
import {PlusLg} from "react-bootstrap-icons";
import {backendEndpoints} from "../../../libs/backendEndpoints";

const columns = [
    { id: 'id', accessor: 'id', header: 'ID' },
    { id: 'title', accessor: 'title', header: 'Title' },
    { id: 'institute', accessor: 'institute.name', header: 'Institute' },
    { id: 'type', accessor: 'type', header: 'Type' },
    { id: 'value', accessor: 'value', header: 'value' },
    { id: 'n_users_following_rule', accessor: 'n_users_following_rule', header: '# users' },
    { id: 'created_at', accessor: 'created_at', header: 'Sign up at', Cell: formats.date },
]

export default function AdminSubscriptionRules(props) {
    return (
        <Pages.Centred padded>
            <div className="d-inline-flex align-items-center gap-4">
                <h1>{concepts.SubscriptionRules}</h1>
                <Button size="sm" href={adminRoutes.addSubscriptionRule} variant="outline-primary"><PlusLg /> add</Button>
            </div>
            <p>{explanations.WhatAreSubscriptionRules}</p>
            <OrderableTable
                orderables={{
                    'type': 'type',
                    'value': 'value',
                }}
                baseEndpoint={backendEndpoints.getSubscriptionRules}
                memoisedColumns={columns}
                buildLink={(record) => adminRoutes.subscriptionRule(record.id)}
            />
        </Pages.Centred>
    );
}
