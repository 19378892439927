import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import LogRocket from 'logrocket';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from "axios";
import {IS_DEV, isProd} from "./utils/env";
import './assets/fonts/marion.ttf';
import './assets/fonts/AvenirLTStd-Book.otf';
import './assets/fonts/Copperplate-Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.baseURL = IS_DEV ? 'http://localhost/api' :'/api';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

if (isProd()) {
    LogRocket.init('fearless-league/website-jvkya');
}


const router = createBrowserRouter([
    {
        path: "*",
        element: <App />,
    },
]);

root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
