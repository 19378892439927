import React, {useEffect, useRef} from 'react';
import moment from 'moment'
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Pages, DataRow } from "../components/layout";
import useAuth from "../components/hooks/useAuth";
import {routes} from "../utils";
import {concepts} from "../libs/lang";
import {standardDateTimeFormat} from "../utils/momentHelpers";
import UserBillingPortal from "./partials/UserBillingPortal";
import Header from "../components/Header";
import {sectionIds} from "../utils/routes";
import Security from "./partials/Security";
import Description from "../components/Description";
import ProfilePicture from "../components/ProfilePicture";
import TwoFactorAuthenticationSummary from "./2fa/partials/TwoFactorAuthenticationSummary";
import {ReadOnlyCustomFields} from "../components/forms/UserCustomFields";


export default function Dashboard() {
    const { user } = useAuth()
    const ref = useRef();
    const navigate = useNavigate();

    useEffect(() => {

        ref.curent = setInterval(() => {
            if (window.location.hash) {
                const el = document.getElementById(window.location.hash.replace('#', ''));
                if (el) {
                    clearTimeout(ref.curent);
                    const yOffset = -100;
                    const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
                    if (y) window.scrollTo({ top: y })
                }
            }
        }, 100);

        return () => {
            clearTimeout(ref.curent);
        }
    }, [window.location.hash])

    return (
        <Pages.SideBar
            size={{ md: 3 }}
            sidebar={(
                <ListGroup className="sticky-top sticky-top-offset">
                    <ListGroupItem as="a" href={routes.personalInformation}>
                        {concepts.PersonalInformation}
                    </ListGroupItem>
                    <ListGroupItem as="a" href={routes.subscriptions}>
                        {concepts.MySubscription}
                    </ListGroupItem>
                    <ListGroupItem as="a" href={routes.security}>
                        {concepts.Security}
                    </ListGroupItem>
                </ListGroup>
            )}
        >
            <Header component="h2"  id={sectionIds.personalInfo} className="d-flex justify-content-start">
                <span className="me-5">{concepts.PersonalInformation}.</span>
                <Button onClick={() => navigate(routes.accountEdit)}>
                    Edit
                </Button>
            </Header>
            <Row>
                <Col md={3}>
                    <ProfilePicture user={user} size="100%" />
                </Col>
                <Col>
                    <DataRow label="Name">{user?.first_name} {user?.last_name}</DataRow>
                    <DataRow label={concepts.Username}>{user?.username}</DataRow>
                    <DataRow label="Email">{user?.email}</DataRow>
                    <DataRow label="Organization">{user?.organisation}</DataRow>
                    <DataRow label="Position">{user?.position}</DataRow>
                    <DataRow label={concepts.GroupName}>{user?.group_name}</DataRow>
                    <DataRow label="Member since">{moment(user?.created_at).format(standardDateTimeFormat)}</DataRow>
                    {user?.custom_fields && <ReadOnlyCustomFields customFields={user?.custom_fields}/>}
                </Col>
            </Row>

            <hr className="my-5" />
            <Header component="h2" id={sectionIds.security}>
                {concepts.Security}.
            </Header>

            <Header component="h5" className="mt-5">Password</Header>
            <Description>
                If you want to change your password. Please visit the&nbsp;
                <Link to={routes.forgotPassword} className="btn-link">password reset</Link> page.
            </Description>

            <Header component="h5" className="mt-5">Two-factor Authentication</Header>
            <p>Two-factor authentication adds an additional layer of security to your account by requiring more than
                just a password to sign in.</p>
            <TwoFactorAuthenticationSummary />

            <Header component="h5" className="mt-5">Logged-in devices</Header>
            <p>This is a list of devices that have logged into your account. Revoke any sessions that you do not recognize.</p>
            <Security />

            <hr className="my-5" />
            <Header component="h2" id={sectionIds.subscriptions}>
                {concepts.MySubscription}.
            </Header>
            <UserBillingPortal />
            <div className="py-vh10" />

        </Pages.SideBar>
    );
}
