import React from 'react';
import { useDelayedState } from 'fearless-common';
import {adminRoutes} from "../../utils/routes";
import Pages from "../../components/layout/Pages";
import {formats} from "../../components/layout/Table";
import OrderableTable from "../../components/layout/OrderableTable";
import OsIcon from "../../components/OSIcon";
import moment from "moment/moment";
import {defaultDateTimeFormat} from "../../utils/date";
import SearchInput from "../../components/SearchInput";

const columns = [
    { id: 'id', accessor: 'id', header: 'ID' },
    { id: 'name', accessor: 'name', header: 'Name', Cell: formats.fullName },
    { id: 'organisation', accessor: 'organisation', header: 'Position, Group, Organisation', Cell: ({ row }) => (<>
            {[row.original?.position, row.original?.group_name, row.original?.organisation].filter(v => v).join(', ')}
        </>)},
    { id: 'email', accessor: 'email', header: 'Email' },
    { id: 'subscription', accessor: 'is_subscribed', header: 'Subscribed', Cell: formats.bool },
    { id: 'last_version_used', accessor: 'last_version_used', header: 'Version' },
    { id: 'os', accessor: 'last_desktop_access_token', header: 'Last Desktop OS', Cell: ({ row }) => {
        return (
                <>
                    {row.original.last_desktop_access_token?.length
                        ? (
                            <span className="text-sm">
                                <OsIcon name={row.original.last_desktop_access_token[0].name} />
                                {moment(row.original.last_desktop_access_token[0].last_used_at).format(defaultDateTimeFormat)}
                            </span>
                        )
                        : ''}
                </>
            )
        } },
    { id: 'trial', accessor: 'on_trial', header: 'Trial', Cell: formats.bool },
    { id: 'groups', accessor: 'groups', header: '# of groups', Cell: formats.length },
    { id: 'created_at', accessor: 'created_at', header: 'Sign up at', Cell: formats.date },
    { id: 'email_verified', accessor: 'email_verified_at', header: 'Email verified', Cell: formats.bool },
]

export default function AdminUsers() {
    const [query, setQuery] = useDelayedState('');

    return (
        <Pages.Centred padded>
            <div className="d-flex align-items-center">
                <h1 className="flex-grow-1 mb-0">Users</h1>
                <div className="ms-3">
                    <SearchInput onChange={(e) => setQuery(e.target.value)} />
                </div>
            </div>
            <OrderableTable
                orderables={{
                    'name': 'last_name',
                    'created_at': 'created_at',
                    'last_version_used': 'last_version_used',
                }}
                baseEndpoint={'/admin/users?_orderBy=created_at&_with[]=lastDesktopAccessToken'}
                memoisedColumns={columns}
                buildLink={(record) => adminRoutes.user(record.id)}
                pagination
                searchQuery={query}
            />
        </Pages.Centred>
    );
}
