import React from 'react';
import Button from "react-bootstrap/Button";
import {PlusLg} from "react-bootstrap-icons";
import {adminRoutes} from "../../utils/routes";
import Pages from "../../components/layout/Pages";
import OrderableTable from "../../components/layout/OrderableTable";
import {backendEndpoints} from "../../libs/backendEndpoints";

const columns = [
    { id: 'id', accessor: 'id', header: 'ID' },
    { id: 'name', accessor: 'name', header: 'Name' },
]

/**
 * Show a table of all institutes.
 */
export default function AdminInstitutes() {
    return (
        <Pages.Centred padded>
            <div className="d-inline-flex align-items-center gap-4">
                <h1>Institutes</h1>
                <Button size="sm" href={adminRoutes.addInstitute} variant="outline-primary"><PlusLg /> add</Button>
            </div>
            <OrderableTable
                orderables={{
                    'name': 'name',
                }}
                baseEndpoint={backendEndpoints.getAdminInstitutes}
                memoisedColumns={columns}
                buildLink={(record) => adminRoutes.institute(record.id)}
            />
        </Pages.Centred>
    );
}
