import React from 'react';
import styles from "./ManualPricingTable.module.scss";
import {isGroupProduct} from "./price";

const PER_QUANTITY = 'per seat';

export default function UnitSpan({ price: selectedPrice, showPerUser = true, showMonthly = false, showInterval = true  }) {
    const addStar = showMonthly && selectedPrice.recurring?.interval === 'year';
    return selectedPrice.unit_amount > 0 && (
        <span className={styles.PriceUnit}>
            {isGroupProduct(selectedPrice) && showPerUser && <div>{PER_QUANTITY}</div>}
            {showInterval && `per ${showMonthly ? 'month' : selectedPrice.recurring?.interval}`}
            {addStar && <>*</>}
        </span>
    );
}
