import React from 'react';
import {Pages} from "../components/layout";
import useTitle from "../components/hooks/useTitle";
import { GmailIcon, OutlookIcon } from 'fearless-common/src/images'
import {routes} from "../utils";
import styles from './SignUpSuccess.module.scss';
import Header from "../components/Header";
import {IS_DEV} from "../utils/env";

const items = [
    {
        label: 'Gmail',
        icon: GmailIcon,
        website: routes.gmail
    }, {
        label: 'MS Outlook',
        icon: OutlookIcon,
        website: routes.microsoftOutlook
    },
]

if (IS_DEV) {
    items.push({
        label: 'MailHog',
        icon: <div>MailHog</div>,
        website: 'http://localhost:8025',
    })
}

export default function SignUpSuccess() {
    useTitle('Welcome to the Fearless League')

    return (
        <Pages.Centred width="xs" className="text-center">
            <h1 className="gradient-color mb-3">Almost done!</h1>
            <p>Please verify your email to complete your signup.</p>

            <hr className="my-5" />
            <Header component="h5" className="my-4 ">Please navigate to your mail application</Header>
            <div className="d-flex align-items-start justify-content-center">
                {items.map(({ icon, website, label }) => (
                    <div key={label} className="px-4">
                        <div className={`${styles.IconContainer} d-flex align-items-center justify-content-center mb-3`}>
                            {icon}
                        </div>
                        {website && <a className="btn btn-outline-light" href={website} target="_blank">Go to {label} website</a>}
                    </div>
                ))}
            </div>
        </Pages.Centred>
    );
}
