import React, {useEffect, useMemo} from 'react';
import useAuth from "../../components/hooks/useAuth";
import {addStripeToHead} from "../../utils/stripe";
import SubscriptionCard from "../../components/subscriptions/SubscriptionCard";
import {Accordion, Alert} from "react-bootstrap";
import moment from "moment";
import { standardDateFormat } from "../../utils/momentHelpers";
import SubscriptionSwitcher from "../../components/subscriptions/SubscriptionSwitcher";
import useGet from "../../components/hooks/useGet";
import {backendEndpoints} from "../../libs/backendEndpoints";
import SubscriptionTabs from "../../components/billing/SubscriptionTabs";
import Loading from "../../components/Loading";
import {concepts} from "../../libs/lang";

export default function UserBillingPortal() {
    const { user, reloadUser } = useAuth();
    const { data: groups, isLoading, error } = useGet({ endpoint: backendEndpoints.getGroups })

    const optionalGroupSubscription = useMemo(() => {
        return !!groups?.find(group => group.is_subscribed);
    }, [groups]);

    useEffect(() => {
        if (user) addStripeToHead();
    }, [user]);

    const hasIndividualSubscription = user.is_subscribed && !user.subscribed_via_group;

    if (isLoading) return <Loading />;
    if (error) return <Alert variant="danger">{error}</Alert>;

    return user ? (
        <>
            {user.on_trial && (
                <Alert variant="info">
                    Your free trial period will end&nbsp;
                    {moment(user.trial_ends_at).format(standardDateFormat)}
                    . Please select a subscription to keep all your features.
                </Alert>
            )}
            {(user.is_subscribed || optionalGroupSubscription) && <SubscriptionSwitcher onSuccess={() => reloadUser()} />}
            {Array.isArray(user?.subscribed_via_group?.subscriptions) && (
                user.subscribed_via_group.subscriptions.map((sub) => <SubscriptionCard key={sub.id} subscription={sub} group={user?.subscribed_via_group} />)
            )}
            {Array.isArray(user?.subscriptions) && (
                user.subscriptions.map((sub) => <SubscriptionCard key={sub.id} subscription={sub} />)
            )}
            {!hasIndividualSubscription && (user.is_subscribed ? (
                <Accordion>
                    <Accordion.Item eventKey="subscriptions">
                        <Accordion.Header>
                            View {concepts.Plans.toLowerCase()} for individuals
                        </Accordion.Header>
                        <Accordion.Body>
                            <SubscriptionTabs hideBasic center={false} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : <SubscriptionTabs center={false} />)}
        </>
    ) : null;
}
