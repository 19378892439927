import React from 'react';
import useAuth from "../components/hooks/useAuth";
import TileMenu from "../components/layout/TileMenu";
import {concepts} from "../libs/lang";
import standardIcons from "../components/layout/StandardIcons";
import {Apple, Windows} from "react-bootstrap-icons";
import {Pages} from "../components/layout";
import {routes} from "../utils";
import Fold from "../components/layout/Fold";
import GettingStarted from "../components/GettingStarted";
import background from './../assets/images/backgrounds/blue-gradient-film-theme.png';
import girlMacWindows from './../assets/images/backgrounds/girl-running.png';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Tiles() {
    return (
        <div className="text-center mt-5">
            <TileMenu>
                <TileMenu.Item
                    header={`Open ${concepts.Photo51} cloud`}
                    icon={standardIcons.Cloud}
                    to={routes.photo51Cloud}
                />
                <TileMenu.Item
                    header={`Download ${concepts.Photo51}`}
                    icon={() => (
                        <>
                            <Apple size={60} className="me-2 mb-1 text-secondary" />
                            <Windows size={60} className="text-secondary me-2" />
                        </>
                    )}
                    to={routes.download}
                />
                <TileMenu.Item
                    header={`Manage your ${concepts.Account}`}
                    icon={standardIcons.Account}
                    to={routes.dashboard}
                />
                <TileMenu.Item
                    header={`Organize your ${concepts.Group}`}
                    icon={standardIcons.Group}
                    to={routes.groups}
                />
                <TileMenu.Item
                    header={concepts.MySubscription}
                    icon={standardIcons.Subscriptions}
                    to={routes.subscriptions}
                />
            </TileMenu>
            <div className="py-2" />
        </div>
    );
}

export default function LoginLanding() {
    const { user } = useAuth();

    return (
        <Pages.Base width="sm">
            <Fold className="bg-lightest text-center pt-5" backgroundImage={background}>
                <h1 className="text-white text-center my-5">Welcome, {user?.first_name}</h1>
                <Container>
                    <Row>
                        <Col
                            sm={{ span: 6 }}
                            style={{
                                backgroundImage: `url(${girlMacWindows})`,
                                backgroundSize: 'contain',
                                minHeight: '300px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                filter: 'FlipH',
                            }}
                        >

                        </Col>
                        <Col
                            sm={{ span: 6, offset: 0 }}
                            className="text-left"
                        >
                            <GettingStarted />
                        </Col>
                    </Row>
                </Container>
            </Fold>
            <Tiles />
        </Pages.Base>
    );
}
