import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {ErrorMessage, Field, useField} from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import {Eye, EyeSlash} from "react-bootstrap-icons";
import styles from './FloatingLabelField.module.scss';
import moment from "moment";

const FloatingLabelFieldPropTypes = {
    autoFocus: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
}

const FloatingLabelFieldDefaultProps = {
    type: 'text',
    autoFocus: false
}

const MB = 'mb-2';
const DT_FORMAT = 'YYYY-MM-DD HH:mm';
const D_FORMAT = 'YYYY-MM-DD';

function getInitialValue(type, value) {
    if (type === 'date' && value) return moment(value).format(D_FORMAT);
    if (type === 'datetime' && value) return moment(value).format(DT_FORMAT);
    return '';
}

export default function FloatingLabelField({
    name,
    label,
    type,
    autoFocus = false,
    icon,
    helperText = '',
    required = false,
    disappearingLabel = false,
}) {
    const [field,, helpers ] = useField(name);
    const { setValue } = helpers;
    const [localValue, setLocalValue] = useState(getInitialValue(type, field.value));
    const [forceTextType, setForceTextType] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const id = label + name;

    const Icon = forceTextType ? Eye: EyeSlash;
    const showPassword = type === 'password' && (
        <Icon
            className={cx({
                [styles.ToggleViewingPassword]: true,
                'fs-4 gray-500': true,
            })}
            onClick={() => setForceTextType(!forceTextType)}
        />
    );

    const onChange = useCallback((value, dateFormat) => {
        setLocalValue(value);
        const date = moment(value);
        if (date.isValid()) {
            setValue(date.format(dateFormat))
        }
    }, [setLocalValue, setValue])
    const focusHandlers = useMemo(() => {
        return {
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
        };
    }, [setFocused])

    const isTextarea = type === 'textarea';
    const textAreaProps = isTextarea ? {
        rows: 5,
        as: 'textarea',
        style: {
            minHeight: '150px'
        },
    }: {};

    const mainInput = (
        <FloatingLabel
            controlId={id}
            label={(
                <div className={cx({"required-field": required, [styles.floatedLabel]: true})}>
                    {label}
                </div>
            )}
            className={cx({
                [styles.disappearingLabel]: disappearingLabel,
                [styles.hasValue]: Boolean(field.value),
                [styles.isFocused]: isFocused,
            })}
        >
            {type === 'date' && (
                <>
                    <input
                        className={`form-control ${!moment(localValue).isValid() ? 'border-danger': ''} ${styles.input}`}
                        name={name}
                        id={id}
                        type="type"
                        value={localValue}
                        onChange={(e) => {
                            onChange(e.target.value, D_FORMAT);
                        }}
                        placeholder={label}
                        autoFocus={autoFocus}
                        {...focusHandlers}
                    />
                    {localValue && !moment(localValue, D_FORMAT).isValid() && <small className="text-danger">Invalid date format. Please use YYYY-MM-DD</small>}
                </>
            )}
            {type === 'datetime' && (
                <>
                    <input
                        className={`form-control ${!moment(localValue).isValid() ? 'border-danger': ''} ${styles.input}`}
                        name={name}
                        id={id}
                        type="text"
                        value={localValue}
                        onChange={(e) => {
                            onChange(e.target.value, DT_FORMAT);
                        }}
                        placeholder={label}
                        autoFocus={autoFocus}
                        {...focusHandlers}
                    />
                    {localValue && !moment(localValue).isValid() && <small className="text-danger">Invalid date format. Please use YYYY-MM-DD HH:mm</small>}
                </>
            )}
            {type !== 'datetime' && type !== 'date' && (
                <Field
                    className={`form-control ${styles.input}`}
                    name={name}
                    id={id}
                    type={forceTextType ? 'text' : type}
                    placeholder={label}
                    autoFocus={autoFocus}
                    {...textAreaProps}
                    {...focusHandlers}
                />
            )}
        </FloatingLabel>
    )

    return (
        <div className={helperText ? undefined : MB}>
            {icon ? (
                <InputGroup className={helperText ? "mb-0" : undefined}>
                    <InputGroup.Text className="fs-4 gray-500">
                        {icon}
                    </InputGroup.Text>
                    {mainInput}
                    {showPassword && <InputGroup.Text>{showPassword}</InputGroup.Text>}
                </InputGroup>
            ) : mainInput}
            {helperText && <div className="text-right text-sm gray-500 mb-3">{helperText}</div>}
            <ErrorMessage name={name} className="text-danger" component="span" />
        </div>
    );
}

FloatingLabelField.propTypes = FloatingLabelFieldPropTypes;
FloatingLabelField.defaultProps = FloatingLabelFieldDefaultProps;
