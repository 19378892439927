import {isProd} from "../../utils/env";

export const ACTIONS = {
    DOWNLOAD_GETTING_STARTED: 'download-via-getting-started',
    FOOTER_LINK: 'footer-link',
    VIEW_PLAN_ON_PUBLIC_PLANS: 'view-plans-on-public-plans-page',
    MAIN_NAVIGATION_TRY_FOR_FREE: 'main-navigation-try-for-free',
    HOME_PAGE_FOLD_TRY_FOR_FREE: 'home-page-fold-try-for-free',
    PLANS_FOLD_TRY_FOR_FREE: 'plans-fold-try-for-free',
    START_WITH_PHOTO51_BANNER_PLANS_PAGE_TRY_FOR_FREE: 'start-with-photo51-banner-plans-page-try-for-free',
    START_WITH_PHOTO51_BANNER_PHOTO51_PAGE_TRY_FOR_FREE: 'start-with-photo51-banner-photo51-page-try-for-free',
}

export const CATEGORIES = {
    FILE_DOWNLOAD: 'file_download',
    CLICK: 'click',
    TAB_VIEW: 'tab_view',
    SIGN_UP_CTA: 'sign_up_cta',
};

export default function trackEvent(category, action, label) {
    if (isProd() && window?.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
        });
    }
}
