import React from 'react';
import {IconContent, Pages} from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {concepts} from "../libs/lang";
import useTitle from "../components/hooks/useTitle";
import Description from "../components/Description";
import {CheckLg} from "react-bootstrap-icons";
import StartWithPhoto51 from "../components/StartWithPhoto51";
import LittleDetails from "../components/LittleDetails";
import TryForFreeButton from "../components/TryForFreeButton";
import SubscriptionTabs from "../components/billing/SubscriptionTabs";
import {ACTIONS} from "../components/tracking/trackEvent";
import useAuth from "../components/hooks/useAuth";
import useMetaDescription from "../components/hooks/useMetaDescription";

const checkIcon = <CheckLg className="text-secondary" size={25} />;
const mapper = (text, idx) => <li key={idx} className="text-white"><IconContent icon={checkIcon} noMargin>{text}</IconContent></li>;

export default function Plans() {
    const { user } = useAuth();
    useTitle('Subscription plans');
    useMetaDescription(
        `Unlock powerful molecular biology software with ${concepts.Photo51}'s flexible plans. Find the perfect fit for your needs, from free trials to individual subscriptions and scalable team solutions. Start your free trial today!`
    )

    return (
        <Pages.Centred>
            <section className="text-center mb-5">
                <h1 className="my-4">
                    Find the Perfect Plan for Your Research Needs
                </h1>
                <Description className="text-lg gray-200 mx-auto mb-4">
                    Explore our flexible subscription plans,
                    <br />
                    designed to empower researchers and teams of all sizes.
                </Description>

                <h2 className="text-xl fw-bold mx-auto mb-4">Sign up today and make great discoveries!</h2>

                <TryForFreeButton
                    actionTag={!user ? ACTIONS.PLANS_FOLD_TRY_FOR_FREE : undefined}
                    className="shadow"
                />
            </section>
            <section style={{ maxWidth: '1400px' }} className="mx-auto py-5">
                <SubscriptionTabs />
                <LittleDetails className="text-center">
                    All prices are excluding VAT.
                    <br/>
                    * = Billed annually
                </LittleDetails>
            </section>
            <section>
                <div className="text-center mt-5 bg-darkest rounded-bottom-lg py-5 text-white">
                    <div className="d-inline-block">
                        <h2 className="mb-5 text-center">
                            Empower Your Research with {concepts.Photo51}
                        </h2>
                        <Row>
                            <Col>
                                <ul className="list-unstyled mx-0 px-0 text-left d-inline-block">
                                    {[
                                        <>Create unlimited teams &amp; work together</>,
                                        'Drag \'n drop to upload your inventory',
                                        'Simplify your plasmid design and cloning projects',
                                        'Generate detailed sequence and map visuals',
                                        'Re-trace your steps with a visual history',
                                        'Share data without exporting or mailing (built-in sharing)',

                                        ].map(mapper)}
                                </ul>
                            </Col>
                            <Col>
                                <ul className="list-unstyled mx-0 px-0 text-left d-inline-block">
                                    {[
                                        'Photo51 Desktop software: For PC and Mac',
                                        'Photo51 Cloud: Work from anywhere',
                                        'Free upgrades, updates and fixes',
                                        'Hassle-free subscription management',
                                        'Dedicated customer support',
                                    ].map(mapper)}
                                </ul>
                            </Col>
                        </Row>
                        <p className="text-center fw-semibold">
                            For custom, on-site solutions, please contact us.
                        </p>
                    </div>
                </div>
            </section>
            <StartWithPhoto51
                actionTag={ACTIONS.START_WITH_PHOTO51_BANNER_PLANS_PAGE_TRY_FOR_FREE}
                variant="light"
            />
        </Pages.Centred>
    );
}
