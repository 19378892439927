import React from 'react';
import cx from "classnames";
import styles from "../../pages/partials/ShowCase.module.scss";

function GalleryDots({ length, activeIndex }) {
    return (
        <div className="d-inline-flex">
            {[...new Array(length)].map((unused, idx) => (
                <div
                    className={cx({
                        [styles.Dot]: true,
                        'rounded-circle mx-1': true,
                        'bg-secondary': activeIndex === idx
                    })}
                    key={idx}
                />
            ))}
        </div>
    );
}

export default GalleryDots;
