import Layout from "./components/layout/Layout";
import {Route, Routes} from "react-router-dom";
import {Navigate} from "react-router";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { routes } from "./utils";
import Dashboard from "./pages/Dashboard";
import {AuthProvider} from "./components/hooks/useAuth";
import Logout from "./pages/Logout";
import {QueryClient, QueryClientProvider} from 'react-query'
import AccountForm from "./pages/AccountForm";
import SignUp from "./pages/SignUp";
import SignUpSuccess from "./pages/SignUpSuccess";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/NotFound";
import DownloadPhoto51 from "./pages/DownloadPhoto51";
import Photo51 from "./pages/Photo51";
import EmailVerified from "./pages/EmailVerified";
import CompleteAccount from "./pages/CompleteAccount";
import CompleteAccountSuccess from "./pages/CompleteAccountSuccess";
import AboutUs from "./pages/AboutUs";
import LoginLanding from "./pages/LoginLanding";
import Plans from "./pages/Plans";
import TwoFactorAuthentication from "./pages/2fa/TwoFactorAuthentication";
import TwoFactorAuthenticationSuccess from "./pages/2fa/TwoFactorAuthenticationSuccess";
import ProtectedRoute from "./pages/guards/ProtectedRoute";
import TwoFactorChallenge from "./pages/TwoFactorChallenge";
import GroupDashboard from "./pages/GroupDashboard";
import {useEffect} from "react";
import {IS_DEV, isInternal, isProd} from "./utils/env";
import FAQ from "./pages/FAQ";
import {addScript} from "./utils/htmlHead";
import {GOOGLE_TAG_ID} from "./tracking/google-analytics";
import {adminRoutes, externalLinks} from "./utils/routes";
import RedirectPage from "./pages/RedirectPage";
import InvalidLink from "./pages/errors/InvalidLink";
import JoinGroupLink from "./pages/shareable-links/JoinGroupLink";
import OpenPhoto51 from "./pages/OpenPhoto51";
import AdminUsers from "./pages/admin/AdminUsers";
import AdminUser from "./pages/admin/AdminUser";
import AdminGroups from "./pages/admin/AdminGroups";
import AdminGroup from "./pages/admin/AdminGroup";
import AdminSubscriptionRules from "./pages/admin/subscription-rules/SubscriptionRules";
import AdminSubscriptionRule from "./pages/admin/subscription-rules/SubscriptionRule";
import ConfirmationModalProvider from "./components/contexts/ConfirmationModalContext";
import ShareRequestAccessLink from "./pages/shareable-links/ShareRequestAccessLink";
import AdminSubscriptions from "./pages/admin/AdminSubscriptions";
import AdminSubscription from "./pages/admin/AdminSubscription";
import AdminInsights from "./pages/admin/AdminInsights";
import AdminInstitutes from "./pages/admin/Institutes";

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 5*60*1000,
        },
    },
})

function App() {
    useEffect(() => {
        if (isInternal() || isProd()) {
            addScript({ src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`, async: true })
            addScript({
                html: "window.dataLayer = window.dataLayer || [];\n" +
                    "function gtag(){dataLayer.push(arguments);}\n" +
                    "gtag('js', new Date());\n" +
                    `gtag('config', '${GOOGLE_TAG_ID}');`
            })
        }

        if (IS_DEV || isInternal()) {
            // Hotjar Tracking Code for https://internal.fearlessleague.com
            addScript({
                html:
                    "(function(h,o,t,j,a,r){\n" +
                    "    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n" +
                    "    h._hjSettings={hjid:3280770,hjsv:6};\n" +
                    "    a=o.getElementsByTagName('head')[0];\n" +
                    "    r=o.createElement('script');r.async=1;\n" +
                    "    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n" +
                    "    a.appendChild(r);\n" +
                    "})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
            });
        } else {
            // Hotjar Tracking Code for https://fearlessleague.com
            addScript({
                html:
                    "(function(h,o,t,j,a,r){\n" +
                    "    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n" +
                    "    h._hjSettings={hjid:3280770,hjsv:6};\n" +
                    "    a=o.getElementsByTagName('head')[0];\n" +
                    "    r=o.createElement('script');r.async=1;\n" +
                    "    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n" +
                    "    a.appendChild(r);\n" +
                    "})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');\n"
            });
        }
    }, [])

    return (
        <ConfirmationModalProvider>
            <AuthProvider>
                <QueryClientProvider client={queryClient}>
                    <Layout>
                        <Routes>
                            <Route path={adminRoutes.subscription(':subscriptionId')} element={<ProtectedRoute><AdminSubscription /></ProtectedRoute>} />
                            <Route path={adminRoutes.subscriptions} element={<ProtectedRoute><AdminSubscriptions /></ProtectedRoute>} />
                            <Route path={adminRoutes.insights} element={<ProtectedRoute><AdminInsights /></ProtectedRoute>} />
                            <Route path={adminRoutes.users} element={<ProtectedRoute><AdminUsers /></ProtectedRoute>} />
                            <Route path={adminRoutes.user(':userId')} element={<ProtectedRoute><AdminUser /></ProtectedRoute>} />
                            <Route path={adminRoutes.groups} element={<ProtectedRoute><AdminGroups /></ProtectedRoute>} />
                            <Route path={adminRoutes.group(':groupId')} element={<ProtectedRoute><AdminGroup /></ProtectedRoute>} />
                            <Route path={adminRoutes.subscriptionRules} element={<ProtectedRoute><AdminSubscriptionRules /></ProtectedRoute>} />
                            <Route path={adminRoutes.institutes} element={<ProtectedRoute><AdminInstitutes /></ProtectedRoute>} />
                            <Route path={adminRoutes.subscriptionRule(':ruleId')} element={<ProtectedRoute><AdminSubscriptionRule /></ProtectedRoute>} />

                            <Route path={routes.dashboard} element={<ProtectedRoute><Dashboard /></ProtectedRoute>}/>
                            <Route path={routes.accountEdit} element={<ProtectedRoute><AccountForm /></ProtectedRoute>}/>
                            <Route path={routes.twoFactorAuthentication} element={<ProtectedRoute><TwoFactorAuthentication /></ProtectedRoute>}/>
                            <Route path={routes.twoFactorAuthenticationSuccess} element={<ProtectedRoute><TwoFactorAuthenticationSuccess /></ProtectedRoute>}/>
                            <Route path={routes.twoFactorChallenge} element={<TwoFactorChallenge />}/>
                            <Route path={routes.login} element={<Login />} />
                            <Route path={routes.openPhoto51} element={<OpenPhoto51 />} />
                            <Route path={routes.groupsJoinLink(':groupId', ':hash')} element={<JoinGroupLink />} />
                            <Route path={routes.completeAccount(':userId', ':hash')} element={<CompleteAccount />} />
                            <Route path={routes.shareRequestAccessLink(':shareRequestIds', ':hash')} element={<ShareRequestAccessLink />} />
                            <Route path={routes.emailVerified} element={<EmailVerified />} />
                            <Route path={routes.download} element={<DownloadPhoto51 />} />
                            <Route path={routes.photo51} element={<Photo51 />} />
                            <Route path={routes.plans} element={<Plans />} />
                            <Route path={routes.signUpSuccess} element={<SignUpSuccess />} />
                            <Route path={routes.completeAccountSuccess} element={<CompleteAccountSuccess />} />
                            <Route path={routes.signUp} element={<SignUp />} />
                            <Route path={routes.cookiePolicy} element={<RedirectPage to={externalLinks.cookiePolicy} />} />
                            <Route path={routes.privacy} element={<RedirectPage to={externalLinks.privacyPolicy} />} />
                            <Route path={routes.termsOfService} element={<RedirectPage to={externalLinks.termsOfService} />} />
                            <Route path={routes.logout} element={<Logout />} />
                            <Route path={routes.aboutUs} element={<AboutUs />} />
                            <Route path={routes.landing} element={<ProtectedRoute><LoginLanding /></ProtectedRoute>} />
                            <Route path={routes.forgotPassword} element={<ForgotPassword />} />
                            <Route path={routes.resetPassword} element={<ResetPassword />} />
                            <Route path={routes.faq} element={<FAQ />} />
                            {[routes.groups, routes.group(':groupId')].map((p) => (
                                <Route
                                    key={p}
                                    path={p}
                                    element={<ProtectedRoute><GroupDashboard /></ProtectedRoute>}
                                />
                            ))}
                            <Route
                                path="/group/groups"
                                element={<Navigate to={routes.groups} replace />}
                            />
                            <Route path="/" element={<Home/>} />
                            <Route path="/invalid-link" element={<InvalidLink />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Layout>
                </QueryClientProvider>
            </AuthProvider>
        </ConfirmationModalProvider>
    );
}

export default App;
