import React from 'react';
import useGet from "../../components/hooks/useGet";
import {backendEndpoints} from "../../libs/backendEndpoints";
import {Pages} from "../../components/layout";

/**
 * Show a nice table of the hashmap
 * @param hashMap E.g. {alpha => 1, beta: 2, ...}
 */
function Counted({ hashMap }) {
    return (
        <table>
            <tbody>
            {Object.keys(hashMap).map((k) => (
                <tr key={k}>
                    <td><label>{k || <i>No value</i>}:&nbsp;&nbsp;</label></td>
                    <td>{hashMap[k]}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default function AdminInsights() {
    const { data: stats } = useGet({
        endpoint: backendEndpoints.getAdminUserStats,
    });
    return (
        <Pages.Centred>
            <h1>User statistics</h1>
            <table className="table table-bordered text-sm table-sm">
                <tbody>
                {stats && Object.keys(stats).map((key) => (
                    <tr key={key}>
                        <td valign="top" width={200}>{key}</td>
                        <td>
                            {stats[key] && typeof stats[key] === 'object'
                                ? <Counted hashMap={stats[key]}/>
                                : stats[key]}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </Pages.Centred>
    );
}
