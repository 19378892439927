import React, {useState} from 'react';
import PropTypes from 'prop-types';
import useAuth from "../hooks/useAuth";
import {Alert, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {routes} from "../../utils";
import {concepts} from "../../libs/lang";
import Button from "react-bootstrap/Button";
import {BackendForm, Checkboxes} from "../forms";
import useGet from "../hooks/useGet";
import {backendEndpoints} from "../../libs/backendEndpoints";
import {useField} from "formik";
import Loading from "../Loading";

function groupToOption(group) {
    return {
        value: `${group.id}`,
        label: `${group.name} ${!group.is_subscribed ? '- No subscription' : ''}`,
        disabled: !group.is_subscribed,
    }
}

function SwitchGroupSelect() {
    const fieldName = 'group_id';
    const [,, { setValue }] = useField(fieldName)
    const { data: groups, isLoading, error } = useGet({ endpoint: backendEndpoints.getGroups })

    const hasGroups = groups?.length && Array.isArray(groups);

    return (
        <>
            {hasGroups ? (groups.filter(g => g.is_subscribed).length === 0
                ? (
                    <Alert variant="warning">None of your {concepts.Groups} have a {concepts.Subscription}.</Alert>
                ) : (
                    <Checkboxes
                        name={fieldName}
                        label="Arrange subscription via"
                        type="radio"
                        onChange={e => {
                            setValue(e.target.value)
                        }}
                        options={hasGroups ? groups.map(groupToOption) : []}
                    />
                )
            ) : <Alert variant="info">You don't have any groups. Create one <a href={routes.groups}>here</a>!</Alert>}
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
        </>
    )
}

const SubscriptionSwitcherPropTypes = {
    newGroup: PropTypes.shape({}),
}

const SubscriptionSwitcherDefaultProps = {
    newGroup: null,
}

export default function SubscriptionSwitcher({ onSuccess }) {
    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const {
        subscribed_via_group: isSubscribedViaGroup,
        is_subscribed: isSubscribed
    } = user;

    const hasIndividualSubscription = isSubscribed && !isSubscribedViaGroup;

    const switchGroupButton = (
        <Button variant="outline-success" size="sm" onClick={() => setShowModal(true)}>
            {hasIndividualSubscription ? `Arrange via ${concepts.Group}` : `${isSubscribedViaGroup ? 'Switch': 'Take seat in'} ${concepts.Group} ${concepts.Subscription}`}
        </Button>
    );

    const currentSituation = isSubscribed ? (hasIndividualSubscription
        ? <div>You have an <b>individual</b> subscription</div>
        :(
            <div>
                Your subscription is arranged via {concepts.Group} account&nbsp;
                <b>{isSubscribedViaGroup.name}</b>
                .
            </div>
        )
    ) : (
        <p>One of your groups has a group subscription. If there is seats available, you can take a seat.</p>
    );
    const close = () => setShowModal(false);

    return (
        <>
            <Alert variant="success">
                {currentSituation}
                {isSubscribedViaGroup && (
                    <Link to={routes.group(isSubscribedViaGroup.id)}>
                        <Button variant="outline-success" size="sm" className="me-3">More info</Button>
                    </Link>
                )}
                {switchGroupButton}
                {!hasIndividualSubscription && (
                    <b className="d-block mt-3">Or you can choose to get a {concepts.Subscription} as an individual below.</b>
                )}
            </Alert>
            {showModal && (
                <Modal
                    show={showModal}
                    onHide={close}
                >
                    <BackendForm
                        initialValues={{ group_id: `${isSubscribedViaGroup?.id}` }}
                        targetMethod="POST"
                        targetEndpoint={backendEndpoints.switchSubscription}
                        onSuccess={() => {
                            close();
                            onSuccess();
                        }}
                        showFooter={false}
                    >
                        <Modal.Header>Switch {concepts.Group}</Modal.Header>
                        <Modal.Body>
                            <p>{currentSituation}</p>
                            <SwitchGroupSelect />
                        </Modal.Body>
                        <Modal.Footer>
                            <BackendForm.Footer showError marginTop={false} />
                        </Modal.Footer>
                    </BackendForm>
                </Modal>
            )}
        </>
    );
}

SubscriptionSwitcher.propTypes = SubscriptionSwitcherPropTypes;
SubscriptionSwitcher.defaultProps = SubscriptionSwitcherDefaultProps;
