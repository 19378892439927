import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import Pages from "../components/layout/Pages";
import {routes} from "../utils";
import useAuth from "../components/hooks/useAuth";
import useTitle from "../components/hooks/useTitle";

export default function Logout() {
    useTitle('Logging out')
    const { logout } = useAuth()
    const [sp] = useSearchParams();

    useEffect(() => {
        logout(undefined);
        window.location.href = sp?.get('redirect') || routes.home;
    }, [])

    return (
        <Pages.Centred padded width="xs">
            <h1>Logging out</h1>
            <p>You are being redirected to the home page.</p>
        </Pages.Centred>
    );
}
