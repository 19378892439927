import React from "react";
import {Fold, IconContent, Pages} from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import useTitle from "../components/hooks/useTitle";
import plasmidEditorScreenshot from '../assets/images/screenshots/photo51/top-of-the-page.png';
import splitScreenScreenshot from '../assets/images/screenshots/photo51/highlight-splitscreen.png';
import previewScreenshot from '../assets/images/screenshots/photo51/highlight-file-inventory-with-preview.png';
import toolsScreenshot from '../assets/images/screenshots/photo51/highlight-CRISPR-Cas-tools.png';
import ListedInformation from "../components/layout/ListedInformation";
import Header from "../components/Header";
import StandardIcons from "../components/layout/StandardIcons";
import {concepts} from "../libs/lang";
import photo51logo from './../../src/assets/images/logos/photo51-red-desktop-icon.svg';
import StartWithPhoto51 from "../components/StartWithPhoto51";
import ImageWithModal from "../components/layout/ImageWithModal";
import Description from "../components/Description";
import {listedFeatures} from "../libs/listedFeatures";
import {ACTIONS} from "../components/tracking/trackEvent";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import useMetaDescription from "../components/hooks/useMetaDescription";

export default function Photo51() {
    useTitle(`${concepts.Photo51} - ${concepts.Photo51Slogan2}.`)
    useMetaDescription(
        `Work anywhere, manage data efficiently with ${concepts.Photo51}. Streamline your research with secure cloud access, intuitive file organization, and powerful search tools. Import & export seamlessly & collaborate in real-time. Explore ${concepts.Photo51}'s features!`
    );

    return (
        <Pages.Base padded={false}>
            <Fold>
                <Fold.Main className="bg-darkest">
                    <div className="text-center text-white">
                        <img
                            src={photo51logo}
                            height={120}
                            alt={`${concepts.Photo51} Logo`}
                            className="mb-2"
                        />
                        <h1 className="mb-1">{concepts.Photo51}</h1>
                        <div className="text-xl fw-bold mb-5 gray-400">
                            {concepts.Photo51Slogan2}
                        </div>
                        <img
                            src={plasmidEditorScreenshot}
                            alt={`${concepts.Photo51} software screenshot`}
                            className="d-block mx-auto rounded-3 border border-1 border-dark shadow-lg"
                            style={{ maxHeight: 'min(50vh, 600px)', maxWidth: '90vw' }}
                        />
                    </div>
                </Fold.Main>
                <Fold.Extension>
                    <Container className="pt-vh15 mt-5 mb-5 text-left">
                        <Row>
                            <Col sm={{ offset: 1, span: 5 }}>
                                <IconContent icon={<StandardIcons.OffLineOnline width={100} />}>
                                    <Header component="h4">Work anywhere, edit anywhere</Header>
                                    <Description>
                                        Like your research, your software shouldn't have any boundaries.
                                        You can install on as many computers as you want and access your data anytime from the cloud.
                                    </Description>
                                </IconContent>
                            </Col>
                            <Col sm={{ span: 5 }}>
                                <IconContent icon={<StandardIcons.Labels width={100} />}>
                                    <Header component="h4">Find what you need in seconds</Header>
                                    <Description>
                                        Build your sequence collections and organize with labels. Quickly
                                        find what you’re looking for with the easy sequence previews.
                                    </Description>
                                </IconContent>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{ offset: 1, span: 5 }}>
                                <IconContent icon={<StandardIcons.CrisprCas width={100} />}>
                                    <Header component="h4">
                                        Design precise CRISPR-Cas edits
                                    </Header>
                                    <Description>
                                        Design guide RNAs for your gene editing projects with preset
                                        commonly used systems. Apply base editor systems (CBE and ABE)
                                        and automatically visualize PAM sequence, nuclease cut sites
                                        and base editing windows.
                                    </Description>
                                </IconContent>
                            </Col>
                            <Col sm={{ span: 5 }}>
                                <IconContent icon={<StandardIcons.LibraryShare width={100} />}>
                                    <Header component="h4">
                                        Collaborate seamlessly in real-time
                                    </Header>
                                    <p>
                                        Create unlimited teams or subgroups. Create joint sequence
                                        collections or work on cloning projects together.
                                    </p>
                                </IconContent>
                            </Col>
                        </Row>
                    </Container>
                </Fold.Extension>
            </Fold>
            <ImageWithModal.Provider>
                <Container>
                    <h2 className="text-center mb-5">
                        Powerful Features, Streamlined Workflows
                    </h2>
                    <Tabs defaultActiveKey="general" className="boxed" variant="tabs" fill>
                        <Tab eventKey="general" title="General">
                            <h2 className="mb-4">Streamline Your Molecular Biology Workflow</h2>
                            <ListedInformation.Item
                                title="Unlimited installations and cloud syncing"
                                description="Like your research, your software shouldn’t have any boundaries. You can install on as many computers as you want and access your data anytime from the cloud."
                            />
                            <ListedInformation.Item
                                title="Intuitive Inventory and Sequence Previews"
                                description="Organize your sequence collections with labels and find what you need quickly. Easily visualize sequences within your inventory for efficient analysis."
                            >
                                <ImageWithModal
                                    header="File inventory with plasmid preview"
                                    src={previewScreenshot}
                                    alt={`${concepts.Photo51} File inventory with plasmid preview example`}
                                    className="d-block mx-auto rounded-2 border-2 border-white shadow-lg w-100"
                                />
                            </ListedInformation.Item>
                            <ListedInformation.Item
                                title="Split-Screen Editing"
                                description={(
                                    <p>
                                        View your plasmid map and sequence simultaneously for efficient editing and data comparison.
                                    </p>
                                )}
                            >
                                <ImageWithModal
                                    header="Split screen"
                                    src={splitScreenScreenshot}
                                    alt={`${concepts.Photo51} split screen example`}
                                    className="d-block mx-auto rounded-2 border-2 border-white shadow-lg w-100"
                                />
                            </ListedInformation.Item>
                            <ListedInformation.Item
                                title="Undo/Redo Functionality"
                                description="Work confidently knowing you can easily undo or redo actions."
                                showLine={false}
                            />
                        </Tab>
                        <Tab eventKey="crispr-cas" title="CRISPR-Cas">
                            <h2 className="mb-4">Powerful Tools for Precise Genome Engineering</h2>
                            <ListedInformation.Item
                                title="Design guide RNAs with ease"
                                description="Design guide RNAs for your projects with commonly used Cas9 and Cas12 systems, and base editor systems CBE and ABE."
                            >
                                <ImageWithModal
                                    header="CRISPR-Cas tools"
                                    src={toolsScreenshot}
                                    alt={`${concepts.Photo51} CRISPR-Cas tools`}
                                    className="d-block mx-auto rounded-2 border-2 border-white shadow-lg w-100"
                                />
                            </ListedInformation.Item>
                            <ListedInformation.Item
                                title="Visualize edits"
                                description="Visualize PAM sequences, nuclease cut sites, and base editing windows for precise genome editing."
                            />
                            <ListedInformation.Item
                                title="Create custom CRISPR-Cas systems"
                                description="Add you own unique CRISPR-Cas system."
                                showLine={false}
                            />
                        </Tab>
                        <Tab eventKey="collaboration" title="File Management">
                            <h2 className="mb-4">Advanced File Management Tools</h2>
                            <ListedInformation.Item
                                title="Intuitive Inventory System"
                                description="Manage your research data efficiently with an intuitive inventory system that includes sequence map previews for clear organization."
                            />
                            <ListedInformation.Item
                                title="Labeling Systems"
                                description="Create and edit label structures for efficient data identification and clear communication within your team."
                            />
                            <ListedInformation.Item
                                title="Comprehensive Version Control"
                                description="Maintain a comprehensive file history with version control, allowing you to recover older versions if needed and ensuring collaborative work is well-documented."
                            />
                            <ListedInformation.Item
                                title="Secure File Sharing"
                                description="Securely share files and collections with team members or external collaborators for seamless collaboration."
                            />
                        </Tab>
                        <Tab eventKey="importing_and_exporting" title="Collaboration">
                            <h2 className="mb-4">Real-Time Collaboration</h2>
                            <ListedInformation.Item
                                title="Real-Time Editing"
                                description="Create joint sequence collections and work on cloning projects together in real-time."
                            />
                            <ListedInformation.Item
                                title="Simplified Team Management"
                                description="Easily manage and invite team members to collaborate on your research projects."
                            />
                            <ListedInformation.Item
                                title="Secure Communication and Data Sharing"
                                description="Share data and communicate effectively within your team or external collaborators using secure sharing features."
                                showLine={false}
                            />
                        </Tab>
                    </Tabs>
                </Container>
            </ImageWithModal.Provider>
            <StartWithPhoto51 actionTag={ACTIONS.START_WITH_PHOTO51_BANNER_PHOTO51_PAGE_TRY_FOR_FREE} />
            <section className="py-5 bg-lighter">
                <h1 className="text-center mb-5">Listed features</h1>
                <Container>
                    <Row>
                        {Object.keys(listedFeatures).map((header, idx) => (
                            <Col md={{ span: 4, offset: idx % 2 === 0 ? 2: 0 }} sm={6} className="mb-4">
                                <h5>{header}</h5>
                                <ul className="ms-0 ps-3">
                                    {listedFeatures[header].map((item) => <li key={item}>{item}</li>)}
                                </ul>
                            </Col>
                        ))}
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Pages.Base>
    );
}
