import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import {Field} from "formik";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {PlusLg} from "react-bootstrap-icons";
import {validateEmail} from "../../utils/validations";
import {concepts} from "../../libs/lang";
import Description from "../Description";
import UserCard from "../layout/UserCard";
import InviteButton from "../InviteButton";
import Loading from "../Loading";
import Header from "../Header";
import InviteEmailButton from "./InviteEmailButton";
import GenerateGroupJoinLink from "./GenerateGroupJoinLink";


export default function InviteUserModal({ groupId, reloadGroup, groupIsSubscribed }) {
    const timeout = useRef();
    const [show, setShow] = useState(false);
    const [query, setQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setShow(false);
        setQuery('')
        setUsers([])
        setError('')
        setLoading(false)
        reloadGroup()
    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        setLoading(false)
        clearTimeout(timeout.current)

        timeout.current = setTimeout(() => {
            setLoading(false)
            setError('')

            if (query) {
                setLoading(true)
                axios.get('/users/search', { params: { query }})
                    .then(({ data }) => {
                        setUsers(data.data)
                    })
                    .catch(e => {
                        const { message } = e.response.data;
                        setError(message)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }, 500)
    }, [query])

    const hasIncompleteEmail = query.indexOf('@') > -1 && !validateEmail(query);

    return (
        <>
            <Button onClick={handleShow} size="sm">
                <PlusLg />
                &nbsp;
                invite {concepts.GroupMembers.toLowerCase()}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new {concepts.GroupMember.toLowerCase()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Description>
                        Search for an existing {concepts.Photo51} user or invite new users by typing in their email.
                    </Description>
                    <Field
                        value={query}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        className="form-control mb-2 border-top border-start border-end"
                        placeholder="Search..."
                        name="query"
                        autoFocus
                        autoComplete="off"
                    />
                    {hasIncompleteEmail && <div className="text-xs text-danger">Please complete the email address.</div>}
                    <ButtonGroup className="d-flex flex-column mb-2">
                        {query && !loading && Array.isArray(users) && (users.length > 0
                                ? users.map((user) => (
                                    <UserCard key={user.id} user={user}>
                                        <InviteButton
                                            user={user}
                                            groupId={groupId}
                                            onSuccess={reloadGroup}
                                            groupIsSubscribed={groupIsSubscribed}
                                        />
                                    </UserCard>
                                ))
                                : validateEmail(query)
                                    ? (
                                        <InviteEmailButton
                                            reload={reloadGroup}
                                            groupId={groupId}
                                            onSuccess={(message) => {
                                                setSuccess(message)
                                                setQuery('')
                                                // Automatically let de message disappear
                                                setTimeout(() => {
                                                    setSuccess('')
                                                }, 3000)
                                            }}
                                            email={query}
                                        />
                                    )
                                    : (!hasIncompleteEmail && <Alert className="alert-warning">No users were found.</Alert>)
                        )}
                    </ButtonGroup>
                    {error && <Alert className="alert-danger">{error}</Alert>}
                    {success && <Alert className="alert-success">{success}</Alert>}
                    {loading && <Loading />}

                    <Header component="h6" className="mb-0 mt-5">Share {concepts.Group.toLowerCase()} link</Header>
                    <GenerateGroupJoinLink groupId={groupId} />
                </Modal.Body>
            </Modal>
        </>
    )
}
