import React from 'react';
import Button from "react-bootstrap/Button";
import {getCurrencySymbol} from "./AmountSpan";

export default function OptionButton({ children, selected, onClick }) {
    return (
        <Button
            className={`flex-grow-1 w-50 text-dark d-flex flex-column rounded-2 align-items-center border border-3 ${selected ? 'border-secondary': 'border-gray'} mt-2`}
            variant=""
            onClick={onClick}
        >
            {children}
        </Button>
    );
}

function Discount({ mainPriceAmount, downSellAmount, currency }) {
    const annualDownSellAmount = 12 * downSellAmount;
    const savingInAbsolute = ((annualDownSellAmount - mainPriceAmount) / 100);
    const savingInPercentage = Math.round(savingInAbsolute * 100 / annualDownSellAmount * 100);

    return (
        <div className="text-success text-sm">
            {savingInAbsolute < 20
                ? `Save ${savingInPercentage}%!`
                : <>{getCurrencySymbol(currency)}{savingInAbsolute.toFixed(2)} savings!</>}
        </div>
    )
}

OptionButton.Discount = Discount;
