
export function addScript({ html: innerHtml, src, async }) {
    const script = document.createElement('script');

    if (innerHtml) script.innerHTML = innerHtml;
    if (src) script.src = src;
    if (async) script.async = true;

    document.body.appendChild(script);
}
