import React, {useContext, useEffect, useId, useState} from 'react';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GalleryDots from "./GalleryDots";

const ImageWithModalContext = React.createContext({
    imageData: [],
});

export default function ImageWithModal({ src, height, className, alt, header }) {
    const id = useId();
    const { registerImage, removeImage, openImage} = useContext(ImageWithModalContext);

    useEffect(() => {
        registerImage(id, { src, alt, header });
        return () => {
            removeImage(id);
        }
    }, [id, src, alt, header])

    return (
        <img
            src={src}
            alt={alt}
            height={height}
            className={`cursor-pointer ${className}`}
            onClick={() => openImage(id)}
        />
    );
}

function ImageWithModalProvider({ children }) {
    const [imageData, setImageData] = useState({});
    const [openId, setOpenId] = useState(undefined);

    const ids = Object.keys(imageData);
    const activeIndex = ids.indexOf(openId);
    const getNextIndex = (idx) => idx < ids.length - 1 ? idx + 1 : 0;
    const getPrevIndex = (idx) => idx > 0 ? idx - 1 : ids.length - 1;

    const prevId = openId ? ids[getPrevIndex(activeIndex)] : undefined;
    const nextId = openId ? ids[getNextIndex(activeIndex)] : undefined;

    return (
        <ImageWithModalContext.Provider
            value={{
                imageData,
                registerImage: (id, data) => {
                    setImageData((oldData) => ({ ...oldData, [id]: data }));
                },
                removeImage: (id) => {
                    setImageData((oldData) => {
                        delete oldData[id];
                        return oldData;
                    });
                },
                openImage: (id) => {
                    setOpenId(id);
                },
            }}
        >
            {children}
            {imageData[openId] && (
                <Modal
                    onHide={() => setOpenId(undefined)}
                    show
                    size="xl"
                >
                    <Modal.Header closeButton className="text-center d-flex">
                        <Modal.Title className="flex-grow-1 justify-content-center">{imageData[openId].header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-lightest">
                        <div className="d-flex justify-content-center mx-auto align-content-center align-items-center gap-3 mb-2">
                            <Button onClick={() => setOpenId(prevId)} className="text-sm text-truncate" style={{ width: '200px' }}>
                                Prev: {imageData[prevId].header}
                            </Button>
                            <GalleryDots length={ids.length} activeIndex={activeIndex} />
                            <Button onClick={() => setOpenId(nextId)} className="text-sm text-truncate" style={{ width: '200px' }}>
                                Next: {imageData[nextId].header}
                            </Button>
                        </div>
                        <img className="rounded-3 shadow" src={imageData[openId].src} alt={imageData[openId].alt} width="100%" />
                    </Modal.Body>
                </Modal>
            )}
        </ImageWithModalContext.Provider>
    );
}

ImageWithModal.Provider = ImageWithModalProvider;
