import React, {useEffect, useMemo} from 'react';
import {Pages} from "../components/layout";
import BackendForm from "../components/forms/BackendForm";
import {useNavigate} from "react-router";
import {routes} from "../utils";
import Description from "../components/Description";
import {Link, useSearchParams} from "react-router-dom";
import SignUpFields, {signUpInitialValues} from "./partials/SignUpFields";
import {backendEndpoints} from "../libs/backendEndpoints";
import useAuth from "../components/hooks/useAuth";
import useTitle from "../components/hooks/useTitle";
import {concepts} from "../libs/lang";


export default function SignUp({ group, shareRequests, redirect }) {
    useTitle('Try it for free!')
    const navigate = useNavigate()
    const { user } = useAuth()
    const [sp] = useSearchParams();
    const userFirstName =  sp?.get('userFirstName');

    useEffect(() => {
        if (user) navigate(routes.dashboard);
    }, [user])

    const showForShareRequests = shareRequests?.length;
    const showForGroup = Boolean(group);

    const groupId = group?.id;
    const initialValues = useMemo(() => ({
        email: '',
        ...signUpInitialValues.Account,
        ...signUpInitialValues.UsernamePassword,
        groups: groupId ? [groupId] : [],
        custom_fields: {},
    }), [groupId]);

    return (
        <Pages.Centred width="xs" padded>
            <h1 className="text-center">
                {showForGroup && `Sign up and join ${group.name}`}
                {showForShareRequests && `Access the ${shareRequests.length} shared files `}
                {!showForGroup && !showForShareRequests && 'Try for free!'}
            </h1>
            {group && userFirstName && (
                <Description className="mb-0 text-center">
                    You have been invited by <b>{userFirstName}</b> to join the {concepts.Group.toLowerCase()}.
                </Description>
            )}
            <Description className="mb-5 text-center">
                Have an account already? Sign in&nbsp;
                <Link className="btn-link" to={redirect ? routes.loginWithRedirect(redirect) : routes.login}>here</Link>.
            </Description>
            <BackendForm
                targetMethod="POST"
                onSuccess={() => navigate(routes.signUpSuccess)}
                targetEndpoint={backendEndpoints.signUp}
                initialValues={initialValues}
                // Enable remember form to lower the friction to sign up
                rememberForm
                formId="sign-up-form"
            >
                <h3>Create login</h3>
                <SignUpFields.UsernamePassword checkDuplicate />
                <br/>
                <SignUpFields.Account showEmail />
                <SignUpFields.InstituteFields />
            </BackendForm>
        </Pages.Centred>
    );
}
