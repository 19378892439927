import React from 'react';
import {Modal} from "react-bootstrap";
import {ArrowRight} from "react-bootstrap-icons";
import Description from "../Description";
import {routes} from "../../utils";

export default function FreeProductModal({ onClose }) {
    return (
        <Modal show size="sm" className="text-center" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Basic Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Description>You automatically have the free subscription.</Description>
            </Modal.Body>
            <Modal.Footer>
                <a href={routes.download} className="btn btn-primary">
                    Download Photo51&nbsp;
                    <ArrowRight size={20} />
                </a>
            </Modal.Footer>
        </Modal>
    );
}
