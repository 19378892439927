import React, {useContext, useState} from 'react';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Loading from "../Loading";
import Alert from "react-bootstrap/Alert";

const ConfirmationModalContext = React.createContext({
    open: (message, actionCallback, danger) => {},
    openPromisify: (message, actionCallback, danger) => {},
});

export default function ConfirmationModalProvider({ children }) {
    const [openData, setOpenData] = useState(null);
    const [isDoing, setDoing] = useState(false);
    const [error, setError] = useState('');

    return (
        <ConfirmationModalContext.Provider
            value={{
                open: (message, actionCallback, danger) => {
                    setOpenData({ message, actionCallback, danger })
                },
                openPromisify: (message, actionCallback, danger) => {
                    return new Promise((resolve, reject) => {
                        setOpenData({ message, actionCallback, danger, promiseCallbacks: { resolve, reject } })
                    });
                },
            }}
        >
            <div>
                {children}
                {openData && (
                    <Modal
                        show
                        size="sm"
                        className="text-center"
                        onHide={() => {
                            if (openData?.promiseCallbacks?.reject) {
                                openData?.promiseCallbacks?.reject();
                            }
                            setOpenData(null);
                        }}
                    >
                        <Modal.Header closeButton className="border-0 d-flex justify-content-center justify-items-center">
                            <Modal.Title className="text-center flex-grow-1 text-center ps-3">Please confirm</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="mb-4">{openData.message}</p>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Button
                                variant={openData.danger ? 'danger' : 'primary'}
                                disabled={isDoing}
                                onClick={() => {
                                    setDoing(true);

                                    openData.actionCallback()
                                        .then((e) => {
                                            setError('');
                                            openData?.promiseCallbacks?.resolve();
                                            return e;
                                        })
                                        .catch((e) => {
                                            setError(e.response?.data?.message || e.message);
                                        })
                                        .finally(() => {
                                            setDoing(false);
                                        })
                                }}
                            >
                                Confirm
                                {isDoing && <Loading size="sm" />}
                            </Button>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        </ConfirmationModalContext.Provider>
    );
}

export function useConfirm() {
    return useContext(ConfirmationModalContext);
}
