import React, {useState} from 'react';
import {Alert} from "react-bootstrap";
import {useNavigate} from "react-router";
import styles from './ManualPricingTable.module.scss';
import useGet from "../hooks/useGet";
import Loading from "../Loading";
import ManualPlanDisplay from "./ManualPlanDisplay";
import PropTypes from "prop-types";
import {routes} from "../../utils";
import SeatSelectorModal from "./SeatSelectorModal";
import {isCustom, isGroupProduct, isIndividualProduct} from "./price";
import useAuth from "../hooks/useAuth";
import IndividualPriceModal from "./IndividualPriceModal";
import FreeProductModal from "./FreeProductModal";


const Price = PropTypes.shape({
    billing_scheme: PropTypes.oneOf(['per_unit']),
    currency: PropTypes.oneOf(['eur']),
    recurring: PropTypes.shape({
        interval: PropTypes.oneOf(['year']),
    }),
    unit_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }),
});
const ManualPricingTablePropTypes = {
    prePrices: PropTypes.arrayOf(Price),
    postPrices: PropTypes.arrayOf(Price),
}

const ManualPricingTableDefaultProps = {}

export default function ManualPricingTable({ endpoint, groupId, prePrices = [], postPrices = [], bestValueIndex, filterScope, center }) {
    const { data: prices, isLoading, error } = useGet({ endpoint, cacheTime: 1000 });
    const [showDownSellForSelectedPrice, setShowDownSellForSelectedPrice] = useState(false);
    const [selectedPrice, setPrice] = useState(null);
    const navigate = useNavigate();
    const { user } = useAuth();

    return (
        <>
            {selectedPrice && selectedPrice.unit_amount === 0 && (
                <FreeProductModal onClose={() => setPrice(null)} />
            )}
            {selectedPrice && selectedPrice.unit_amount > 0 && (
                isGroupProduct(selectedPrice)
                    ? (
                        <SeatSelectorModal
                            onCancel={() => setPrice(null)}
                            selectedPrice={selectedPrice}
                            initGroupId={groupId}
                        />
                    ) : (
                        <IndividualPriceModal
                            price={selectedPrice}
                            onCancel={() => setPrice(null)}
                            defaultShowDownSell={showDownSellForSelectedPrice}
                        />
                    )
            )}
            {isLoading && <div style={{minHeight: 500}}><Loading /></div>}
            {error && <Alert variant="danger">{error}</Alert>}
            <div className={`${styles.Table} ${center ? styles.Center : styles.Left}`}>
                {Array.isArray(prices) && [...prePrices, ...prices, ...postPrices]?.map((price, idx) => (
                    <ManualPlanDisplay
                        filterScope={filterScope}
                        price={price}
                        key={price.id || idx}
                        onSelect={(showDownSell) => {
                            if (isCustom(price)) {
                                window.location.href = 'mailto:info@fearlessleague.com';
                            } else {
                                if (!user) {
                                    navigate(routes.signUp);
                                } else if (isIndividualProduct(price) || isGroupProduct(price)) {
                                    setShowDownSellForSelectedPrice(showDownSell);
                                    setPrice(price);
                                }
                            }
                        }}
                        isBestValue={idx === bestValueIndex}
                    />
                ))}
            </div>
        </>
    )
}

ManualPricingTable.propTypes = ManualPricingTablePropTypes;
ManualPricingTable.defaultProps = ManualPricingTableDefaultProps;
